import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InfiniteRetailInviteCard from "../../../common/cardContainers/InfiniteRetailInviteCard";
import { APP_TITLE } from "../../../../config/constant";
import { useOutletContext } from "react-router";
export default function Index() {
    document.title = `${APP_TITLE} - Retailers View`;
    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const infiniteGridQuerykey = "InviteGrid";
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(props);
    }, [props?.layoutType]);
    /* #endregion EFFECTS */
    return (
        <div className="main-container">
            <InfiniteRetailInviteCard
                minColumnWidth="370px"
                limit="8"
                //onDeleteAction={handleDelete}
                //onSuspendAction={handleToogleSuspend}
                queryKey={infiniteGridQuerykey}
                statusFromPath="status"
            />
        </div>
    );
}
function setupHeaders(props, onRefreshAction) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Retailers", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([{ to: "/retailers", active: true, disabled: false, title: "Retailers" }]);
    props.setControlBarRightBtns([
        { to: "/retailers/view", active: true, disabled: false, title: "View", icon: "eye" },
        { to: "/retailers", active: false, disabled: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        visible: true,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: onRefreshAction
    });
}
