import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import CardComponentContainer from "../../common/cardContainers/CardComponentContainer";
import { useNavigate, useOutletContext } from "react-router";
import CompanyLocationCard from "./createCompanyComponents/CompanyLocationCard";
import CompanyLogo from "./createCompanyComponents/CompanyLogo";
import * as Library from "../../../utils/Library";
import { useDispatch, useSelector } from "react-redux";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import LayoutContext from "../../../LayoutContext";
import FormCard from "../../common/forms/FormCard";
import FormField from "../../common/forms/FormField";
import { getDropdownData } from "../../../utils/dropdownData";
import CalendarIcon from "../../../assets/images/calender.svg";
import Glob from "../../../assets/images/glob.svg";
import UrlIcon from "../../../assets/images/url-icon.svg";
import LinkedIn from "../../../assets/images/linkedin.svg";
import Twitter from "../../../assets/images/twitter.svg";
import Facebook from "../../../assets/images/facebook.svg";
import Instagram from "../../../assets/images/instagram.svg";
import SimpleButtonContentHeader from "./createCompanyComponents/SimpleButtonContentHeader";
/* #region SCOPED CSS */
const StyledPage = styled.div`
    .input-remove-default {
        border: none;
        outline: none;
        background: none;
    }

    .selects {
        background: var(--backgrounds-and-lines-n-600, #10141b);
    }

    .selects.mandatory {
        border: 1px solid transparent;
        border-left: 5px solid var(--text-icons-orange-500);
        border-radius: 4px;
        outline: none;
    }

    .selects.mandatory > div {
        border: none;
        cursor: pointer;
    }

    .selects.mandatory.error {
        border: 1px solid var(--text-icons-red-500);
        border-left: 5px solid var(--text-icons-red-500);
    }

    .main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 68px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--buttons-all-platforms-gray-200, #4f5b6d);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
    .map {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 1;
        width: 393px;
        position: relative;
    }
    .frame-241 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle10 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .on-map {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-483 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        overflow: hidden;
    }
    .frame-244 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-178 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 200px;
    }
    .map-01 {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        position: relative;
        overflow: visible;
    }
`;
const RequiredLabelStyle = styled.div`
    &.main-conatainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        font-size: 10px;
        font-family: "Roboto";
        color: #7c868b;
        text-align: center;
        justify-content: center;
        align-items: center;
        text-transform: lowercase;
    }
    .recommended {
        display: flex;
        flex-direction: row;
        gap: 4px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .recommended-icon {
        height: 6px;
        width: 6px;
        border: 1px solid #00aeef;
        background-color: #00aeef;
        border-radius: 50%;
    }
    .mandatory-icon {
        height: 6px;
        width: 6px;
        border: 1px solid #ff9900;
        background-color: #ff9900;
        border-radius: 50%;
    }
    .locked-icon {
        height: 6px;
        width: 6px;
        border: 1px solid #4f5b6d;
        background-color: #4f5b6d;
        border-radius: 50%;
    }
`;
/* #endregion */

export default function CreateCompanyPage() {
    /* #region STATES */
    document.title = `${APP_TITLE} - Companies Create`;
    const navigations = useSelector(state => state.navigationAccesses);
    const { MainLayoutProps, layoutType } = useOutletContext();

    const {
        showIsAccountSavedPopup,
        setShowIsAccountSavedPopup,
        setShowIsCompanySavedPopup,
        readyToSavePopup,
        setReadyToSavePopup
    } = React.useContext(LayoutContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formCompanyID = useRef();
    const formCompanySocialMedia = useRef();
    const formCompanyFieldsRefs = [];
    const formCompanySocialMediaFieldsRefs = [];

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };

    const [isLoading, setIsLoading] = useState(false);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

    const [countryOptions] = useState(getDropdownData("countryOptions"));
    const [storeName, setStoreName] = useState("");
    const [companyAddressFormValues, setCompanyDetails] = useState({
        legalBusinessName: "",
        googlePlusCode: "",
        companyCountry: "",
        companyAddressCountry: "",
        companyCity: "",
        companyPostalCode: "",
        companyAddressOne: "",
        companyAddressTwo: "",
        companyLatitude: "",
        companyLongitude: "",
        companyMainLogo: "",
        companyCompactLogo: ""
    });
    const [selectedPhoneCountryCode, setSelectedPhoneCountryCode] = useState("");
    const [selectedTaxCode, setSelectedTaxCode] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [taxNumber, setTaxNumber] = useState("");
    /* #endregion */

    /* #region METHODS */
    const selectTimezoneAndPhoneNumber = data => {
        const selectedCountryID = data?.id;
        const phoneCountryCodes = getDropdownData("countryCodeOptions");
        const taxIdData = getDropdownData("taxNumberOptions");
        const selectedPhoneCountryCodeTMP = phoneCountryCodes.find(country => country.id === selectedCountryID);
        const selectedTaxCode = taxIdData?.find(country => country.value === selectedCountryID);
        setSelectedPhoneCountryCode(selectedPhoneCountryCodeTMP?.value);
        setSelectedTaxCode(selectedTaxCode?.value ?? "");
        setTaxNumber(selectedTaxCode?.value === "NO" ? registrationNumber : "");
    };

    const checkFormIsValid = () => {
        const allCompanyIdFieldsAreValid = formCompanyID.current.allFieldsAreValid(false);

        const ignoredFields = ["companyCountry", "legalBusinessName", "companyCompactLogo", "companyAddressTwo"]; //first two fields are ignored because there value is get by ref
        const isCompanyAddressValid = Object.entries(companyAddressFormValues)
            .filter(([key]) => !ignoredFields.includes(key))
            .every(([_, value]) => !!value);

        const allFieldsAreValid = allCompanyIdFieldsAreValid && isCompanyAddressValid;

        if (allFieldsAreValid) {
            setSaveButtonEnabled(true);
        } else {
            setSaveButtonEnabled(false);
        }

        return allFieldsAreValid;
    };

    const onSaveClicked = async () => {
        const companyIDFormValues = formCompanyID.current.getAllFieldValues();
        const socialMediaFormValues = formCompanySocialMedia.current.getAllFieldValues();
        const { companyFacebook, companyInstagram, companyLinkedIn, companyWebsite, companyX } = socialMediaFormValues;

        const {
            legalBusinessName,
            country,
            registrationNumber,
            date,
            taxNumber,
            businessPhoneNr,
            doingBusinessAs,
            privacyPolicyUrl,
            businessEmail
        } = companyIDFormValues;

        const {
            googlePlusCode,
            companyAddressCountry,
            companyCity,
            companyPostalCode,
            companyAddressOne,
            companyAddressTwo,
            companyLatitude,
            companyLongitude,
            companyMainLogo,
            companyCompactLogo
        } = companyAddressFormValues;

        const jsonPostData = {
            companyID: localStorage.getItem("currentCompanyID"),
            name: legalBusinessName || "",
            countryValue: country?.value || "",
            registrationNumber: registrationNumber || "",
            registrationDate: date || "",
            taxPreLabel: selectedTaxCode || "",
            taxNumber: taxNumber.replace(/^NO\s*/, "") || "",
            taxPosLabel: selectedTaxCode === "NO" ? "MVA" : "",
            phoneNumberCountryCode: selectedPhoneCountryCode || "",
            phoneNumber: businessPhoneNr || "",
            businessName: doingBusinessAs || "",
            businessEmail: businessEmail || "",
            businessType: "Retail",
            mainLogoData: companyMainLogo,
            compactLogoData: companyCompactLogo,
            location: {
                latitude: companyLatitude || "",
                longitude: companyLongitude || "",
                googlePlusCode: googlePlusCode || "",
                countryValue: companyAddressCountry || "",
                city: companyCity || "",
                postalCode: companyPostalCode || "",
                addressLine1: companyAddressOne || "",
                addressLine2: companyAddressTwo || "",
                addressNumber: ""
            },
            socialMedia: {
                website: companyWebsite || "",
                privacyPolicy: privacyPolicyUrl || "",
                linkedin: companyLinkedIn || "",
                X: companyX || "",
                facebook: companyFacebook || "",
                instagram: companyInstagram || ""
            }
        };

        Library.makeCommonPostRequest("Company", "updateCompany", jsonPostData, false, setIsLoading).then(res => {
            if (res?.data?.status === 200) {
                const updatedNavigations = {
                    ...navigations.navigationAccesses,
                    companies: {
                        ...navigations.navigationAccesses.companies,
                        createModeOnly: false
                    },
                    labelBrands: {
                        ...navigations.navigationAccesses.labelBrands,
                        createModeOnly: true,
                        navigationPanel: true
                    }
                };
                dispatch(setNavigations({ navigationAccesses: updatedNavigations }));
                navigate("/companies");
                //setShowIsCompanySavedPopup(true);
                localStorage.setItem("activeCompanies", 1);
                setShowIsAccountSavedPopup(true);
            }
        });
    };
    /* #endregion METHODS */

    /* #region COMPONENT_VARS */
    const formCompanyFields = [
        <FormField
            type="text"
            title="Legal Business name"
            tooltip="Legal Business name"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="legalBusinessName"
            ref={el => (formCompanyFieldsRefs.legalBusinessName = el)}
            onTextChange={e => {
                setStoreName(e);
            }}
        />,
        <FormField
            type="dropdown"
            dropdownData={countryOptions}
            title="Country"
            isDropdownSearchable={true}
            tooltip="Select country"
            requiredType="mandatory"
            varName="country"
            onDropdownChange={selectTimezoneAndPhoneNumber}
            ref={el => (formCompanyFieldsRefs.country = el)}
        />,
        <FormField
            type="number"
            title="Registration number"
            tooltip="Registration number"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="registrationNumber"
            ref={el => (formCompanyFieldsRefs.registrationNumber = el)}
            onTextChange={e => {
                setRegistrationNumber(e);
            }}
        />,
        <FormField
            type="date"
            tooltip="Date"
            title="Registration Date"
            requiredType="mandatory"
            leftContent={<img src={CalendarIcon} alt="calender-icon" />}
            varName="date"
            ref={el => (formCompanyFieldsRefs.date = el)}
        />,
        <FormField
            type="taxNumber"
            title="Tax Number"
            tooltip="Tax Number"
            rightContent={selectedTaxCode === "NO" ? "MVA" : ""}
            taxNumberCountryCode={selectedTaxCode}
            varName="taxNumber"
            requiredType="mandatory"
            assistiveText="KYC"
            value={taxNumber}
            ref={el => (formCompanyFieldsRefs.taxNumber = el)}
        />,
        <FormField
            type="phoneNumber"
            title="Business phone nr"
            tooltip="Enter Business phone nr"
            requiredType="mandatory"
            varName="businessPhoneNr"
            assistiveText="KYC"
            phoneNumberCountryCode={selectedPhoneCountryCode}
            ref={el => (formCompanyFieldsRefs.businessPhoneNr = el)}
            onCountryCodeChange={e => {
                setSelectedPhoneCountryCode(e.value);
            }}
        />,
        <FormField
            type="text"
            title="Doing business as"
            tooltip="Doing business as"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="doingBusinessAs"
            ref={el => (formCompanyFieldsRefs.doingBusinessAs = el)}
        />,
        <FormField
            type="text"
            title="Business website"
            tooltip="Business website"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="businessWebsite"
            leftContent={<img src={Glob} alt="glob-icon" />}
            ref={el => (formCompanyFieldsRefs.businessWebsite = el)}
        />,
        <FormField
            type="text"
            title="Privacy policy URL"
            tooltip="Privacy policy URL"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="privacyPolicyUrl"
            leftContent={<img src={Glob} alt="glob-icon" />}
            ref={el => (formCompanyFieldsRefs.privacyPolicyUrl = el)}
        />,
        <FormField
            type="text"
            title="Business email"
            tooltip="Business email"
            requiredType="mandatory"
            assistiveText="KYC"
            varName="businessEmail"
            leftContent={<img src={UrlIcon} alt="glob-icon" />}
            ref={el => (formCompanyFieldsRefs.businessEmail = el)}
        />,
        <FormField
            type="dropdown"
            title="Business type"
            dropdownData={[{ name: "Retail", value: "Retail", selected: true }]}
            isDropdownMultiselect={false}
            isDropdownSearchable={false}
            tooltip="Business type"
            requiredType="locked"
            assistiveText="KYC"
            varName="businessType"
            ref={el => (formCompanyFieldsRefs.businessType = el)}
        />
    ];

    const formCompanySocialMediaFields = [
        <FormField
            type="text"
            title="Company website"
            tooltip="Company website"
            requiredType="simple"
            varName="companyWebsite"
            leftContent={<img src={Glob} alt="Glob" />}
            value={companyAddressFormValues.companyWebsite}
            ref={el => (formCompanySocialMediaFieldsRefs.companyWebsite = el)}
        />,
        <FormField
            type="text"
            title="Company LinkedIn"
            tooltip="Company LinkedIn"
            requiredType="simple"
            varName="companyLinkedIn"
            leftContent={<img src={LinkedIn} alt="LinkedIn" />}
            value={companyAddressFormValues.companyLinkedIn}
            ref={el => (formCompanySocialMediaFieldsRefs.companyLinkedIn = el)}
        />,
        <FormField
            type="text"
            title="Company X"
            tooltip="Company X"
            requiredType="simple"
            varName="companyX"
            leftContent={<img src={Twitter} alt="Twitter" />}
            value={companyAddressFormValues.companyX}
            ref={el => (formCompanySocialMediaFieldsRefs.companyX = el)}
        />,
        <FormField
            type="text"
            title="Company Facebook"
            tooltip="Company Facebook"
            requiredType="simple"
            varName="companyFacebook"
            leftContent={<img src={Facebook} alt="calender-icon" />}
            value={companyAddressFormValues.companyFacebook}
            ref={el => (formCompanySocialMediaFieldsRefs.companyFacebook = el)}
        />,
        <FormField
            type="text"
            title="Company Instagram"
            tooltip="Company Instagram"
            requiredType="simple"
            varName="companyInstagram"
            leftContent={<img src={Instagram} alt="Instagram" />}
            value={companyAddressFormValues.companyInstagram}
            ref={el => (formCompanySocialMediaFieldsRefs.companyInstagram = el)}
        />
    ];
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(props, saveButtonEnabled, setReadyToSavePopup);
    }, [props?.layoutType, navigations, saveButtonEnabled]);

    useEffect(() => {
        checkFormIsValid();
    }, [companyAddressFormValues]);
    /* #endregion */

    return (
        <StyledPage>
            <div>
                <FullScreenOverlay show={showIsAccountSavedPopup}>
                    <DialogBoxComponent
                        onClickYes={() => setShowIsAccountSavedPopup(false)}
                        title="Account is now saved!"
                        heading="Success!"
                        headingColor="#00BB00"
                        message={
                            "<div style='font-size: 12px; margin: 0 -5px;'>You can now proceed with enrolling your Company</div>"
                        }
                        // useImageHeader={{ src: BOStageBLogo, width: "165px" }}
                        showConfirm={() => setShowIsAccountSavedPopup(false)}
                        showCancel={false}
                        confirmText="CONTINUE"
                    />
                </FullScreenOverlay>

                <FullScreenOverlay show={readyToSavePopup}>
                    <DialogBoxComponent
                        headingColor="var(--text-icons-blue-500)"
                        title="Ready to save"
                        message="You can still change it later. No worries:)"
                        onClickYes={() => {
                            setReadyToSavePopup(false);
                            onSaveClicked();
                        }}
                        loading={isLoading}
                        loadingHeading={"SUBSCRIBING.."}
                        onClickNo={() => {
                            setReadyToSavePopup(false);
                        }}
                        cancelText="No"
                        confirmText="Yes"
                    />
                </FullScreenOverlay>

                <FullScreenOverlay show={isLoading}>
                    <DialogBoxComponent
                        headingColor="var(--text-icons-green-500)"
                        heading="SAVING..."
                        showCancel={false}
                        showConfirm={false}
                        loading={isLoading}
                    />
                </FullScreenOverlay>

                <FormCard
                    ref={formCompanyID}
                    title="COMPANY ID"
                    numOfFieldsPerLine={4}
                    showDefaultRightLegends={true}
                    formFields={formCompanyFields}
                    formFieldRefs={formCompanyFieldsRefs}
                    onFormChanged={() => {
                        checkFormIsValid();
                    }}
                />

                <CardComponentContainer title="COMPANY ADDRESS" className="mt-24px" rightComponent={<RequiredLabel />}>
                    <CompanyLocationCard
                        setCompanyDetails={setCompanyDetails}
                        companyDetails={companyAddressFormValues}
                        storeName={storeName}
                    />
                </CardComponentContainer>

                <CardComponentContainer title="COMPANY LOGO" className="mt-24px" rightComponent={<RequiredLabel />}>
                    <CompanyLogo companyDetails={companyAddressFormValues} setCompanyDetails={setCompanyDetails} />
                </CardComponentContainer>

                <FormCard
                    ref={formCompanySocialMedia}
                    title="COMPANY SOCIAL MEDIA"
                    numOfFieldsPerLine={4}
                    showDefaultRightLegends={true}
                    formFields={formCompanySocialMediaFields}
                    formFieldRefs={formCompanySocialMediaFieldsRefs}
                />
            </div>
        </StyledPage>
    );
}

/* #REGION SMALL_COMPONENTS_AND_METHODS */
function setupHeaders(props, saveButtonEnabled, setReadyToSavePopup) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Company", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/companies/create", active: true, disabled: false, title: "Companies" },
        { to: "#", active: false, disabled: true, title: "KYC POLICY" },
        { to: "#", active: false, disabled: true, title: "Users" }
    ]);
    props.setControlBarRightBtns([
        { to: "/companies", active: false, disabled: true, title: "View", icon: "eye" },
        { to: "/companies/create", active: true, disabled: false, title: "Add", icon: "plus" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader
                btnTitle="SAVE"
                btnEnabled={saveButtonEnabled}
                onClick={() => {
                    setReadyToSavePopup(true);
                }}
            />
        )
    });
}

const RequiredLabel = () => {
    return (
        <RequiredLabelStyle className="main-conatainer">
            <div className="recommended">
                <div className="recommended-icon"></div> recommended
            </div>
            <div className="recommended">
                <div className="mandatory-icon"></div> mandatory
            </div>
            <div className="recommended">
                <div className="locked-icon"></div> locked
            </div>
        </RequiredLabelStyle>
    );
};
/* #endregion */
