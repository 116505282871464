import React from "react";
import { GoogleMap, InfoBox, LoadScript, Marker } from "@react-google-maps/api";
import styled from "styled-components";
import InfoCircle from "../../../../assets/icons/info-circle-white.svg";
import ChevronDown from "../../../../assets/icons/chevron-down0.svg";
import Map from "../../../../assets/icons/map-010.svg";
import axios from "axios";
import { fetchAddressFromCoordinates, getLatLngUsingPlusCode } from "../../../../utils/LocationLib";

/* #region SCOPED CSS */
const StyledStoreLocation = styled.div`
    &.input-field-card-layout-3,
    &.input-field-card-layout-3 * {
        box-sizing: border-box;
    }
    &.input-field-card-layout-3 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 16px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-245 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-248 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-247 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .simple {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1 1 100%;
        position: relative;
        overflow: hidden;
    }
    .frame-51 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .title {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 38px;
        position: relative;
    }
    .frame-49 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 38px;
        position: relative;
        overflow: hidden;
    }
    .frame-11 {
        background: var(--text-icons-gray-300);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 38px;
        position: relative;
        overflow: hidden;
        cursor: not-allowed;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b) !important;
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-50 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        color: rgb(0, 174, 239);
        font-size: 8px;
    }
    .frame-KYC {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        color: #4f5b6d;
        font-size: 8px;
    }
    .dropdown-selector {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1 1 100%;
        position: relative;
        overflow: hidden;
    }
    .frame-492 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle2 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .frame-493 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 38px;
        position: relative;
        overflow: hidden;
    }
    .search2 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-482 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
        color: var(--text-icons-gray-300);
    }
    .placeholder {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .info-circle3 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .chevron-down2 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-2482 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle4 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .info-circle5 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .info-circle6 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .frame-249 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle7 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .chevron-down3 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .info-circle8 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .info-circle9 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .map {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 1;
        width: 393px;
        position: relative;
    }
    .frame-241 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle10 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .on-map {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-483 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        overflow: hidden;
    }
    .frame-244 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-178 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .map-01 {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        position: relative;
        overflow: visible;
    }
    .disabled {
        cursor: not-allowed;
        //pointer-events: none; //TODO: SHOULD THIS EXIST? IT MAKES THE CURSOR NOT CHANGE WHEN HOVERING
    }
    // _____ new map styles

    .gmnoprint .gm-style-mtc {
        display: none;
    }
    .gm-svpc {
        display: none;
    }
    .gmnoprint button.gm-control-active {
        background: #212121 !important;
        border-radius: 10px !important;
        margin: 0px 0 4px !important;
    }

    .gmnoprint button.gm-control-active img {
        display: none;
    }
    .gmnoprint button.gm-control-active:first-child::after {
        content: "\\2b";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .gmnoprint button.gm-control-active:last-child::after {
        content: "\\f068";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .gm-control-active.gm-fullscreen-control {
        background-color: #212121 !important;
        border-radius: 10px !important;
    }

    .gm-control-active.gm-fullscreen-control::after {
        content: "\\f31d";
        font-family: "Font Awesome 6 Pro";
        color: #fff;
        font-size: 18px;
        font-weight: 100;
    }
    .gm-control-active.gm-fullscreen-control img {
        display: none;
    }
    .gm-style-cc {
        display: none !important;
    }
    .gmnoprint > div > div {
        background: transparent !important;
        box-shadow: none !important;
    }
    .infoBox {
        margin-top: -40px;
        margin-left: 18px;
    }
`;
/* #endregion SCOPED CSS */
export default function CompanyLocationCard({ companyDetails, setCompanyDetails, storeName }) {
    const {
        googlePlusCode,
        companyAddressCountry,
        companyCity,
        companyPostalCode,
        companyAddressOne,
        companyAddressTwo,
        companyLatitude,
        companyLongitude
    } = companyDetails;
    /* #region VARS */

    /* #endregion */

    /* #region METHODS */
    const onGooglePlusCodeChange = e => {
        setCompanyDetails({
            ...companyDetails,
            googlePlusCode: e
        });
        fetchData(e);
    };
    /* #endregion */

    async function fetchData(googlePlusCode) {
        if (googlePlusCode) {
            const res = await getLatLngUsingPlusCode(googlePlusCode);
            if (res) {
                fetchAddressFromCoordinates(res.lat, res.lng).then(res2 => {
                    setCompanyDetails(prev => ({
                        ...prev,
                        companyLatitude: res?.lat,
                        companyLongitude: res?.lng,
                        companyAddressOne: res2?.street || "",
                        companyAddressTwo: res2?.streetNumber || "",
                        companyPostalCode: res2?.postCode || "",
                        companyAddressCountry: res2?.country || "",
                        companyCity: res2?.city || ""
                    }));
                });
            } else {
                setCompanyDetails(prev => ({
                    ...prev,
                    companyLatitude: "",
                    companyLongitude: "",
                    companyAddressOne: "",
                    companyAddressTwo: "",
                    companyPostalCode: "",
                    companyAddressCountry: "",
                    companyCity: ""
                }));
            }
        }
    }

    return (
        <StyledStoreLocation className="input-field-card-layout-3">
            <div className="frame-245">
                <div className="frame-248">
                    <div className="frame-247">
                        <div className="simple">
                            <div className="frame-51 ov-hidden">
                                <img className="info-circle" src={InfoCircle} alt="" />
                                <div className="title w-100">Google Plus Code</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={googlePlusCode ? "frame-49" : "frame-493"}></div>
                                <div className="search">
                                    <input
                                        className="frame-48 input-remove-default w-100 text-green-500"
                                        type="text"
                                        onChange={e => {
                                            onGooglePlusCodeChange(e?.target?.value);
                                        }}
                                        value={googlePlusCode}
                                    />
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="dropdown-selector hover-not-allowed disabled">
                            <div className="frame-492">
                                <img className="info-circle2" src={InfoCircle} alt="" />
                                <div className="title">Country</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={companyAddressCountry ? "frame-11" : "frame-11"}></div>{" "}
                                {/* enabled is frame-493 */}
                                <div className="search2">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            value={companyAddressCountry}
                                        />
                                        <img className="chevron-down" src={ChevronDown} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className={companyAddressCountry ? "frame-KYC" : "frame-50"}>KYC</div>
                        </div>
                        <div className="dropdown-selector hover-not-allowed disabled">
                            <div className="frame-492">
                                <img className="info-circle3" src={InfoCircle} alt="" />
                                <div className="title">City</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={companyCity ? "frame-11" : "frame-11"}></div>{" "}
                                {/* enabled is frame-493 */}
                                <div className="search2">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            value={companyCity}
                                        />
                                        <img className="chevron-down2" src={ChevronDown} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className={companyCity ? "frame-KYC" : "frame-50"}>KYC</div>
                        </div>
                    </div>
                    <div className="frame-2482">
                        <div className="simple hover-not-allowed disabled">
                            <div className="frame-51">
                                <img className="info-circle4" src={InfoCircle} alt="" />
                                <div className="title">Postal Code</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={companyPostalCode ? "frame-11" : "frame-11"}></div>{" "}
                                {/* enabled is frame-493 */}
                                <div className="search">
                                    <div className="frame-48">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            value={companyPostalCode}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={companyPostalCode ? "frame-KYC" : "frame-50"}>KYC</div>
                        </div>
                        <div className="simple hover-not-allowed disabled">
                            <div className="frame-51">
                                <img className="info-circle5" src={InfoCircle} alt="" />
                                <div className="title">Address 1</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={companyAddressOne ? "frame-11" : "frame-11"}></div>{" "}
                                {/* enabled is frame-493 */}
                                <div className="search">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            value={companyAddressOne}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={companyAddressOne ? "frame-KYC" : "frame-50"}>KYC</div>
                        </div>
                        <div className="simple hover-not-allowed disabled">
                            <div className="frame-51">
                                <img className="info-circle6" src={InfoCircle} alt="" />
                                <div className="title">Address 2</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={"frame-11"}></div>
                                <div className="search">
                                    <div className="frame-48">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            value={companyAddressTwo}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                    </div>
                    <div className="frame-249">
                        <div className="simple hover-not-allowed disabled">
                            <div className="frame-51">
                                <img className="info-circle8" src={InfoCircle} alt="" />
                                <div className="title">Latitude</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={companyLongitude ? "frame-11" : "frame-11"}></div>{" "}
                                {/* enabled is frame-493 */}
                                <div className="search">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            value={companyLongitude}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="simple hover-not-allowed disabled">
                            <div className="frame-51">
                                <img className="info-circle9" src={InfoCircle} alt="" />
                                <div className="title">Longitude</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={companyLatitude ? "frame-11" : "frame-11"}></div>{" "}
                                {/* enabled is frame-493 */}
                                <div className="search">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            value={companyLatitude}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="simple hover-not-allowed"></div>
                    </div>
                </div>
                <div className="map">
                    <div className="frame-241">
                        <img className="info-circle10" src={InfoCircle} alt="" />
                        <div className="on-map">On map</div>
                    </div>
                    {!companyLongitude || !companyLatitude ? (
                        <div className="frame-483">
                            <div className="frame-244">
                                <div className="frame-178">
                                    <img className="map-01" src={Map} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <GoogleMapsStore
                            companyDetails={companyDetails}
                            setCompanyDetails={setCompanyDetails}
                            storeName={storeName}
                        />
                    )}
                </div>
            </div>
        </StyledStoreLocation>
    );
}

const GoogleMapsStore = ({ storeName, companyDetails, setCompanyDetails }) => {
    const { companyLatitude, companyLongitude } = companyDetails;

    const location = { lat: companyLatitude, lng: companyLongitude };
    const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;
    const options = { closeBoxURL: "", enableEventPropagation: false };

    /* #region EFFECTS */

    /* #endregion */

    /* #region METHODS */
    const handleDragMarker = async e => {
        const center = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        };

        setCompanyDetails({
            ...companyDetails,
            companyLatitude: center.lat,
            companyLongitude: center.lng
        });

        getPlusCodeUsingLatLng(center.lat, center.lng);

        const res = await fetchAddressFromCoordinates(center.lat, center.lng);
        if (res) {
            setCompanyDetails(prev => ({
                ...prev,
                companyAddressOne: res?.street,
                companyAddressTwo: res?.streetNumber,
                companyPostalCode: res?.postCode,
                companyAddressCountry: res?.country,
                companyCity: res?.city
            }));
        }
    };

    const getPlusCodeUsingLatLng = (lat, lng) => {
        axios.get("https://plus.codes/api?address=" + lat + "," + lng).then(res => {
            if (res.data?.status === "OK") {
                setCompanyDetails(prev => ({
                    ...prev,
                    googlePlusCode: res.data?.plus_code?.global_code
                }));
            }
        });
    };

    /* #endregion */

    return (
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
            <GoogleMap
                mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px"
                }}
                center={location}
                zoom={10}
                clickableIcons={false}
                options={{
                    disableDefaultUI: true,
                    zoomControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    keyboardShortcuts: false,
                    streetViewControlOptions: false,
                    styles: [
                        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                        {
                            elementType: "labels.text.stroke",
                            stylers: [{ color: "#242f3e" }]
                        },
                        {
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#909eb2" }]
                        },
                        {
                            featureType: "administrative",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#d3d9e1" }]
                        },
                        {
                            featureType: "landscape",
                            elementType: "labels",
                            stylers: [{ visibility: "off" }]
                        },
                        {
                            featureType: "administrative.locality",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#ffffff" }]
                        },
                        {
                            featureType: "poi",
                            stylers: [{ visibility: "off" }]
                        },
                        {
                            featureType: "poi.business",
                            stylers: [{ visibility: "off" }]
                        },
                        {
                            featureType: "poi.park",
                            elementType: "geometry",
                            stylers: [{ color: "#154043" }]
                        },
                        {
                            featureType: "poi.park",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#6b9a76" }]
                        },
                        {
                            featureType: "road",
                            elementType: "geometry",
                            stylers: [{ color: "#42566d" }]
                        },
                        {
                            featureType: "road",
                            elementType: "geometry.stroke",
                            stylers: [{ color: "#212a37" }]
                        },
                        {
                            featureType: "road",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#9ca5b3" }]
                        },
                        {
                            featureType: "road.highway",
                            elementType: "geometry",
                            stylers: [{ color: "#42566d" }]
                        },
                        {
                            featureType: "road.highway",
                            elementType: "geometry.stroke",
                            stylers: [{ color: "#000000" }]
                        },
                        {
                            featureType: "road.highway",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#ffffff" }]
                        },
                        {
                            featureType: "transit",
                            elementType: "geometry",
                            stylers: [{ color: "#2f3948" }, { visibility: "off" }]
                        },
                        {
                            featureType: "transit.station",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#d59563" }]
                        },
                        {
                            featureType: "water",
                            elementType: "geometry",
                            stylers: [{ color: "#000000" }]
                        },
                        {
                            featureType: "water",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#8ab4f8"
                                }
                            ]
                        },
                        {
                            featureType: "water",
                            elementType: "labels.text.stroke",
                            stylers: [{ color: "#8ab4f8" }]
                        },
                        {
                            selector: ":focus",
                            stylers: [{ outline: "none" }]
                        },
                        {
                            selector: "*:focus",
                            stylers: [{ outline: "none" }]
                        }
                    ],
                    draggable: true
                }}>
                <Marker onDragEnd={handleDragMarker} position={location} draggable={true} />
                <InfoBox options={options} position={location}>
                    <div style={{ width: "140px", opacity: 1, paddingTop: "9px" }}>
                        <div
                            style={{
                                fontSize: "12px",
                                width: "140px",
                                color: "white",
                                padding: "0px",
                                fontWeight: 700
                            }}>
                            {storeName || "Unnamed Store"}
                        </div>
                    </div>
                </InfoBox>
            </GoogleMap>
        </LoadScript>
    );
};
