import DatePickerComponent from "./DatePickerComponent";
import styled from "styled-components";

const StyledPage = styled.div`
    &.dashboad-header-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 24px;
    }
    .header-first-portion {
        display: flex;
        gap: 16px;
        align-items: center;
    }
    .dashboard-header-data-labels {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0px 8px;
        border-radius: 4px;
        background-color: #005fcc30;
        color: #00aeef;
        font-size: 12px;
        font-weight: 500;
    }
    .dashboard-header-btn {
        height: 35px;
        width: 120px;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 400;
        background-color: #1e2631;
        outline: none;
        border: none;
        color: #d1d1d1;
    }
`;

const DashboardHeader = ({ onClick }) => {
    return (
        <StyledPage className="dashboad-header-container">
            <div className="header-first-portion">
                <DatePickerComponent />
                <p>compare to</p>
                <div className="dashboard-custom-date-picker">
                    <i className="fa-light fa-calendar" style={{ color: "#4F5B6D" }}></i>
                    <div className="dates-wrapper" style={{ color: "#4F5B6D" }}>
                        <div style={{ color: "#4F5B6D" }}>26 January 2024</div>-
                        <div style={{ color: "#4F5B6D" }}>2 Feburary 2024</div>
                    </div>
                    <i className="fa-light fa-chevron-down" style={{ color: "#4F5B6D" }}></i>
                </div>
                <div className="dashboard-header-data-labels">
                    <i className="fa-light fa-store"></i>&nbsp; Active stores: 120
                </div>
                <div className="dashboard-header-data-labels">
                    <i className="fa-light fa-tags"></i>&nbsp; Active Brands: 79
                </div>
            </div>
            <div className="header-second-portion">
                <button className="dashboard-header-btn" onClick={onClick}>
                    CHANGE
                </button>
            </div>
        </StyledPage>
    );
};

export default DashboardHeader;
