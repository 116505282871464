import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useOutletContext } from "react-router";
import { APP_TITLE } from "../../../config/constant";
import CardComponentContainer from "../../common/cardContainers/CardComponentContainer";
import BillingPlanCard from "./BillingPlanCard";
import * as Library from "../../../utils/Library";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import BOStageLogo from "../../../assets/images/footer-logologin.png";
import AppContext from "../../../AppContext";
import LayoutContext from "../../../LayoutContext";
import { useDispatch, useSelector } from "react-redux";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";

const StyledPage = styled.div`
    .frame-313325 {
        display: flex;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: var(--Spacings-2xl, 24px);
        align-self: stretch;
        height: 522px;
    }

    .frame-313335 {
        background: var(--text-and-icons-green-500, #00bb00);
        border-radius: 58px;
        padding: 2px 4px 2px 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .save-15 {
        color: var(--backgrounds-and-lines-n-500, #141922);
        text-align: left;
        font-family: var(--label-xs-lowercase-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--label-xs-lowercase-font-size, 8px);
        font-weight: var(--label-xs-lowercase-font-weight, 400);
        position: relative;
    }

    .frame-313326 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .monthly {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--overline-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--overline-font-size, 14px);
        letter-spacing: var(--overline-letter-spacing, 0.015em);
        font-weight: var(--overline-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .toggle-switch {
        flex-shrink: 0;
        width: 45px;
        height: 19px;
        position: relative;
        transform-origin: 0 0;
        //transform: rotate(0deg) scale(-1, 1);
    }
    .rectangle-1 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 54px;
        width: 97.83%;
        height: 86.36%;
        position: absolute;
        right: 0%;
        left: 2.17%;
        bottom: 4.55%;
        top: 9.09%;
    }
    .group-1 {
        position: absolute;
        inset: 0;
    }
    .ellipse-1 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 50%;
        width: 42.22%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 57.78%;
        bottom: 0%;
        top: 0%;

        transition: left 0.4s;
    }
    .ellipse-2 {
        background: var(--text-and-icons-green-500, #00bb00);
        border-radius: 50%;
        width: 23.03%;
        height: 54.55%;
        position: absolute;
        right: 9.6%;
        left: 67.37%;
        bottom: 22.73%;
        top: 22.73%;

        transition: left 0.4s;
    }
    .annual {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--overline-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--overline-font-size, 14px);
        letter-spacing: var(--overline-letter-spacing, 0.015em);
        font-weight: var(--overline-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
`;

export default function BillingPage() {
    document.title = `${APP_TITLE} - Billing`;

    /* #region VARS */
    const navigate = useNavigate();
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { isLoadingApp } = React.useContext(AppContext);
    const { setShowIsSubscribedPopup } = React.useContext(LayoutContext);

    const [viewLayoutStyle, setViewLayoutStyle] = useState("grid");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInvite, setIsLoadingInvite] = useState(false);
    const [changeSubscriptionPlanData, setChangeSubscriptionPlanData] = useState(null);

    const [isMonthly, setIsMonthly] = useState(true);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [subscriptionsData, setSubscriptionsData] = useState(null);
    const [inviteID, setInviteID] = useState(null);

    const [showFirstTimePopup, setShowFirstTimePopup] = useState(
        [null, undefined, "", "undefined", "null"].includes(localStorage.getItem("subscriptionPlanID"))
    );
    const [isSubscribing, setIsSubscribing] = useState(false);

    const navigations = useSelector(state => state.navigationAccesses);
    const dispatch = useDispatch();

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    /* #endregion */

    /* #region METHODS */
    const getSubscriptionPlanSetRequest = async () => {
        const subscriptionPlanSetID = "66a3497854938ff3b9321a54";
        const res = await Library.makeCommonPostRequest(
            "Organization",
            "getSubscriptionPlanSet",
            {
                subscriptionPlanSetID: subscriptionPlanSetID
            },
            false,
            setIsLoadingInvite
        );

        if (res.data.status !== 200) return;
        const data = res.data?.data;

        setSubscriptionPlans(data?.subscriptionPlans);
        setSubscriptionsData(data);
    };

    const onSelectPlan = subscriptionPlanData => {
        const currentSubscriptionPlanID = localStorage.getItem("subscriptionPlanID");

        if (currentSubscriptionPlanID === subscriptionPlanData?._id) {
            Library.showWarningMessage("You are already subscribed to this plan");
            return;
        }
        setChangeSubscriptionPlanData(subscriptionPlanData);
    };

    const onSelectPlanRequest = async subscriptionPlanData => {
        const subscriptionPlanID = subscriptionPlanData?._id;
        const subscriptionPlanName = subscriptionPlanData?.displayName;

        setChangeSubscriptionPlanData(null);
        const res = await Library.makeCommonPostRequest(
            "Organization",
            "setSubscriptionPlan",
            {
                organizationID: localStorage.getItem("currentOrganizationID"),
                subscriptionPlanID: subscriptionPlanID,
                email: localStorage.getItem("userEmail")
            },
            false,
            setIsSubscribing
        );

        if (res.data.status !== 200) return;

        if (inviteID) updateInviteRequest(inviteID);
        const updatedNavigations = {
            ...navigations.navigationAccesses,
            organization: {
                ...navigations.navigationAccesses.organization,
                navigationPanel: true,
                createModeOnly: true
            }
        };
        dispatch(setNavigations({ navigationAccesses: updatedNavigations }));

        localStorage.setItem("billingPlan", subscriptionPlanName || "--");
        localStorage.setItem("subscriptionPlanID", subscriptionPlanID);
        navigate("/account/create");
        setShowIsSubscribedPopup(true);
    };

    const updateInviteRequest = inviteID => {
        Library.makeCommonPostRequest(
            "Accounts",
            "updateInvite",
            { inviteID: inviteID, status: "Enrolled" },
            false,
            setIsLoading
        ).then(res => {
            if (res.data.status !== 200) {
                return;
            }
            localStorage.setItem("status", "Enrolled");
        });
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);

    useEffect(() => {
        getSubscriptionPlanSetRequest();
    }, []);

    useEffect(() => {
        const invite = localStorage.getItem("invite");
        if (invite) {
            const inviteData = JSON.parse(invite);
            if (inviteData?.status?.status === "Pre-Enrolled" || inviteData?.status?.status === "Enrolling") {
                setInviteID(inviteData._id);
            }
        }
    }, []);

    /* #endregion */

    return (
        <StyledPage>
            <FullScreenOverlay show={showFirstTimePopup && !isLoading && !isLoadingInvite}>
                <DialogBoxComponent
                    title={`
                        <span style="line-height: 12px">
                        Thank you for<br /> pre-enrolling on BOtag</span>`}
                    message={`<div style='font-size: 12px; padding-top: 16px; margin: 0 -5px;'>
                        Your invitation includes a two-month trial offer.
                        <br />We will notify you shortly when the trial period <br />commences. You may cancel at any time during the <br />trial period.</div>`}
                    onClickYes={() => {
                        setShowFirstTimePopup(false);
                    }}
                    headingColor="var(--text-icons-green-500)"
                    confirmText="CONTINUE"
                    showCancel={false}
                    useImageHeader={{ src: BOStageLogo, width: "165px" }}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={isSubscribing && !isLoading && !isLoadingInvite}>
                <DialogBoxComponent
                    heading="SUBSCRIBING.."
                    headingColor="green"
                    loading={true}
                    showCancel={false}
                    showConfirm={false}
                />
            </FullScreenOverlay>

            <FullScreenOverlay show={changeSubscriptionPlanData !== null}>
                <DialogBoxComponent
                    title={`
                        <span style="line-height: 12px">
                        You selected ‘${changeSubscriptionPlanData?.displayName}’ <span style="font-size: 10px">(${
                        isMonthly ? "monthly" : "annual"
                    } fees)</span></span>`}
                    message={`<div style='font-size: 12px; padding-top: 10px; margin: 0 -5px;'>
                        Cancel anytime during trial.<br />Reminder email sent 7 days before the free period ends.<br />${
                            changeSubscriptionPlanData?.displayName
                        } starts post-trial, billed ${isMonthly ? "monthly" : "annually"} in advance.
                        </div>`}
                    onClickYes={() => {
                        onSelectPlanRequest(changeSubscriptionPlanData);
                    }}
                    loading={isLoading}
                    headingColor="var(--text-icons-green-500)"
                    loadingHeading={"SUBSCRIBING.."}
                    onClickNo={() => {
                        setChangeSubscriptionPlanData(null);
                    }}
                    cancelText="CANCEL"
                    confirmText="CONFIRM"
                />
            </FullScreenOverlay>

            <ContainerLoader
                isLoading={!isLoadingApp && (isLoading || isLoadingInvite)}
                isLoadingOverlay={false}
                className="w-100"
                //loadingPositionFromTop="300px"
                alignPageCenter={true}>
                <CardComponentContainer
                    title="Subscription Plans"
                    layoutStyle={viewLayoutStyle}
                    style={{ paddingTop: "14px" }}
                    middleComponent={
                        <MonthlyAnualSlider
                            isMonthly={isMonthly}
                            setIsMonthly={setIsMonthly}
                            annualDiscountPercentage={subscriptionsData?.annualDiscountPercentage}
                        />
                    }>
                    <div className="frame-313325" style={{ marginTop: "24px" }}>
                        {subscriptionPlans.map((plan, index) => (
                            <BillingPlanCard
                                key={index}
                                isMonthly={isMonthly}
                                subscriptionPlanData={plan}
                                onSelectPlan={onSelectPlan}
                                showLabelBrandPrices={APP_TITLE === "BOstageB"}
                            />
                        ))}
                    </div>
                </CardComponentContainer>
            </ContainerLoader>
        </StyledPage>
    );
}

function setupHeaders(props) {
    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([{ to: "/billing", active: true, disabled: false, title: "BILLING" }]);
    props.setControlBarRightBtns([
        { to: "/billing", active: true, disabled: false, title: "Plans", icon: "file-check-01" },
        { to: "/billing/invoices", active: false, disabled: true, title: "Invoices", icon: "file-check-02" },
        { to: "/billing/methods", active: false, disabled: true, title: "Methods", icon: "credit-card-check" }
    ]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: undefined
    });
}

/* #region SMALL COMPONENTS */

const MonthlyAnualSlider = ({ isMonthly, setIsMonthly, annualDiscountPercentage }) => {
    return (
        <>
            <div className="frame-313326 cursor-pointer" onClick={() => setIsMonthly(!isMonthly)}>
                <div className="monthly">MONTHLY</div>
                <div className="toggle-switch">
                    <div className="rectangle-1"></div>
                    <div className="ellipse-1" style={{ left: isMonthly ? "0" : "" }}></div>
                    <div className="ellipse-2" style={{ left: isMonthly ? "4px" : "" }}></div>
                </div>
                <div className="annual">ANNUAL</div>
                <div className="frame-313335">
                    <div className="save-15">Save ~{annualDiscountPercentage}%</div>
                </div>
            </div>
        </>
    );
};

/* #endregion */
