import React from "react";
import BtnLargeSolid from "../../../common/buttons/BtnLargeSolid";
import styled from "styled-components";

const StyledPage = styled.div`
    &.content-header,
    &.content-header * {
        box-sizing: border-box;
    }
    &.content-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        padding: 15px 24px 15px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 66px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
`;

/**
 * Styled component for the page header.
 *
 * @param {Object} props - The props of the component.
 * @param {Function} props.onClick - The function to call when the button is clicked.
 * @param {String} props.btnTitle - The title of the button.
 * @param {Boolean} props.btnEnabled - The flag to enable or disable the button.
 */
export default function SimpleButtonContentHeader(props) {
    const { onClick, btnTitle, btnEnabled } = props;

    return (
        <StyledPage className="content-header simple-button-content-header">
            <div className="frame-40">
                <div className="btn-large-solid">
                    <BtnLargeSolid className="mr-7px" text={btnTitle} disabled={!btnEnabled} onClick={onClick} />
                </div>
            </div>
        </StyledPage>
    );
}
