import React, { useRef } from "react";
import styled from "styled-components";
import InfoCircle from "../../../../assets/icons/info-circle-white.svg";
import ReactSelect from "react-select";
const CompanyIDCardDiv = styled.div`
    &.main-company-card-container {
        padding: 16px 0px 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .company-card-column {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
    }
    .box {
        width: 25%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
    }
    .box-label {
        display: flex;
        flex-direction: row;
        gap: 4px;
        font-size: 10px;
        color: #7c868b;
    }
    .input-container {
        background: #10141b;
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex: 1 1 0%;
        height: 38px;
        position: relative;
    }
    input.input-box {
        border: none;
        outline: none;
        background: none;
        color: #00bb00;
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1 1 0%;
    }
    .group-input {
        display: flex;
        flex-direction: row;
    }
    .filled {
        height: 38px;
        width: 5px;
        background: #141922;
        border-radius: 4px 0px 0px 4px;
    }
    .mondatory {
        height: 38px;
        width: 5px;
        background: #ff9900;
        border-radius: 4px 0px 0px 4px;
    }
    .disabled {
        height: 38px;
        width: 5px;
        background: #4f5b6d;
        border-radius: 4px 0px 0px 4px;
    }
    .input-container.select {
        padding: 0px;
    }
    .selects-main {
        width: 100%;
        background: #10141b;
    }
    /*.css-13cymwt-control,
    .css-t3ipsp-control,
    .css-t3ipsp-control:hover {
        background: transparent;
        border: 0px;
        box-shadow: none;
    }
    span.css-1u9des2-indicatorSeparator {
        display: none;
    }*/
`;
export default function BrandIDCard({
    brand,
    setBrand,
    toCompany,
    setToCompany,
    industry,
    setIndustry,
    lifestyle,
    setLifestyle,
    productCategory,
    setProductCategory,
    website,
    setWebsite,
    instagram,
    setInstagram,
    facebook,
    setFacebook
}) {
    const industryOptions = [
        { key: "Option 1", label: "Option 1" },
        { key: "Option 2", label: "Option 2" },
        { key: "Option 3", label: "Option 3" },
        { key: "Option 4", label: "Option 4" }
    ];
    return (
        <CompanyIDCardDiv className="main-company-card-container">
            <div className="company-card-row">
                <div className="company-card-column">
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Brand</div>
                        </div>
                        <div className="group-input">
                            <div className={brand ? "filled" : "mondatory"}></div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input-box"
                                    value={brand}
                                    onChange={e => setBrand(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">To Company</div>
                        </div>
                        <div className="group-input">
                            <div className={toCompany ? "filled" : "mondatory"}></div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input-box"
                                    value={toCompany}
                                    onChange={e => setToCompany(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Industry</div>
                        </div>
                        <div className="group-input">
                            <div className={industry ? "filled" : "mondatory"}></div>
                            <div className="input-container select">
                                <ReactSelect
                                    className="selects-main"
                                    options={industryOptions}
                                    placeholder=""
                                    styles={{
                                        option: (styles, { isSelected }) => ({
                                            ...styles,
                                            "backgroundColor": "#1e2631",
                                            "color": "#00bb00",
                                            ":hover": {
                                                border: "1px solid #00bb00"
                                            },
                                            ":active": {
                                                border: "1px solid #00bb00"
                                            },
                                            "border": "1px solid #1e2631",
                                            "marginBottom": 5,
                                            ":focus": {
                                                backgroundColor: "#333" // Change the background color when clicked
                                            }
                                        }),
                                        menu: styles => ({
                                            ...styles,
                                            backgroundColor: "#10141b"
                                        }),
                                        control: styles => ({
                                            ...styles,
                                            "color": "#00bb00",
                                            "background": "transparent",
                                            "border": "0px",
                                            "boxShadow": "none",
                                            "&:placeholder": {
                                                color: "#ccc" // Change the placeholder color
                                            }
                                        }),
                                        singleValue: styles => ({
                                            ...styles,
                                            color: "#00bb00"
                                        }),
                                        indicatorSeparator: styles => ({
                                            ...styles,
                                            backgroundColor: "transparent"
                                        }),
                                        dropdownIndicator: styles => ({
                                            ...styles,
                                            color: "#D1D1D1"
                                        })
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null // Remove the separator
                                    }}
                                    onChange={e => setIndustry(e.key)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Design/Lifestyle</div>
                        </div>
                        <div className="group-input">
                            <div className={lifestyle ? "filled" : "mondatory"}></div>
                            <div className="input-container select">
                                <ReactSelect
                                    className="selects-main"
                                    options={industryOptions}
                                    placeholder=""
                                    styles={{
                                        option: (styles, { isSelected }) => ({
                                            ...styles,
                                            "backgroundColor": "#1e2631",
                                            "color": "#00bb00",
                                            ":hover": {
                                                border: "1px solid #00bb00"
                                            },
                                            ":active": {
                                                border: "1px solid #00bb00"
                                            },
                                            "border": "1px solid #1e2631",
                                            "marginBottom": 5,
                                            ":focus": {
                                                backgroundColor: "#333" // Change the background color when clicked
                                            }
                                        }),
                                        menu: styles => ({
                                            ...styles,
                                            backgroundColor: "#10141b"
                                        }),
                                        control: styles => ({
                                            ...styles,
                                            "color": "#00bb00",
                                            "background": "transparent",
                                            "border": "0px",
                                            "boxShadow": "none",
                                            "&:placeholder": {
                                                color: "#ccc" // Change the placeholder color
                                            }
                                        }),
                                        singleValue: styles => ({
                                            ...styles,
                                            color: "#00bb00"
                                        }),
                                        indicatorSeparator: styles => ({
                                            ...styles,
                                            backgroundColor: "transparent"
                                        }),
                                        dropdownIndicator: styles => ({
                                            ...styles,
                                            color: "#D1D1D1"
                                        })
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null // Remove the separator
                                    }}
                                    onChange={e => setLifestyle(e.key)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-card-row">
                <div className="company-card-column">
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Main product category</div>
                        </div>
                        <div className="group-input">
                            <div className={productCategory ? "filled" : "mondatory"}></div>
                            <div className="input-container select">
                                <ReactSelect
                                    className="selects-main"
                                    options={industryOptions}
                                    placeholder=""
                                    styles={{
                                        option: (styles, { isSelected }) => ({
                                            ...styles,
                                            "backgroundColor": "#1e2631",
                                            "color": "#00bb00",
                                            ":hover": {
                                                border: "1px solid #00bb00"
                                            },
                                            ":active": {
                                                border: "1px solid #00bb00"
                                            },
                                            "border": "1px solid #1e2631",
                                            "marginBottom": 5,
                                            ":focus": {
                                                backgroundColor: "#333" // Change the background color when clicked
                                            }
                                        }),
                                        menu: styles => ({
                                            ...styles,
                                            backgroundColor: "#10141b"
                                        }),
                                        control: styles => ({
                                            ...styles,
                                            "color": "#00bb00",
                                            "background": "transparent",
                                            "border": "0px",
                                            "boxShadow": "none",
                                            "&:placeholder": {
                                                color: "#ccc" // Change the placeholder color
                                            }
                                        }),
                                        singleValue: styles => ({
                                            ...styles,
                                            color: "#00bb00"
                                        }),
                                        indicatorSeparator: styles => ({
                                            ...styles,
                                            backgroundColor: "transparent"
                                        }),
                                        dropdownIndicator: styles => ({
                                            ...styles,
                                            color: "#D1D1D1"
                                        })
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null // Remove the separator
                                    }}
                                    onChange={e => setProductCategory(e.key)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Website</div>
                        </div>
                        <div className="group-input">
                            <div className={website ? "filled" : "mondatory"}></div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input-box"
                                    value={website}
                                    onChange={e => setWebsite(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Instagram</div>
                        </div>
                        <div className="group-input">
                            <div className={instagram ? "filled" : "mondatory"}></div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input-box"
                                    value={instagram}
                                    onChange={e => setInstagram(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Facebook</div>
                        </div>
                        <div className="group-input">
                            <div className={facebook ? "filled" : "mondatory"}></div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input-box"
                                    value={facebook}
                                    onChange={e => setFacebook(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CompanyIDCardDiv>
    );
}
