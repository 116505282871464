import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import { useNavigate, useOutletContext, useParams } from "react-router";
import * as Library from "../../../utils/Library";
import * as Formatter from "../../../utils/Formatter";
import CardComponentContainer from "../../common/cardContainers/CardComponentContainer";
import { ReactComponent as Hand } from "../../../assets/icons/hand.svg";
import { ReactComponent as Pencil } from "../../../assets/icons/pencil-01.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash-01.svg";
import { ReactComponent as InfoCircle } from "../../../assets/icons/info-circle0.svg";
import { ReactComponent as Image030 } from "../../../assets/icons/image-030.svg";
import { ReactComponent as Rectangle10 } from "../../../assets/icons/rectangle-10.svg";
import { ReactComponent as Rectangle20 } from "../../../assets/icons/rectangle-20.svg";
import { ReactComponent as BOIkon1 } from "../../../assets/icons/bo-ikon-10.svg";
import { ReactComponent as Icon0 } from "../../../assets/icons/icon0.svg";
import { ReactComponent as Check0 } from "../../../assets/icons/check0.svg";
import { ReactComponent as Cellular0 } from "../../../assets/icons/cellular0.svg";
import { ReactComponent as Wifi0 } from "../../../assets/icons/wifi0.svg";
import { ReactComponent as Battery0 } from "../../../assets/icons/battery0.svg";
import { ReactComponent as Time0 } from "../../../assets/icons/time0.svg";
import { ReactComponent as Frame29159 } from "../../../assets/icons/frame-291590.svg";
import { ReactComponent as EyeOff0 } from "../../../assets/icons/eye-off0.svg";
import { ReactComponent as Share070 } from "../../../assets/icons/share-070.svg";
import { ReactComponent as Edit020 } from "../../../assets/icons/edit-020.svg";
import { ReactComponent as RefreshCcw01 } from "../../../assets/icons/refresh-ccw-01.svg";
import { ReactComponent as FlipBackward } from "../../../assets/icons/flip-backward0.svg";
import SvgjsRect1010 from "../../../assets/images/SvgjsRect1010.png";
import FullScreenLoader from "../../common/loaders/FullScreenLoader";
import { formatDefaultDate } from "../../../utils/Formatter";
import ImageBeacons from "./ImageBeacons";
import { getSpanWithColor } from "../../../utils/CardUtils";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        //overflow-y: hidden;
        //height: 100%;
        margin-right: -23px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .frame-191 {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        margin-top: 24px;
    }

    .separator-class {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
        height: 1px;
        width: 100%;
        background: var(--backgrounds-and-lines-n-100, #3c485a) !important;
    }
`;

const StyledBOImagesDetailsHeader = styled.div`
    &.main-window-header-detail,
    &.main-window-header-detail * {
        box-sizing: border-box;
    }
    &.main-window-header-detail {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        padding: 15px 24px;
    }
    .frame-34 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-52 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313357 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .input-link-disabled {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .inverted-field {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 385px;
        position: relative;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 38px;
        position: relative;
    }
    .frame-50 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-48 {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .order-g-00019134-xlsx {
        text-align: left;
        font-family: "-", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .order-g-00019134-xlsx-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: "Roboto-Bold", sans-serif;
        font-size: 12px;
        font-weight: 700;
    }
    .order-g-00019134-xlsx-span2 {
        color: var(--text-and-icons-green-500, #00bb00);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
    }
    .icon-search {
        flex-shrink: 0;
        width: 38px;
        height: 38px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(90deg) scale(1, -1);
    }
    .icon-edit-disabled {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 97.37%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 2.63%;
        bottom: 0%;
        top: 0%;
    }
    .edit-02 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .btn-large-solid {
        background: var(--App-Accent-hover, #009900);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .btn-large-solid.disabled {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        cursor: not-allowed;
    }
    .btn-large-solid.disabled .button {
        color: var(--text-icons-gray-300) !important;
    }
    .button {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
    .frame-261 {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-51 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .status-block-ready {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 5px;
        padding: 11.5px 12px 11.5px 12px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .status-title {
        color: var(--text-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .status-ready {
        color: var(--text-icons-green-500, #0b0);

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .frame-313278 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-160 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .refresh-icon {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 37px;
        height: 38px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(90deg) scale(1, -1);
    }
    .refresh-ccw-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
        transform-origin: 0 0;
        transform: rotate(90deg) scale(1, -1);
    }
    .back-icon {
        flex-shrink: 0;
        width: 38px;
        height: 38px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(90deg) scale(1, -1);
    }
    .frame-29 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 97.37%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 2.63%;
        bottom: 0%;
        top: 0%;
    }
    .flip-backward {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
        transform-origin: 0 0;
        transform: rotate(90deg) scale(1, -1);
    }
`;

const StyledBOImageLeftSection = styled.div`
    &.id-info-card {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 8px 8px 16px 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        //width: 802px;
        height: 613px;
        position: relative;
        flex-shrink: 1;
        flex-basis: 63%;
        position: relative;
        width: calc(63% - 316px);
    }
    .BOImageContainer {
        width: 100%;
        box-sizing: content-box;
        height: calc(100vh - 140px);
        position: relative;
    }
    .BoimageCanvas {
        width: 100%;
        height: 100%;
        object-fit: contain;
        flex: 1 1 0;
        //border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 9px;
        display: inline-flex;
        overflow: hidden;
        position: relative;
    }
    .frame-197 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-198 {
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 423px;
        position: relative;
        object-fit: contain;
    }
    .frame-172 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-161 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 15px;
    }
    .boimage2 {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--label-medium-uppercase-font-family, "Inter-Regular", sans-serif);
        font-size: var(--label-medium-uppercase-font-size, 12px);
        font-weight: var(--label-medium-uppercase-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
    .frame-162 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .pencil-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .hand {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .trash-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .botag-id {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        align-self: stretch;
    }
    .frame-171 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 9px;
    }
    .details {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--label-xs-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-xs-uppercase-font-size, 8px);
        letter-spacing: var(--label-xs-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-xs-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .line-1 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
    }
    .frame-204 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
        //nowrap

        white-space: nowrap;
    }
    .frame-169 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        width: calc(50% - 24px);
        overflow: hidden;
    }
    .frame-170 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        width: calc(50% - 24px);
        overflow: hidden;
    }
    .frame-169 > div,
    .frame-170 > div {
        height: 14px;
    }

    .card-details-item {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .active-period {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .div {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: right;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .revenue {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .items {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .current-status {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .statuses {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .alert-text {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .line-7 {
        margin-top: -1px;
        border-style: solid;
        background: var(--backgrounds-and-lines-n-100, #3c485a);
        border: 1px;
        align-self: stretch;
        flex-shrink: 0;
        width: 1px;
        height: 100%;
        position: relative;
    }
    .to-brand {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .to-collection {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .gender-target {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .age-groups {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
`;

const StyledBOImageMiddleSection = styled.div`
    &.frame-313355 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 613px;
        position: relative;
        flex-shrink: 1;
        flex-basis: 37%;
        width: calc(37% - 316px);
    }
    .chartCustom {
        flex-shrink: 1;
        width: 20vw;
    }
    .frame-313309 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 56px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        overflow: hidden;
    }
    .frame-313284 {
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        align-self: stretch;
        flex: 1;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .frame-313288 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .frame-313293 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313291 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313286 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .heading-4-total-visitors {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .info-circle {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .frame-313290 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313289 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    ._0-items {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        position: relative;
        align-self: stretch;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._0-items-span {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 40px;
        letter-spacing: -0.005em;
        font-weight: 400;
    }
    ._0-items-span2 {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        font-family: var(--heading-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-1-font-size, 28px);
        letter-spacing: var(--heading-1-letter-spacing, -0.015em);
        font-weight: var(--heading-1-font-weight, 400);
    }
    .frame-313287 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .compared-to-last-30-days {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--heading-4-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-4-font-size, 18px);
        font-weight: var(--heading-4-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .no-data-to-display-chart {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: center;
        font-family: var(--heading-4-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-4-font-size, 18px);
        font-weight: var(--heading-4-font-weight, 400);
        position: relative;
        width: 100%;
        height: 93px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313311 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 56px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        overflow: hidden;
    }
    .info-circle2 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    ._0-nok {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        position: relative;
        align-self: stretch;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._0-nok-span {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 40px;
        letter-spacing: -0.005em;
        font-weight: 400;
    }
    ._0-nok-span2 {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        font-family: var(--heading-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-1-font-size, 28px);
        letter-spacing: var(--heading-1-letter-spacing, -0.015em);
        font-weight: var(--heading-1-font-weight, 400);
    }
    .frame-1443 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .frame-313296 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313294 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        flex-basis: 50%;
        width: 50%;
    }
    .total-orders {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        align-self: stretch;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._0 {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 40px;
        letter-spacing: -0.015em;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .avg-orders {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: right;
        font-family: var(--subtitle-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        align-self: stretch;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    ._0-kr {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: right;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 40px;
        letter-spacing: -0.015em;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

const StyledBOImageRightSection = styled.div`
    &.group-5 {
        flex-shrink: 0;
        width: 316px;
        height: 613px;
        position: relative;
        flex-shrink: 0;
    }
    .rectangle-1 {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
        overflow: visible;
    }
    .rectangle-2 {
        width: 93.29%;
        height: 96.17%;
        position: absolute;
        right: 3.21%;
        left: 3.5%;
        bottom: 1.7%;
        top: 2.13%;
        overflow: visible;
    }
    .image-03 {
        width: 14.87%;
        height: 7.09%;
        position: absolute;
        right: 42.86%;
        left: 42.27%;
        bottom: 46.24%;
        top: 46.67%;
        overflow: visible;
    }
`;

const StyledBOImagesStats = styled.div`
    .frame-313319 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313287 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313297 {
        display: flex;
        flex-direction: row;
        gap: 56px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313284 {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313288 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313293 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313292 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313291 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313286 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313326 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .heading-4-total-visitors {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .info-circle {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .frame-188 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-186 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .see-more {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
    .frame-313290 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313289 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    ._0-nok {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        position: relative;
        align-self: stretch;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._0-nok-span {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        font-family: var(--heading-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-2-font-size, 24px);
        letter-spacing: var(--heading-2-letter-spacing, -0.005em);
        font-weight: var(--heading-2-font-weight, 400);
    }
    ._0-nok-span2 {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        font-family: var(--heading-4-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-4-font-size, 18px);
        font-weight: var(--heading-4-font-weight, 400);
    }
    .frame-3132872 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .chevron-up {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .compared-to-last-30-days {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .nothing-to-show-here {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
        align-self: stretch;
        height: 12px;
    }
    .frame-3132882 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3132972 {
        display: flex;
        flex-direction: row;
        gap: 56px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3132842 {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .frame-3132883 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3132932 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313310 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313327 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .info-circle2 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .nothing-to-show-here2 {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
        align-self: stretch;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-3132902 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .info-circle3 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .see-more2 {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--label-small-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-small-uppercase-font-size, 10px);
        line-height: var(--label-small-uppercase-line-height, 5px);
        letter-spacing: var(--label-small-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-small-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
        width: 63px;
    }
    ._0-nok2 {
        color: var(--text-and-icons-gray-300, #00aeef);
        text-align: left;
        position: relative;
        align-self: stretch;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._0-nok-2-span {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        font-family: var(--heading-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-2-font-size, 24px);
        letter-spacing: var(--heading-2-letter-spacing, -0.005em);
        font-weight: var(--heading-2-font-weight, 400);
    }
    ._0-nok-2-span2 {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        font-family: var(--heading-4-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-4-font-size, 18px);
        font-weight: var(--heading-4-font-weight, 400);
    }
    .chevron-up2 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
`;

const StyledBOImageMobile = styled.div`
    .b-oimage-emulator,
    .b-oimage-emulator * {
        box-sizing: border-box;
    }
    .b-oimage-emulator {
        flex-shrink: 0;
        width: 316px;
        height: 613px;
        position: relative;
        overflow: hidden;
    }
    .rectangle-1 {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
        overflow: visible;
    }
    .frame-295922362 {
        border-radius: 45px;
        width: 294px;
        height: 585px;
        position: absolute;
        left: 12px;
        top: 14px;
        overflow: hidden;
    }
    .rectangle-2 {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
        overflow: visible;
    }
    .group-3577 {
        position: absolute;
        inset: 0;
    }
    .sams-e-b-oimage-3-1 {
        width: 100%;
        height: 71.97%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 10.26%;
        top: 17.78%;
        object-fit: cover;
    }
    .frame-313867 {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 6999.3px;
        padding: 8.4px 11.2px 8.4px 11.2px;
        display: flex;
        flex-direction: row;
        gap: 7px;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: calc(50% - 64px);
        top: calc(50% - 22.5px);
        backdrop-filter: blur(10px);
    }
    .beacon-new {
        width: 5.44%;
        height: 2.74%;
        position: absolute;
        right: 59.86%;
        left: 34.69%;
        bottom: 55.21%;
        top: 42.05%;
    }
    .ellipse-103 {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        width: 24.96px;
        height: 24.96px;
        position: absolute;
        left: calc(50% - 12.8px);
        top: calc(50% - 12.8px);
        backdrop-filter: blur(10px);
    }
    .ellipse-102 {
        background: #ffffff;
        border-radius: 50%;
        width: 68%;
        height: 68%;
        position: absolute;
        right: 16%;
        left: 16%;
        bottom: 16%;
        top: 16%;
    }
    .skjorter {
        color: #ffffff;
        text-align: center;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 8.399999618530273px;
        line-height: 14px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header {
        background: var(--surface-surface-secondary, #121212);
        border-style: solid;
        border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.1));
        border-width: 0px 0px 1px 0px;
        padding: 24px 16px 24px 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 294px;
        height: 52px;
        position: absolute;
        left: 0px;
        top: 52px;
    }
    .frame-8 {
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .bo-ikon-1 {
        flex-shrink: 0;
        width: 33.78px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .frame-29219 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29517 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .shopping-bag-03 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: hidden;
    }
    .icon {
        width: 76.65%;
        height: 75%;
        position: absolute;
        right: 11.68%;
        left: 11.67%;
        bottom: 12.5%;
        top: 12.5%;
        overflow: visible;
    }
    .check {
        border-radius: 31px;
        width: 14px;
        height: 58.33%;
        position: absolute;
        left: calc(50% - 1px);
        bottom: 0%;
        top: 41.67%;
        overflow: visible;
    }
    .frame-29159 {
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
        overflow: visible;
    }
    .status-bar {
        background: var(--surface-surface-secondary, #121212);
        padding: 25px 14px 15px 34px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        top: calc(50% - 292.5px);
    }
    .time {
        flex-shrink: 0;
        width: 26.62px;
        height: 10.36px;
        position: relative;
        overflow: visible;
    }
    .stack {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 12px;
        position: relative;
    }
    .cellular {
        flex-shrink: 0;
        width: 17px;
        height: 10.67px;
        position: relative;
        overflow: visible;
    }
    .wifi {
        flex-shrink: 0;
        width: 15.27px;
        height: 10.97px;
        position: relative;
        overflow: visible;
    }
    .battery {
        flex-shrink: 0;
        width: 24.33px;
        height: 11.33px;
        position: relative;
        overflow: visible;
    }
    .footer {
        background: var(--surface-surface-secondary, #121212);
        border-style: solid;
        border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.1));
        border-width: 1px 0px 0px 0px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        width: 294px;
        height: 60px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        overflow: hidden;
    }
    .frame-12 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-14 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .mobile-footer-brand {
        flex-shrink: 0;
        width: 107px;
        height: 35px;
        position: relative;
        object-fit: contain;
    }
    .frame-15 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-2 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29153 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .eye-off {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .frame-29155 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .share-07 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .image-03 {
        width: 14.87%;
        height: 7.09%;
        position: absolute;
        right: 42.86%;
        left: 42.27%;
        bottom: 46.24%;
        top: 46.67%;
        overflow: hidden;
    }
`;

const StyledBrandsSalesItem = styled.div`
    &.frame-313304,
    &.frame-313304 * {
        box-sizing: border-box;
    }
    &.frame-313304 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .frame-313299 {
        background: var(--backgrounds-and-lines-n-300, #1a222d);
        border-radius: 4px;
        padding: 4px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .frame-313296 {
        padding: 0px 4px 0px 0px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313294 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313325 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 60px;
        height: 30px;
        position: relative;
    }
    .replay-logo-1 {
        align-self: stretch;
        flex-shrink: 0;
        height: 16px;
        position: relative;
        object-fit: cover;
    }

    .frame-313298 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .follestad-hamar {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313295 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    ._13-936-07-nok {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: left;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._13-936-07-nok-span {
        color: var(--text-and-icons-blue-500, #00aeef);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 20px;
        font-weight: 400;
    }
    ._13-936-07-nok-span2 {
        color: var(--text-and-icons-blue-500, #00aeef);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 16px;
        font-weight: 400;
    }
`;

const StyledTopProductsItem = styled.div`
    &.frame-313309,
    &.frame-313309 * {
        box-sizing: border-box;
    }
    &.frame-313309 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313304 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313299 {
        border-radius: 4px;
        padding: 4px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313296 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313294 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313298 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .luan-j-shirt-cosmic-4-3 {
        border-radius: 4px;
        flex-shrink: 0;
        width: 30px;
        height: 41px;
        position: relative;
        object-fit: cover;
    }
    .frame-313305 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .luan-j-shirt-14826 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._1-500-kr {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-3133052 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-end;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    ._78 {
        color: var(--text-and-icons-blue-500, #00aeef);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .sold {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
`;
/* #endregion */

export default function BOImagesDetailsPage() {
    document.title = `${APP_TITLE} - BOImages`;

    /* #region VARS */
    const { BOimageID } = useParams();
    const { MainLayoutProps, layoutType } = useOutletContext();
    //const [viewLayoutStyle, setViewLayoutStyle] = useState("Grid");
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [BOImageData, setBOImageData] = useState(undefined);

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    //const {} = React.useContext(AppContext);

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(props, BOImageData);
    }, [props?.layoutType, BOImageData]);

    useEffect(() => {
        if (!BOimageID || BOimageID === "empty") return;
        getBOImageDetails(BOimageID);
    }, [BOimageID]);
    /* #endregion */

    /* #region METHODS */
    async function getBOImageDetails(BOimageID) {
        Library.makeCommonPostRequest("BOImages", "getBOimageDetails", { BOimageID }, false, setIsLoadingData).then(
            res => {
                if (res.data.status === 200) {
                    const data = res.data.data;

                    setBOImageData(data);
                }
            }
        );
    }
    /* #endregion */

    return (
        <FullScreenLoader isLoading={isLoadingData}>
            <StyledPage>
                <div>
                    {/* <BOImageDetailsHeader BOImageData={BOImageData} /> */}

                    <CardComponentContainer title="BOImage">
                        <BOImageDetails
                            BOImageData={BOImageData}
                            onRefreshAction={() => getBOImageDetails(BOimageID)}
                        />
                    </CardComponentContainer>

                    <CardComponentContainer title="STATS">
                        <BOImagesStats BOImageData={BOImageData} />
                    </CardComponentContainer>
                </div>
            </StyledPage>
        </FullScreenLoader>
    );
}

function setupHeaders(props, BOImageData) {
    props.setControlBarLeftBtns([
        { to: "#", disabled: false, title: "BOImages", icon: "fa-magnifying-glass", isSearchToogle: true }
    ]);
    props.setControlBarCenterBtns([{ to: "/boimages", active: true, disabled: false, title: "BOIMAGES" }]);
    props.setControlBarRightBtns([
        { to: "/boimages", active: false, disabled: false, title: "View", icon: "eye" },
        { to: "/boimages/create", active: false, disabled: false, title: "Add", icon: "plus" },
        { to: "#", active: true, disabled: false, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: <BOImageDetailsHeader BOImageData={BOImageData} />
    });
}

/* #region SMALL COMPONENTS */
function BOImageDetailsHeader({ BOImageData }) {
    const urlPrefix = process.env.REACT_APP_BOTHEBOT_URL;
    const BOImageID = BOImageData?.BOimageData?.ourID ?? "";

    const BOImageURL = !BOImageID ? "--" : `${urlPrefix}${BOImageID}`;
    const Status = BOImageData?.BOimageData?.statusEnum || "--";

    return (
        <StyledBOImagesDetailsHeader className="main-window-header-detail">
            <div className="frame-34">
                <div className="frame-52">
                    <div className="frame-313357">
                        <div className="input-link-disabled">
                            <div className="inverted-field overflow-hidden">
                                <div className="input-selector-inverted">
                                    <div className="search w-100">
                                        <div className="frame-48">
                                            <div className="order-g-00019134-xlsx text-one-line overflow-hidden">
                                                <span className="order-g-00019134-xlsx-span">Link: </span>
                                                <span className="order-g-00019134-xlsx-span2">{BOImageURL}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-search hover-not-allowed">
                                <div className="icon-edit-disabled">
                                    <Edit020 className="edit-02" src="edit-020.svg" />
                                </div>
                            </div>
                        </div>
                        <div className="btn-large-solid disabled">
                            <div className="button">PUBLISH</div>
                        </div>
                    </div>
                    <div className="frame-261">
                        <div className="frame-51">
                            <div className="status-block-ready">
                                <div className="frame-313278">
                                    <div className="frame-160">
                                        <span className="status-title">Status: </span>
                                        <span
                                            className="status-ready"
                                            style={{ color: Status === "Active" ? "#00BB00" : "#F90" }}>
                                            {Status}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="refresh-icon hover-not-allowed" style={{ color: "#4F5B6D" }}>
                                <RefreshCcw01 className="refresh-ccw-01" src="refresh-ccw-010.svg" />
                            </div>
                            <div className="back-icon hover-not-allowed">
                                <div className="frame-29" style={{ color: "#4F5B6D" }}>
                                    <FlipBackward className="flip-backward" src="flip-backward0.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledBOImagesDetailsHeader>
    );
}

function BOImageDetails({ BOImageData, onRefreshAction }) {
    return (
        <div>
            <div className="frame-191">
                <BOImageLeftSection BOImageData={BOImageData} onRefreshAction={onRefreshAction} />

                <BOImageMiddleSection BOImageData={BOImageData} />

                <BOImageRightSection BOImageData={BOImageData} />
            </div>
        </div>
    );
}

function BOImageLeftSection({ BOImageData, onRefreshAction }) {
    /* #region VARS */
    const navigate = useNavigate();
    const isActive = !!BOImageData;
    const data = BOImageData?.BOimageData;
    const imgURL = data?.imageURL || "";
    const name = data?.name || "--";
    const ourID = data?.ourID || "--";
    const id = data?._id || "--";
    const activePeriodStart = formatDefaultDate(data?.activePeriodStartISO) || "--";
    const activePeriodEnd = formatDefaultDate(data?.activePeriodEndISO) || "--";
    let activePeriod =
        activePeriodStart === "--" && activePeriodEnd === "--" ? "--" : `${activePeriodStart} to ${activePeriodEnd}`;
    const revenue = BOImageData?.totalSalesAmount || "--";
    const items = BOImageData?.totalItemsSold || "--";
    const currentStatus = data?.statusEnum || "--";
    const toBrand = data?.labelBrandName || "--";
    const toCollection = data?.collectionName || "--";
    const genderTarget = !data?.targets?.genders?.length ? "--" : data?.targets?.genders?.join(", ");
    const ageGroups = !data?.targets?.ageGroups?.length ? "--" : data?.targets?.ageGroups?.join(", ");
    const isSuspended = data?.statusEnum === "Suspended";

    const valueStyle = { color: isActive ? "#00AEEF" : "#4F5B6D" };
    const titleStyle = { color: isActive ? "#D1D1D1" : "#4F5B6D" };

    const [isLoading, setIsLoading] = useState(false);
    const [removeID, setRemoveID] = useState(null);
    const [toogleSuspendData, setToogleSuspendData] = useState(null);

    if (activePeriod === "--") activePeriod = "Always Active";
    /* #endregion */

    /* #region METHODS */
    function onEditClick() {
        if (!isActive) return;
        navigate(`/boimages/edit/${id}`);
    }

    const toogleSuspend = itemData => {
        setIsLoading(true);

        const itemID = id;
        const status = !isSuspended ? "Suspended" : "Active";

        Library.makePostRequest(
            "updateBOimageStatus",
            {
                //groupCompanyID,
                BOimageID: itemID,
                status
            },
            false,
            setIsLoading
        )
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setToogleSuspendData(null);
                Library.showSuccessMessage(
                    status === "Active" ? "BOImage activated successfully!" : "BOImage suspended successfully!"
                );

                if (onRefreshAction) onRefreshAction();
            })
            .catch(err => {
                setIsLoading(false);
                setToogleSuspendData(null);
            });
    };

    const makeDeleteRequest = itemID => {
        setIsLoading(true);

        Library.makePostRequest("deleteBOimage", { BOimageID: itemID }, false, setIsLoading)
            .then(res => {
                if (res.data.status !== 200) return;
                setIsLoading(false);
                setRemoveID(null);
                Library.showSuccessMessage("BOImage deleted successfully!");
                navigate("/boimages");
            })
            .catch(err => {
                setIsLoading(false);
                setRemoveID(null);
            });
    };
    /* #endregion */

    return (
        <StyledBOImageLeftSection className={`id-info-card left-section ${isActive ? "active" : ""}`}>
            <div style={{ position: "absolute" }}>
                <FullScreenOverlay show={toogleSuspendData !== null}>
                    <DialogBoxComponent
                        title={toogleSuspendData?.status === "Active" ? "Suspend BOImage" : "Activate BOImage"}
                        message={
                            toogleSuspendData?.status === "Active"
                                ? `Are you sure you want to suspend ${toogleSuspendData?.name} BOImage?`
                                : `Are you sure you want to activate ${toogleSuspendData?.name} BOImage?`
                        }
                        onClickYes={() => {
                            toogleSuspend(toogleSuspendData);
                        }}
                        loading={isLoading}
                        loadingHeading={
                            toogleSuspendData?.status === "Active" ? "Suspending BOImage..." : "Activating BOImage..."
                        }
                        onClickNo={() => {
                            setToogleSuspendData(null);
                        }}
                    />
                </FullScreenOverlay>

                <FullScreenOverlay show={removeID !== null}>
                    <DialogBoxComponent
                        headingColor="var(--text-icons-red-500)"
                        title="Removing BOImage"
                        message="Are you sure you want to remove this BOImage?"
                        onClickYes={() => {
                            makeDeleteRequest(removeID);
                        }}
                        loading={isLoading}
                        loadingHeading="Removing BOImage..."
                        onClickNo={() => {
                            setRemoveID(null);
                        }}
                    />
                </FullScreenOverlay>
            </div>

            <div className="frame-197">
                {isActive && imgURL ? (
                    <div className="BoimageCanvas">
                        <ImageBeacons BOTagsList={data?.BOtags} src={imgURL} useStyleV2={true} />
                    </div>
                ) : (
                    <div className="frame-198" />
                )}
            </div>
            <div className="frame-172">
                <div className="frame-161">
                    <div className="boimage2 text-one-line" style={titleStyle}>
                        BOIMAGE: <span style={valueStyle}>{name}</span>
                    </div>
                    <div className="frame-162">
                        <Pencil
                            className={`pencil-01 ${isActive ? "cursor-pointer" : "cursor-not-allowed"}`}
                            alt="edit"
                            style={{ color: isActive ? "#00BB00" : "#4F5B6D" }}
                            onClick={onEditClick}
                        />
                        {!isSuspended ? (
                            <Hand
                                className={`hand ${isActive ? "cursor-pointer" : "cursor-not-allowed"}`}
                                alt="suspend"
                                style={{ color: isActive ? "#FF9900" : "#4F5B6D" }}
                                onClick={() => setToogleSuspendData(id)}
                            />
                        ) : (
                            <RefreshCcw01
                                className={`hand text-blue-500 ${isActive ? "cursor-pointer" : "cursor-not-allowed"}`}
                                onClick={() => setToogleSuspendData(id)}
                            />
                        )}
                        <Trash
                            className={`trash-01 ${isActive ? "cursor-pointer" : "cursor-not-allowed"}`}
                            alt="delete"
                            style={{ color: isActive ? "#FF3636" : "#4F5B6D" }}
                            onClick={() => setRemoveID(id)}
                        />
                    </div>
                </div>
                <div className="botag-id" style={titleStyle}>
                    BOTAG ID: <span style={valueStyle}>{ourID}</span>
                </div>
            </div>
            <div className="frame-171">
                <div className="frame-144">
                    <div className="details" style={titleStyle}>
                        DETAILS
                    </div>
                    <div className="line-1"></div>
                </div>
                <div className="frame-204">
                    <div className="frame-169">
                        <div className="card-details-item">
                            <div className="active-period" style={titleStyle}>
                                Active Period:
                            </div>
                            <div className="div text-one-line" style={valueStyle}>
                                {activePeriod}
                            </div>
                        </div>
                        <div className="card-details-item">
                            <div className="revenue" style={titleStyle}>
                                Revenue:
                            </div>
                            <div className="div text-one-line" style={valueStyle}>
                                {Formatter.formatPrice(revenue, "NOK")}
                            </div>
                        </div>
                        <div className="card-details-item">
                            <div className="items" style={titleStyle}>
                                Items:
                            </div>
                            <div className="div text-one-line" style={valueStyle}>
                                {Formatter.formatInteger(items)}
                            </div>
                        </div>
                        <div className="card-details-item">
                            <div className="current-status" style={titleStyle}>
                                Current status:
                            </div>
                            <div className="statuses">
                                <div className="alert-text" style={valueStyle}>
                                    {getSpanWithColor(currentStatus)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="line-7"></div>
                    <div className="frame-170">
                        <div className="card-details-item">
                            <div className="to-brand" style={titleStyle}>
                                To brand:
                            </div>
                            <div className="div text-one-line" style={valueStyle}>
                                {toBrand}
                            </div>
                        </div>
                        <div className="card-details-item">
                            <div className="to-collection" style={titleStyle}>
                                To collection:
                            </div>
                            <div className="statuses">
                                <div className="alert-text" style={valueStyle}>
                                    {toCollection}
                                </div>
                            </div>
                        </div>
                        <div className="card-details-item">
                            <div className="gender-target" style={titleStyle}>
                                Gender target:
                            </div>
                            <div className="div text-one-line" style={valueStyle}>
                                {genderTarget}
                            </div>
                        </div>
                        <div className="card-details-item">
                            <div className="age-groups" style={titleStyle}>
                                Age groups:
                            </div>
                            <div className="div text-one-line" style={valueStyle}>
                                {ageGroups}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledBOImageLeftSection>
    );
}

function BOImageMiddleSection({ BOImageData }) {
    const isActive = !!BOImageData;
    const valueStyle = { color: isActive ? "#00AEEF" : "#4F5B6D" };
    const titleStyle = { color: isActive ? "#D1D1D1" : "#4F5B6D" };

    const totalItemsSold = BOImageData?.totalItemsSold || "0";
    const amountComparison = BOImageData?.amountComparison || "--%";
    const totalSalesAmount = BOImageData?.totalSalesAmount || "0";
    const salesComparison = BOImageData?.salesComparison || "--%";
    const totalOrders = BOImageData?.totalOrders || "0";
    const avgOrders = BOImageData?.totalSalesAvgOrders || "0";

    return (
        <StyledBOImageMiddleSection className={`frame-313355 middle-section ${isActive ? "active" : ""}`}>
            <div className="frame-313309">
                <div className="frame-313284">
                    <div className="frame-313288">
                        <div className="frame-313293">
                            <div className="frame-313291">
                                <div className="frame-313286">
                                    <div className="heading-4-total-visitors text-one-line" style={titleStyle}>
                                        TOTAL ITEMS SOLD
                                    </div>
                                    <InfoCircle className="info-circle" style={titleStyle} />
                                </div>
                                <div className="frame-313290">
                                    <div className="frame-313289">
                                        <div className="_0-items text-one-line">
                                            <span>
                                                <span className="_0-items-span" style={valueStyle}>
                                                    {Formatter.formatInteger(totalItemsSold, "0")}{" "}
                                                </span>
                                                <span className="_0-items-span2" style={valueStyle}>
                                                    ITEMS
                                                </span>
                                            </span>
                                        </div>
                                        <div className="frame-313287">
                                            <div className="compared-to-last-30-days text-one-line">
                                                <span style={valueStyle}>{amountComparison}</span>&nbsp;
                                                <span style={titleStyle}>compared to last 7 days</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator-class" />
                            {!isActive ? (
                                <div className="no-data-to-display-chart">No data to display chart</div>
                            ) : (
                                <img src={SvgjsRect1010} alt="chart" className="chartCustom" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-313311">
                <div className="frame-313284">
                    <div className="frame-313288">
                        <div className="frame-313293">
                            <div className="frame-313291">
                                <div className="frame-313286">
                                    <div className="heading-4-total-visitors text-one-line" style={titleStyle}>
                                        TOTAL SALES
                                    </div>
                                    <InfoCircle className="info-circle2" style={titleStyle} />
                                </div>
                                <div className="frame-313290">
                                    <div className="frame-313289">
                                        <div className="_0-nok">
                                            <span>
                                                <span className="_0-nok-span text-one-line" style={valueStyle}>
                                                    {Formatter.formatInteger(totalSalesAmount)}{" "}
                                                </span>
                                                <span className="_0-nok-span2" style={valueStyle}>
                                                    NOK
                                                </span>
                                            </span>
                                        </div>
                                        <div className="frame-313287">
                                            <div className="compared-to-last-30-days text-one-line">
                                                <span style={valueStyle}>{salesComparison}</span>&nbsp;
                                                <span style={titleStyle}>compared to last 7 days</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator-class" />
                            <div className="frame-313296">
                                <div className="frame-313294 text-one-line">
                                    <div className="total-orders" style={titleStyle}>
                                        Total orders
                                    </div>
                                    <div className="_0" style={valueStyle}>
                                        {Formatter.formatInteger(totalOrders)}
                                    </div>
                                </div>
                                <div className="frame-313294 text-one-line">
                                    <div className="avg-orders" style={titleStyle}>
                                        Avg. orders
                                    </div>
                                    <div className="_0-kr" style={valueStyle}>
                                        {Formatter.formatPrice(avgOrders, "kr", "0 kr")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledBOImageMiddleSection>
    );
}

function BOImageRightSection({ BOImageData }) {
    const isActive = !!BOImageData;

    return (
        <StyledBOImageRightSection className={`group-5 right-section ${isActive ? "active" : ""}`}>
            {!isActive ? <BOImageMobileEmpty /> : <BOImageMobile BOImageData={BOImageData} />}
        </StyledBOImageRightSection>
    );
}

function BOImagesStats({ BOImageData }) {
    const isActive = !!BOImageData;
    const valueStyle = { color: isActive ? "#00AEEF" : "#4F5B6D" };
    const titleStyle = { color: isActive ? "#D1D1D1" : "#4F5B6D" };

    const salesByBrandData = BOImageData?.salesByBrand;
    const topProductsData = BOImageData?.topProductsByUnitsSold;
    const salesByStoreData = BOImageData?.salesByStore;

    const totalSalesByBrand = Formatter.formatPrice(BOImageData?.totalSalesAmount, "", "0");
    const totalSalesTopProducts = Formatter.formatPrice(BOImageData?.totalSalesAmount, "", "0");
    const totalSalesByStore = Formatter.formatPrice(BOImageData?.totalSalesAmount, "", "0");
    const totalSalesByBrandComparison = BOImageData?.salesComparison || "--%";
    const totalSalesTopProductsComparison = BOImageData?.salesComparison || "--%";
    const totalSalesByStoreComparison = BOImageData?.salesComparison || "--%";

    return (
        <StyledBOImagesStats>
            <div className="frame-191">
                <div className="frame-313319">
                    <div className="frame-313287" style={{ alignSelf: "stretch" }}>
                        <div className="frame-313297">
                            <div className="frame-313284">
                                <div className="frame-313288">
                                    <div className="frame-313293">
                                        <div className="frame-313292">
                                            <div className="frame-313291">
                                                <div className="frame-313286">
                                                    <div className="frame-313326 overflow-hidden">
                                                        <div
                                                            className="heading-4-total-visitors text-one-line"
                                                            style={titleStyle}>
                                                            SALES BY BRAND
                                                        </div>
                                                        <InfoCircle className="info-circle" style={titleStyle} />
                                                    </div>
                                                    <div className="frame-188">
                                                        <div className="frame-186 d-none">
                                                            <div className="see-more">SEE MORE...</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="frame-313290">
                                                    <div className="frame-313289">
                                                        <div className="_0-nok text-one-line">
                                                            <span>
                                                                <span className="_0-nok-span" style={valueStyle}>
                                                                    {totalSalesByBrand}{" "}
                                                                </span>
                                                                <span className="_0-nok-span2" style={valueStyle}>
                                                                    NOK
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="frame-3132872">
                                                            {/* <ChevronUp className="chevron-up" style={titleStyle} /> */}
                                                            <div className="compared-to-last-30-days text-one-line">
                                                                <span style={valueStyle}>
                                                                    {totalSalesByBrandComparison}
                                                                </span>
                                                                &nbsp;
                                                                <span style={titleStyle}>compared to last 7 days</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="separator-class" />
                                        </div>
                                        {!isActive || !salesByBrandData?.length ? (
                                            <div className="nothing-to-show-here">Nothing to show here...</div>
                                        ) : (
                                            <BrandsSalesItems data={salesByBrandData} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-3132882" style={{ alignSelf: "stretch" }}>
                        <div className="frame-3132972">
                            <div className="frame-3132842">
                                <div className="frame-3132883">
                                    <div className="frame-3132932">
                                        <div className="frame-313310">
                                            <div className="frame-313291">
                                                <div className="frame-313286">
                                                    <div className="frame-313327 overflow-hidden">
                                                        <div
                                                            className="heading-4-total-visitors text-one-line"
                                                            style={titleStyle}>
                                                            TOP PRODUCTS BY UNITS SOLD
                                                        </div>
                                                        <InfoCircle className="info-circle2" style={titleStyle} />
                                                    </div>
                                                    <div className="frame-188">
                                                        <div className="frame-186 d-none">
                                                            <div className="see-more">SEE MORE...</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="frame-313290">
                                                <div className="frame-313289">
                                                    <div className="_0-nok text-one-line">
                                                        <span>
                                                            <span className="_0-nok-span" style={valueStyle}>
                                                                {totalSalesTopProducts}{" "}
                                                            </span>
                                                            <span className="_0-nok-span2" style={valueStyle}>
                                                                NOK
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="frame-3132872">
                                                        {/* <ChevronUp className="chevron-up" style={titleStyle} /> */}
                                                        <div className="compared-to-last-30-days text-one-line">
                                                            <span style={valueStyle}>
                                                                {totalSalesTopProductsComparison}
                                                            </span>
                                                            &nbsp;
                                                            <span style={titleStyle}>compared to last 7 days</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="separator-class" />

                                            {!isActive || !topProductsData?.length ? (
                                                <div className="nothing-to-show-here2 text-one-line">
                                                    Nothing to show here...
                                                </div>
                                            ) : (
                                                <TopProductsItems data={topProductsData} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-3132902" style={{ alignSelf: "stretch" }}>
                        <div className="frame-313297">
                            <div className="frame-313284">
                                <div className="frame-313288">
                                    <div className="frame-313293">
                                        <div className="frame-313292">
                                            <div className="frame-313291">
                                                <div className="frame-313286">
                                                    <div className="frame-313326 overflow-hidden">
                                                        <div
                                                            className="heading-4-total-visitors text-one-line"
                                                            style={titleStyle}>
                                                            SALES BY STORE
                                                        </div>
                                                        <InfoCircle className="info-circle3" style={titleStyle} />
                                                    </div>
                                                    <div className="frame-188">
                                                        <div className="frame-186 d-none">
                                                            <div className="see-more2">SEE MORE...</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="frame-313290">
                                                    <div className="frame-313289">
                                                        <div className="_0-nok2">
                                                            <span>
                                                                <span className="_0-nok-2-span" style={valueStyle}>
                                                                    {totalSalesByStore}{" "}
                                                                </span>
                                                                <span className="_0-nok-2-span2" style={valueStyle}>
                                                                    NOK
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="frame-3132872">
                                                            {/* <ChevronUp className="chevron-up2" style={titleStyle} /> */}
                                                            <div className="compared-to-last-30-days text-one-line">
                                                                <span style={valueStyle}>
                                                                    {totalSalesByStoreComparison}
                                                                </span>
                                                                &nbsp;
                                                                <span style={titleStyle}>compared to last 7 days</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="separator-class" />
                                        </div>
                                        {/* <div className="nothing-to-show-here">Nothing to show here...</div> */}
                                        {!isActive || !salesByStoreData?.length ? (
                                            <div className="nothing-to-show-here">Nothing to show here...</div>
                                        ) : (
                                            <BrandsSalesItems data={salesByStoreData} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledBOImagesStats>
    );
}

function BrandsSalesItems({ data }) {
    const array = data?.slice(0, 5) || [];

    return (
        <StyledBrandsSalesItem className="frame-313304">
            {array.map((item, index) => (
                <div className="frame-313299">
                    <div className="frame-313296">
                        <div className="frame-313294">
                            <div className="frame-313325">
                                <img className="replay-logo-1" src={item?.logo} alt="Brand logo" />
                            </div>
                            <div className="frame-313298">
                                <div className="follestad-hamar">{item?.name}</div>
                            </div>
                        </div>
                        <div className="frame-313295">
                            <div className="_13-936-07-nok">
                                <span>
                                    <span className="_13-936-07-nok-span">
                                        {Formatter.formatPrice(item?.totalSales, "")}
                                    </span>
                                    <span className="_13-936-07-nok-span2"> NOK</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </StyledBrandsSalesItem>
    );
}

function TopProductsItems({ data }) {
    const array = data?.slice(0, 5) || [];

    return (
        <StyledTopProductsItem className="frame-313309 w-100">
            {array.map((item, index) => (
                <div className="frame-313304">
                    <div className="frame-313299">
                        <div className="frame-313296">
                            <div className="frame-313294">
                                <div className="frame-313298">
                                    <img className="luan-j-shirt-cosmic-4-3" src={item?.imageURL} alt="Product" />
                                    <div className="frame-313305">
                                        <div className="luan-j-shirt-14826">{item?.name}</div>
                                        <div className="_1-500-kr">{Formatter.formatPrice(item?.totalSales, "kr")}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-3133052">
                                <div className="_78">{Formatter.formatInteger(item?.totalUnitsSold)}</div>
                                <div className="sold">sold</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </StyledTopProductsItem>
    );
}

function BOImageMobileEmpty() {
    return (
        <div>
            <Rectangle10 className="rectangle-1" />
            <Rectangle20 className="rectangle-2" />
            <Image030 className="image-03" />
        </div>
    );
}

function BOImageMobile({ BOImageData }) {
    const isActive = !!BOImageData;
    const data = BOImageData?.BOimageData;

    const imgURL = data?.imageURL ?? "";

    return (
        <StyledBOImageMobile>
            <div className="b-oimage-emulator">
                <Rectangle10 className="rectangle-1" />
                <div className="frame-295922362">
                    <Rectangle20 className="rectangle-2" />
                    {isActive && imgURL ? (
                        <div className="BoimageCanvas" style={{ position: "relative", top: "114px" }}>
                            <ImageBeacons BOTagsList={data?.BOtags} src={imgURL} maxHeight="400px" />
                        </div>
                    ) : (
                        <div className="sams-e-b-oimage-3-1" />
                    )}
                    <div className="frame-313867 hidden">
                        <div className="skjorter">Skjorter</div>
                    </div>
                    <div className="beacon-new hidden">
                        <div className="ellipse-103"></div>
                        <div className="ellipse-102"></div>
                    </div>
                    <div className="header">
                        <div className="frame-8">
                            <BOIkon1 className="bo-ikon-1" />
                        </div>
                        <div className="frame-29219">
                            <div className="frame-29517">
                                <div className="shopping-bag-03">
                                    <Icon0 className="icon" src="icon0.svg" />
                                    <Check0 className="check" src="check0.svg" />
                                </div>
                            </div>
                            <div className="frame-29159" src="frame-291590.svg">
                                <Frame29159 />
                            </div>
                        </div>
                    </div>
                    <div className="status-bar">
                        <Time0 className="time" src="time0.svg" />
                        <div className="stack">
                            <Cellular0 className="cellular" src="cellular0.svg" />
                            <Wifi0 className="wifi" src="wifi0.svg" />
                            <Battery0 className="battery" src="battery0.svg" />
                        </div>
                    </div>
                    <div className="footer">
                        <div className="frame-12">
                            <div className="frame-14">
                                <img
                                    className="mobile-footer-brand"
                                    src={localStorage.getItem("currentOrganizationLogo")}
                                    alt="Brand logo"
                                />
                            </div>
                            <div className="frame-15">
                                <div className="frame-2">
                                    <div className="frame-29153">
                                        <EyeOff0 className="eye-off" src="eye-off0.svg" />
                                    </div>
                                    <div className="frame-29155">
                                        <Share070 className="share-07" src="share-070.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image-03"></div>
            </div>
        </StyledBOImageMobile>
    );
}

/* #endregion */
