import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BOImageCardGridSkeleton from "../gridCards/animations/BOImageCardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import * as Library from "../../../utils/Library";
import AppContext from "../../../AppContext";
import ProductCardLinkingSidePanel from "../mainApp/ProductCardLinkingSidePanel";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        //height: 100%;
        height: calc(100vh - 140px);
        padding-right: 16px;
    }
`;
/* #endregion */

/**
 * Renders an infinite grid of cards for the BO images.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className] - The additional CSS class name for the component.
 * @param {number} [props.limit=10] - The maximum number of cards to load per page.
 * @param {string} [props.queryKey] - The query key for fetching data.
 * @param {number} [props.minimumCardsFill] - The minimum number of cards to fill the grid.
 * @param {number} [props.minColumnWidth] - The minimum width of each grid column.
 * @param {Function} [props.onEditAction] - The callback function for the edit action.
 * @param {Function} [props.onDeleteAction] - The callback function for the delete action.
 * @param {Function} [props.onSuspendAction] - The callback function for the suspend action.
 * * @param {string} [props.statusFromPath] - The status from the path.
 * @returns {JSX.Element} The rendered component.
 */

export default function InfinityGridSidePanelBOImages(props) {
    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${"100%"}, 1fr))`,
        gap: "8px"
    };

    /* #region STATES */
    const { sidePanelLabelBrandID, sidePanelProducts, setSidePanelProducts, sidePanelProductSearchName } =
        React.useContext(AppContext);

    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    //const [queryKey, setQueryKey] = useState(props?.queryKey ?? uuidv4());
    //const [minimumCardsFill, setminimumCardsFill] = useState(props?.minimumCardsFill || pageLimit);

    const pageLimit = 10;
    const queryKey = ["getProductsForBOimages", sidePanelLabelBrandID, sidePanelProductSearchName];

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getProductsForBOimages",
        {
            labelBrandID: sidePanelLabelBrandID,
            searchName: sidePanelProductSearchName,
            limit: pageLimit
        },
        () => {
            setScroll(true);
        }
    );

    const pageList = data?.pages;
    const nextDataCount = data?.pages[data?.pages.length - 1].data?.nextDataCount ?? pageLimit;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();

        if (!data?.pages) {
            setSidePanelProducts([]);
        }

        const newProducts = data?.pages?.reduce((acc, it) => {
            return [...acc, ...it?.data?.result];
        }, []);

        setSidePanelProducts(newProducts);
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            fetchNextPage();
        }
    };

    return (
        <StyledPage className={`${props?.className || ""} w-100`} ref={scrollContainerRef}>
            <div className="mb-16px w-100" style={gridLayoutStyle}>
                {sidePanelProducts?.map(item => (
                    <div key={item.productID} className="ListItem2">
                        <ProductCardLinkingSidePanel productID={item.productID} />
                    </div>
                ))}
                {sidePanelProducts?.length === 0 && !isLoading && !isFetchingNextPage && (
                    <div className="ListItem2">
                        <label className="w-100">No products found!</label>
                    </div>
                )}

                {(isLoading || isFetchingNextPage) &&
                    Array.from({ length: nextDataCount }, () => <BOImageCardGridSkeleton key={uuidv4()} />)}
            </div>
        </StyledPage>
    );
}
