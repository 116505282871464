import React, { useRef } from "react";
import styled from "styled-components";
import InfoCircle from "../../../../assets/icons/info-circle-white.svg";
const CompanyIDCardDiv = styled.div`
    &.main-company-card-container {
        padding: 16px 0px 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .company-card-column {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
    }
    .box {
        width: 25%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
    }
    .box-label {
        display: flex;
        flex-direction: row;
        gap: 4px;
        font-size: 10px;
        color: #7c868b;
    }
    .input-container {
        background: #10141b;
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex: 1 1 0%;
        height: 38px;
        position: relative;
    }
    input.input-box {
        border: none;
        outline: none;
        background: none;
        color: #00bb00;
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1 1 0%;
    }
    .group-input {
        display: flex;
        flex-direction: row;
    }
    .filled {
        min-height: 38px;
        width: 5px;
        background: #141922;
        border-radius: 4px 0px 0px 4px;
    }
    .mondatory {
        min-height: 38px;
        width: 5px;
        background: #ff9900;
        border-radius: 4px 0px 0px 4px;
    }
    .tags-input-tag {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0px 8px;
        border-radius: 0px 4px 4px 0px;
        background: #10141b;
        align-items: center;
        width: 100%;
        input {
            flex: 1;
            border: none;
            font-size: 14px;
            &:focus {
                outline: transparent;
            }
        }
    }
    .tag-ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0px 0 0 0;
        list-style-type: none;
        gap: 5px;
    }
    li.tag {
        padding: 4px;
        border-radius: 4px;
        display: flex;
        gap: 4px;
        color: #d1d1d1;
        background: #0155b3;
        height: 22px;
        align-items: center;
        margin-right: 5px;
    }
    .tag-close-icon {
        cursor: pointer;
    }
`;
export default function HashtagAndTarget({
    genderTags,
    setGenderTags,
    ageGroupTags,
    setAgeGroupTags,
    lifestyleTags,
    setLifestyleTags,
    majorMarkets,
    setmajorMarkets,
    hashtags,
    setHashtags
}) {
    const addGennderTags = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            setGenderTags([...genderTags, event.target.value]);
            event.target.value = "";
        }
    };
    const removeGenderTags = indexToRemove => {
        setGenderTags([...genderTags.filter((_, index) => index !== indexToRemove)]);
    };
    const addAgeGroupTags = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            setAgeGroupTags([...ageGroupTags, event.target.value]);
            event.target.value = "";
        }
    };
    const removeAgeGroupTags = indexToRemove => {
        setAgeGroupTags([...ageGroupTags.filter((_, index) => index !== indexToRemove)]);
    };
    const addTitleTags = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            setLifestyleTags([...lifestyleTags, event.target.value]);
            event.target.value = "";
        }
    };
    const removeTitleTags = indexToRemove => {
        setLifestyleTags([...lifestyleTags.filter((_, index) => index !== indexToRemove)]);
    };
    const addTitleTagsTwo = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            setmajorMarkets([...majorMarkets, event.target.value]);
            event.target.value = "";
        }
    };
    const removeTitleTagsTwo = indexToRemove => {
        setmajorMarkets([...majorMarkets.filter((_, index) => index !== indexToRemove)]);
    };
    return (
        <CompanyIDCardDiv className="main-company-card-container">
            <div className="company-card-row">
                <div className="company-card-column">
                    <div className="box" style={{ width: "50%" }}>
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Genders</div>
                        </div>
                        <div className="group-input">
                            <div className={genderTags.length ? "filled" : "mondatory"}></div>
                            <div className="tags-input-tag">
                                <ul className="tag-ul">
                                    {genderTags.map((tag, index) => (
                                        <li key={index} className="tag">
                                            <span className="tag-title">{tag}</span>
                                            <span className="tag-close-icon" onClick={() => removeGenderTags(index)}>
                                                x
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                <input
                                    type="text"
                                    className="input-box"
                                    onKeyUp={event => addGennderTags(event)}
                                    placeholder="Press enter to add tags"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box" style={{ width: "50%" }}>
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Age groups</div>
                        </div>
                        <div className="group-input">
                            <div className={ageGroupTags.length ? "filled" : "mondatory"}></div>
                            <div className="tags-input-tag">
                                <ul className="tag-ul">
                                    {ageGroupTags.map((tag, index) => (
                                        <li key={index} className="tag">
                                            <span className="tag-title">{tag}</span>
                                            <span className="tag-close-icon" onClick={() => removeAgeGroupTags(index)}>
                                                x
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                <input
                                    type="text"
                                    className="input-box"
                                    onKeyUp={event => addAgeGroupTags(event)}
                                    placeholder="Press enter to add tags"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-card-row">
                <div className="company-card-column">
                    <div className="box" style={{ width: "50%" }}>
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Lifestyle</div>
                        </div>
                        <div className="group-input">
                            <div className={lifestyleTags.length ? "filled" : "mondatory"}></div>
                            <div className="tags-input-tag">
                                <ul className="tag-ul">
                                    {lifestyleTags.map((tag, index) => (
                                        <li key={index} className="tag">
                                            <span className="tag-title">{tag}</span>
                                            <span className="tag-close-icon" onClick={() => removeTitleTags(index)}>
                                                x
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                <input
                                    type="text"
                                    className="input-box"
                                    onKeyUp={event => addTitleTags(event)}
                                    placeholder="Press enter to add tags"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box" style={{ width: "50%" }}>
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Major Market</div>
                        </div>
                        <div className="group-input">
                            <div className={majorMarkets.length ? "filled" : "mondatory"}></div>
                            <div className="tags-input-tag">
                                <ul className="tag-ul">
                                    {majorMarkets.map((tag, index) => (
                                        <li key={index} className="tag">
                                            <span className="tag-title">{tag}</span>
                                            <span className="tag-close-icon" onClick={() => removeTitleTagsTwo(index)}>
                                                x
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                <input
                                    type="text"
                                    className="input-box"
                                    onKeyUp={event => addTitleTagsTwo(event)}
                                    placeholder="Press enter to add tags"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-card-row">
                <div className="company-card-column">
                    <div className="box" style={{ width: "100%" }}>
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Hashtags</div>
                        </div>
                        <div className="group-input">
                            <div className={hashtags ? "filled" : "mondatory"}></div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input-box"
                                    value={hashtags}
                                    onChange={e => setHashtags(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CompanyIDCardDiv>
    );
}
