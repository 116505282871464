import { createSlice } from "@reduxjs/toolkit";

export const navigationAccessSlice = createSlice({
    name: "navigationAccess",
    initialState: {
        navigationAccesses: null
    },
    reducers: {
        setNavigations: (state, action) => {
            state.navigationAccesses = action.payload.navigationAccesses;
        }
    }
});

export const { setNavigations } = navigationAccessSlice.actions;

export default navigationAccessSlice.reducer;
