import React from "react";

/**
 * Renders a dialog box component.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.headingColor - The color of the heading.
 * @param {string} props.heading - The heading text.
 * @param {string} props.title - The title text.
 * @param {string} props.message - The message text.
 * @param {boolean} props.useAlternativeLayout - Indicates whether to use an alternative layout.
 * @param {function} props.onClickYes - The function to be called when the "YES" button is clicked.
 * @param {function} props.onClickNo - The function to be called when the "NO" button is clicked.
 * @param {string} props.loading - Indicates whether the component is in a loading state.
 * @param {string} props.loadingHeading - The heading text to be displayed when the component is in a loading state.
 * @param {string} props.cancelText - The text to be displayed on the "NO" button.
 * @param {string} props.confirmText - The text to be displayed on the "YES" button.
 * @param {boolean} props.showCancel - Indicates whether the "NO" button should be displayed.
 * @param {boolean} props.showConfirm - Indicates whether the "YES" button should be displayed.
 * @param {object} props.useImageHeader - The image to be used as the header.
 * @param {string} props.useImageHeader.src - The image source.
 * @param {string} props.useImageHeader.width - The image width
 * @returns {JSX.Element} The rendered dialog box component.
 */
const DialogBoxComponent = ({
    headingColor = "var(--text-icons-blue-500)",
    heading = "JUST CHECKING...",
    headingFontSize,
    title,
    message,
    useAlternativeLayout = undefined,
    onClickYes,
    onClickNo,
    loading,
    loadingHeading,
    cancelText = "NO",
    confirmText = "YES",
    showCancel = true,
    showConfirm = true,
    useImageHeader = {
        src: undefined,
        width: "100%"
    },
    buttonWidth
}) => {
    return (
        <>
            <div className="CardBox">
                <div className="CardBox-container">
                    {!useImageHeader?.src ? (
                        <h4
                            className="CardBox-heading dbox"
                            style={{ color: headingColor, textTransform: "uppercase" }}>
                            {loading && loadingHeading ? loadingHeading : heading}
                        </h4>
                    ) : (
                        <img
                            src={useImageHeader?.src}
                            alt="header"
                            className="CardBox-image-header"
                            style={{ width: useImageHeader?.width || "100%" }}
                        />
                    )}

                    <div
                        className="CardBox-container-hr2"
                        style={{
                            marginTop: useImageHeader?.src ? "16px" : "25px"
                        }}></div>
                    {loading === "true" || loading === true ? (
                        <div
                            style={{
                                height: "130px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            // className="CardBox-container-body"
                        >
                            <div className="loader" style={{ height: "100%" }}>
                                <div className="dot dot1"></div>
                                <div className="dot dot2"></div>
                                <div className="dot dot3"></div>
                                <div className="dot dot4"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="CardBox-container-body">
                            <h3
                                className=" ready-delete"
                                dangerouslySetInnerHTML={{ __html: title }}
                                style={{ fontSize: headingFontSize || "18px" }}></h3>

                            {useAlternativeLayout ? (
                                useAlternativeLayout
                            ) : (
                                <p className="Delete-CardBox-pregraf" dangerouslySetInnerHTML={{ __html: message }}></p>
                            )}
                            <div
                                className="CardBox-buttons button-marginb w-100"
                                style={{ display: "flex", justifyContent: "center" }}>
                                {showCancel && (
                                    <button className="CardBox-button-a w-100" onClick={onClickNo}>
                                        {cancelText}
                                    </button>
                                )}
                                {showConfirm && (
                                    <button
                                        className="CardBox-button-b"
                                        onClick={onClickYes}
                                        style={{ width: buttonWidth ? buttonWidth : "100%" }}>
                                        {confirmText}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DialogBoxComponent;
