import React from "react";
import styled from "styled-components";
import InfoCircle from "../../../../assets/icons/info-circle-white.svg";
import ChevronUp from "../../../../assets/images/invite-cher-up.svg";
import ChevronDown from "../../../../assets/images/invite-cher-down.svg";
import UrlIcon from "../../../../assets/images/url-icon.svg";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import CountrySelectDropdown from "../../../common/countrySelectDropdown/CountrySelectDropdown";
/* #region SCOPED CSS */
const InviteCardDiv = styled.div`
    &.main-invite-card {
        padding: 16px 0px 0px;
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .invite-row-one {
        width: 1482px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .company-card-column {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
    }
    .box {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
    }
    .box-label {
        display: flex;
        flex-direction: row;
        gap: 4px;
        font-size: 10px;
        color: #7c868b;
    }
    .input-container {
        background: #10141b;
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex: 1 1 0%;
        height: 38px;
        position: relative;
    }
    input.input-box {
        border: none;
        outline: none;
        background: none;
        color: #00bb00;
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1 1 0%;
    }
    .group-input {
        display: flex;
        flex-direction: row;
    }
    .filled {
        height: 38px;
        width: 5px;
        background: #141922;
        border-radius: 4px 0px 0px 4px;
    }
    .mondatory {
        height: 38px;
        width: 5px;
        background: #ff9900;
        border-radius: 4px 0px 0px 4px;
    }
    .selects-main {
        width: 100%;
    }
    .input-text-number {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-504 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 12px;
        position: relative;
    }
    .info-circle7 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .max-stores {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-labels-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-labels-font-size, 10px);
        font-weight: var(--subtitle-3-labels-font-weight, 400);
        position: relative;
    }
    .input-selector-inverted4 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 114px;
        height: 114px;
        position: relative;
    }
    .search3 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-485 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--text-and-icons-orange-500, #ff9900);
        border-width: 0px 0px 0px 5px;
        padding: 6px 16px 6px 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-485.filled-border {
        border-color: #141922;
    }
    ._200 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--heading-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-2-font-size, 24px);
        letter-spacing: var(--heading-2-letter-spacing, -0.005em);
        font-weight: var(--heading-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .chevron-up {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 46px;
        top: calc(50% - 42px);
        overflow: visible;
        cursor: pointer;
    }
    .chevron-down3 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 46px;
        top: calc(50% - -18px);
        overflow: visible;
        cursor: pointer;
    }
    .middle-comp {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid;
        background: #10141b;
    }
    .css-qr46ko {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 12px 8px 12px 8px;
    }
`;
const OptionDiv = styled.div`
    &.frame-75,
    &.frame-75 * {
        box-sizing: border-box;
    }
    &.frame-75 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
        color: #ffffff;
        cursor: pointer;
    }
    &.frame-75.disabled {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        color: unset;
        cursor: no-drop;
    }
    .frame-313316 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .custom-checkbox input[type="checkbox"] {
        display: none;
    }

    /* Style the custom checkbox */
    .custom-checkbox {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        position: relative;
    }

    /* Create the custom checkmark */
    .checkmark {
        width: 20px;
        height: 20px;
        border: 2px solid #007bff;
        border-radius: 3px;
        display: inline-block;
        margin-right: 8px;
        position: relative;
        background-color: #fff;
    }

    /* When the checkbox is checked, style the checkmark */
    .custom-checkbox input[type="checkbox"]:checked + .checkmark {
        background-color: #007bff;
        border-color: #007bff;
    }

    /* Add a checkmark symbol inside the box */
    .checkmark::after {
        content: "";
        position: absolute;
        left: 7px;
        top: 3px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
    }

    /* Show the checkmark when checked */
    .custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
        opacity: 1;
    }
`;
/* #endregion SCOPED CSS */
export default function InviteCard({
    brand,
    setBrand,
    retailBrand,
    setRetailBrand,
    name,
    setName,
    email,
    setEmail,
    country,
    setCountry,
    taxLabel,
    setTaxLabel,
    tax,
    setTax,
    maxStore,
    setMaxStore,
    maxStoreQty,
    setMaxStoreQty
}) {
    const [selectedOptions] = React.useState([]);

    const industryOptions = [
        { value: "Option 1", label: "Option 1", isDisabled: true },
        { value: "Option 2", label: "Option 2" },
        { value: "Option 3", label: "Option 3" },
        { value: "Option 4", label: "Option 4" }
    ];

    const CustomOption = ({ data, isSelected, isFocused, selectOption, isDisabled }) => {
        return (
            <OptionDiv
                className={isDisabled ? "frame-75 disabled" : "frame-75"}
                onClick={() => (isDisabled ? null : selectOption(data))}>
                <div className="frame-313316">
                    <label className="custom-checkbox">
                        <input type="checkbox" checked={isSelected} />
                        <span className="checkmark"></span>
                    </label>
                    <div className="dropdown-text">{data.label}</div>
                </div>
            </OptionDiv>
        );
    };

    const animatedComponents = makeAnimated();
    const mergedComponents = {
        ...animatedComponents,
        Option: CustomOption,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null
    };
    const formatOptionLabel = option => {
        return option.label;
    };
    const handleSelect = options => {
        const newOptions = options.flat(); // flatten the array of options
        setBrand(newOptions);
    };
    const handleMaxStore = type => {
        if (type === "increase") {
            setMaxStoreQty(maxStoreQty => maxStoreQty + 1);
            setMaxStore(maxStoreQty + 1);
        }
        if (type === "decrease") {
            if (maxStoreQty > 1) {
                setMaxStoreQty(maxStoreQty => maxStoreQty - 1);
                setMaxStore(maxStoreQty - 1);
            } else {
                setMaxStoreQty(0);
                setMaxStore("All");
            }
        }
    };
    return (
        <InviteCardDiv className="main-invite-card">
            <div className="invite-row-one">
                <div className="company-card-column">
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">To Brand</div>
                        </div>
                        <div className="group-input">
                            <div className={brand.length ? "filled" : "mondatory"}></div>
                            <div className="input-container select">
                                <ReactSelect
                                    className="selects-main"
                                    options={industryOptions}
                                    placeholder=""
                                    isMulti
                                    components={mergedComponents}
                                    value={brand}
                                    onChange={option => handleSelect(option)}
                                    formatOptionLabel={formatOptionLabel}
                                    styles={{
                                        menu: styles => ({
                                            ...styles,
                                            backgroundColor: "#10141b"
                                        }),
                                        control: styles => ({
                                            ...styles,
                                            "color": "#00bb00",
                                            "background": "transparent",
                                            "border": "0px",
                                            "boxShadow": "none",
                                            "&:placeholder": {
                                                color: "#ccc" // Change the placeholder color
                                            }
                                        }),
                                        multiValue: (styles, { data }) => {
                                            return {
                                                ...styles,
                                                backgroundColor: "#1E2631",
                                                height: "22px",
                                                borderRadius: "4px",
                                                color: "#D1D1D1"
                                            };
                                        },
                                        multiValueLabel: (styles, { data }) => ({
                                            ...styles,
                                            color: "#D1D1D1"
                                        }),
                                        multiValueRemove: (styles, { data }) => ({
                                            ...styles,
                                            "color": data.color,
                                            ":hover": {
                                                backgroundColor: data.color,
                                                color: "#D1D1D1"
                                            }
                                        }),
                                        indicatorSeparator: styles => ({
                                            ...styles,
                                            backgroundColor: "transparent"
                                        }),
                                        dropdownIndicator: styles => ({
                                            ...styles,
                                            color: "#D1D1D1"
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Retail Brand</div>
                        </div>
                        <div className="group-input">
                            <div className={retailBrand ? "filled" : "mondatory"}></div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input-box"
                                    value={retailBrand}
                                    onChange={e => setRetailBrand(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Contact Name</div>
                        </div>
                        <div className="group-input">
                            <div className={name ? "filled" : "mondatory"}></div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input-box"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="company-card-column">
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Email</div>
                        </div>
                        <div className="group-input">
                            <div className={email ? "filled" : "mondatory"}></div>
                            <div className="middle-comp">
                                <img src={UrlIcon} alt="glob-icon" />
                            </div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input-box"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Country</div>
                        </div>
                        <div className="group-input">
                            <CountrySelectDropdown
                                disabled={false}
                                dataType="country"
                                setState={e => {
                                    setCountry(e);
                                }}
                                value={country}
                                callback={e => {
                                    setCountry(e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Tax number</div>
                        </div>
                        <div className="group-input">
                            <CountrySelectDropdown
                                disabled={false}
                                dataType={"number"}
                                type="tel"
                                taxNumber={true}
                                setState={e => {
                                    setTax(e);
                                }}
                                value={tax}
                                callback={e => {
                                    setTax("");
                                    setTaxLabel(e?.code);
                                }}
                                callingCode={taxLabel}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="invite-row-two">
                <div className="input-text-number">
                    <div className="frame-504">
                        <img className="info-circle7" src={InfoCircle} alt="" />
                        <div className="max-stores">Max stores</div>
                    </div>
                    <div className="input-selector-inverted4">
                        <div className="search3">
                            <div className={maxStoreQty > 0 ? "frame-485 filled-border" : "frame-485"}>
                                <div className="_200">{maxStore}</div>
                            </div>
                        </div>
                        <img
                            className="chevron-up"
                            src={ChevronUp}
                            alt="chevron-up0.svg"
                            onClick={() => handleMaxStore("increase")}
                        />
                        <img
                            className="chevron-down3"
                            src={ChevronDown}
                            alt="chevron-down2.svg"
                            onClick={() => handleMaxStore("decrease")}
                        />
                    </div>
                </div>
            </div>
        </InviteCardDiv>
    );
}
