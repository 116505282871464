import React from "react";
import styled from "styled-components";

const StyledPage = styled.div`
    // this class has & because for styled components the parent needs it to reference itself
    &.page-template {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
    }
    .container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        background-color: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 8px;
        box-shadow: 0 0 8px 0 var(--shadow-n-600, #000000);
    }
`;

export default function PageTemplate() {
    return (
        <StyledPage className="page-template">
            <div className="container">Page Template</div>
        </StyledPage>
    );
}
