import styled from "styled-components";
import InfoCircle from "../../../../assets/icons/info-circle-white.svg";
import Upload from "../../../../assets/icons/upload-010.svg";
import ImageUploading from "../../../common/forms/ImageUploading";
import XClose0 from "../../../../assets/icons/x-close0.svg";
/* #region CSS */
const CompanyLogoDiv = styled.div`
    &.main-company-card-container {
        padding: 16px 0px 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .company-card-column {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
    }
    .box {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;
    }
    .box-label {
        display: flex;
        flex-direction: row;
        gap: 4px;
        font-size: 10px;
        color: #7c868b;
    }
    .group-input {
        display: flex;
        flex-direction: row;
    }
    .input-container {
        background: #10141b;
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex: 1 1 0%;
        height: 259px;
        position: relative;
    }
    .input-container.remove-padding {
        padding: 0px;
    }
    .mondatory {
        height: 259px;
        width: 5px;
        background: #ff9900;
        border-radius: 4px 0px 0px 4px;
    }
    .filled {
        height: 259px;
        width: 5px;
        background: #141922;
        border-radius: 4px 0px 0px 4px;
    }
    .recommended {
        height: 259px;
        width: 5px;
        background: #00aeef;
        border-radius: 4px 0px 0px 4px;
    }
    .frame-288 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .frame-199 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .browse-files-from-device {
        color: #d1d1d1;
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }
    .logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb {
        color: #7c868b;
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
    }
`;
const StyledImagePreview = styled.div`
    &.upload-card,
    &.upload-card * {
        box-sizing: border-box;
    }
    &.upload-card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .frame-51 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .store-facade {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-197 {
        border-radius: 0px 4px 4px 0px;
        height: 259px;
        width: 100%;
    }
    .x-close-container {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: static;
        z-index: 8888;
    }
    .ellipse-5 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        position: absolute;
        //left: 766px;
        right: 12px;
        top: 12px;
    }
    .x-close {
        width: 16px;
        height: 16px;
        position: absolute;
        //left: 770px;
        right: 16px;
        top: 16px;
        overflow: visible;
        z-index: 8888;
        cursor: pointer;
    }
`;
/* #endregion METHODS */
export default function BrandLogo({
    mainLogo,
    setMainLogo,
    compactLogo,
    setCompactLogo,
    mainLogoWhite,
    setMainLogoWhite,
    mainLogoBlack,
    setMainLogoBlack,
    compactLogoWhite,
    setCompactLogoWhite,
    compactLogoBlack,
    setCompactLogoBlack
}) {
    /* #region METHODS */
    const handleOnImageUpload = (imageType, data) => {
        if (imageType === "mainLogo") {
            setMainLogo(data);
        } else if (imageType === "compactLogo") {
            setCompactLogo(data);
        } else if (imageType === "mainLogoWhite") {
            setMainLogoWhite(data);
        } else if (imageType === "mainLogoBlack") {
            setMainLogoBlack(data);
        } else if (imageType === "compactLogoWhite") {
            setCompactLogoWhite(data);
        } else if (imageType === "compactLogoBlack") {
            setCompactLogoBlack(data);
        }
    };
    /* #endregion */
    return (
        <CompanyLogoDiv className="main-company-card-container">
            <div className="company-card-row">
                <div className="company-card-column">
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Main logo</div>
                        </div>
                        <div className="group-input">
                            <div className={mainLogo ? "filled" : "mondatory"}></div>
                            <div className={`input-container ${mainLogo ? "remove-padding" : ""}`}>
                                {mainLogo ? (
                                    <ImagePreview
                                        imageBase64={mainLogo?.base64}
                                        onCloseClicked={() => setMainLogo(null)}
                                    />
                                ) : (
                                    <ImageUploading onImageUpload={data => handleOnImageUpload("mainLogo", data)}>
                                        <div className="frame-288">
                                            <img className="upload-01" src={Upload} alt="" />
                                            <div className="frame-199">
                                                <div className="browse-files-from-device">Browse files from device</div>
                                                <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                    Logo size should be 16:9 in aspect ratio
                                                    <br />
                                                    Max file size accepted is 300kb
                                                </div>
                                            </div>
                                        </div>
                                    </ImageUploading>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Compact logo</div>
                        </div>
                        <div className="group-input">
                            <div className={"filled"}></div>
                            <div className={`input-container ${compactLogo ? "remove-padding" : ""}`}>
                                {compactLogo ? (
                                    <ImagePreview
                                        //imageURL={imageStoreFacadeBase64}
                                        imageBase64={compactLogo?.base64}
                                        onCloseClicked={() => setCompactLogo(null)}
                                    />
                                ) : (
                                    <ImageUploading onImageUpload={data => handleOnImageUpload("compactLogo", data)}>
                                        <div className="frame-288">
                                            <img className="upload-01" src={Upload} alt="" />
                                            <div className="frame-199">
                                                <div className="browse-files-from-device">Browse files from device</div>
                                                <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                    Logo size should be 16:9 in aspect ratio
                                                    <br />
                                                    Max file size accepted is 300kb
                                                </div>
                                            </div>
                                        </div>
                                    </ImageUploading>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-card-row">
                <div className="company-card-column">
                    <div className="box" style={{ width: "25%" }}>
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Main logo in white</div>
                        </div>
                        <div className="group-input">
                            <div className={mainLogoWhite ? "filled" : "recommended"}></div>
                            <div className={`input-container ${mainLogoWhite ? "remove-padding" : ""}`}>
                                {mainLogoWhite ? (
                                    <ImagePreview
                                        //imageURL={imageStoreFacadeBase64}
                                        imageBase64={mainLogoWhite?.base64}
                                        onCloseClicked={() => setMainLogoWhite(null)}
                                    />
                                ) : (
                                    <ImageUploading onImageUpload={data => handleOnImageUpload("mainLogoWhite", data)}>
                                        <div className="frame-288">
                                            <img className="upload-01" src={Upload} alt="" />
                                            <div className="frame-199">
                                                <div className="browse-files-from-device">Browse files from device</div>
                                                <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                    Logo size should be 1:1 in aspect ratio
                                                    <br />
                                                    Max file size accepted is 300kb
                                                </div>
                                            </div>
                                        </div>
                                    </ImageUploading>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="box" style={{ width: "25%" }}>
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Main logo in black</div>
                        </div>
                        <div className="group-input">
                            <div className={mainLogoBlack ? "filled" : "recommended"}></div>
                            <div className={`input-container ${mainLogoBlack ? "remove-padding" : ""}`}>
                                {mainLogoBlack ? (
                                    <ImagePreview
                                        //imageURL={imageStoreFacadeBase64}
                                        imageBase64={mainLogoBlack?.base64}
                                        onCloseClicked={() => setMainLogoBlack(null)}
                                    />
                                ) : (
                                    <ImageUploading onImageUpload={data => handleOnImageUpload("mainLogoBlack", data)}>
                                        <div className="frame-288">
                                            <img className="upload-01" src={Upload} alt="" />
                                            <div className="frame-199">
                                                <div className="browse-files-from-device">Browse files from device</div>
                                                <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                    Logo size should be 1:1 in aspect ratio
                                                    <br />
                                                    Max file size accepted is 300kb
                                                </div>
                                            </div>
                                        </div>
                                    </ImageUploading>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="box" style={{ width: "25%" }}>
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Compact logo in white</div>
                        </div>
                        <div className="group-input">
                            <div className={"filled"}></div>
                            <div className={`input-container ${compactLogoWhite ? "remove-padding" : ""}`}>
                                {compactLogoWhite ? (
                                    <ImagePreview
                                        //imageURL={imageStoreFacadeBase64}
                                        imageBase64={compactLogoWhite?.base64}
                                        onCloseClicked={() => setCompactLogoWhite(null)}
                                    />
                                ) : (
                                    <ImageUploading
                                        onImageUpload={data => handleOnImageUpload("compactLogoWhite", data)}>
                                        <div className="frame-288">
                                            <img className="upload-01" src={Upload} alt="" />
                                            <div className="frame-199">
                                                <div className="browse-files-from-device">Browse files from device</div>
                                                <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                    Logo size should be 1:1 in aspect ratio
                                                    <br />
                                                    Max file size accepted is 300kb
                                                </div>
                                            </div>
                                        </div>
                                    </ImageUploading>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="box" style={{ width: "25%" }}>
                        <div className="box-label">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="title">Compact logo in black</div>
                        </div>
                        <div className="group-input">
                            <div className={"filled"}></div>
                            <div className={`input-container ${compactLogoBlack ? "remove-padding" : ""}`}>
                                {compactLogoBlack ? (
                                    <ImagePreview
                                        //imageURL={imageStoreFacadeBase64}
                                        imageBase64={compactLogoBlack?.base64}
                                        onCloseClicked={() => setCompactLogoBlack(null)}
                                    />
                                ) : (
                                    <ImageUploading
                                        onImageUpload={data => handleOnImageUpload("compactLogoBlack", data)}>
                                        <div className="frame-288">
                                            <img className="upload-01" src={Upload} alt="" />
                                            <div className="frame-199">
                                                <div className="browse-files-from-device">Browse files from device</div>
                                                <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                    Logo size should be 1:1 in aspect ratio
                                                    <br />
                                                    Max file size accepted is 300kb
                                                </div>
                                            </div>
                                        </div>
                                    </ImageUploading>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CompanyLogoDiv>
    );
}
const ImagePreview = ({ imageURL, onCloseClicked, imageBase64 }) => {
    let imageSource = imageURL;
    if (!imageURL && imageBase64)
        imageSource = imageBase64.startsWith("data:") ? imageBase64 : `data:image;base64,${imageBase64}`;

    return (
        <StyledImagePreview className="upload-card">
            <div
                className="frame-197"
                style={{
                    background: `url(${imageSource}) center / cover no-repeat`
                }}>
                {onCloseClicked && (
                    <div className="x-close-container">
                        <div className="ellipse-5"></div>
                        <img className="x-close" src={XClose0} alt="" onClick={onCloseClicked} />
                    </div>
                )}
            </div>
        </StyledImagePreview>
    );
};
