import InvitePage from "../components/pages/retailers/invites/index";
import InviteView from "../components/pages/retailers/View/Index";
const routes = [
    {
        path: "/retailers",
        element: <InvitePage />
    },
    {
        path: "/retailers/view",
        element: <InviteView />
    }
];
export default routes;
