import React from "react";
import { GoogleMap, InfoBox, LoadScript, Marker } from "@react-google-maps/api";
import styled from "styled-components";
import InfoCircle from "../../../../assets/icons/info-circle-white.svg";
import ChevronDown from "../../../../assets/icons/chevron-down0.svg";
import Map from "../../../../assets/icons/map-010.svg";
import axios from "axios";
import { fetchAddressFromCoordinates, getLatLngUsingPlusCode } from "../../../../utils/LocationLib";
/* #region SCOPED CSS */
const StyledStoreLocation = styled.div`
    &.input-field-card-layout-3,
    &.input-field-card-layout-3 * {
        box-sizing: border-box;
    }
    &.input-field-card-layout-3 {
        background: var(--backgrounds-and-lines-n-400, #1e2631);
        border-radius: 4px;
        padding: 16px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-245 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-248 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-247 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .simple {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1 1 100%;
        position: relative;
        overflow: hidden;
    }
    .frame-51 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .title {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 38px;
        position: relative;
    }
    .frame-49 {
        background: var(--backgrounds-and-lines-n-500, #141922);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 38px;
        position: relative;
        overflow: hidden;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b) !important;
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
        color: var(--text-and-icons-green-500, #00bb00);
    }
    .frame-50 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        color: rgb(0, 174, 239);
        font-size: 8px;
    }
    .dropdown-selector {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1 1 100%;
        position: relative;
        overflow: hidden;
    }
    .frame-492 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle2 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .frame-493 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 38px;
        position: relative;
        overflow: hidden;
    }
    .search2 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-482 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .placeholder {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .info-circle3 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .chevron-down2 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-2482 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle4 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .info-circle5 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .info-circle6 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .frame-249 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle7 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .chevron-down3 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .info-circle8 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .info-circle9 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .map {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 1;
        width: 393px;
        position: relative;
    }
    .frame-241 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle10 {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .on-map {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-483 {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        overflow: hidden;
    }
    .frame-244 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-178 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .map-01 {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        position: relative;
        overflow: visible;
    }
`;
/* #endregion SCOPED CSS */
export default function BrandMainOffice({
    legalBusinessName = "-",
    googlePlusCode,
    setGooglePlusCode,
    setBrandLongitude,
    setBrandLatitude,
    setBrandAddressOne,
    setBrandAddressTwo,
    setBrandPostalCode,
    setBrandCountry,
    setBrandCity,
    brandCountry,
    brandCity,
    brandPostalCode,
    brandAddressOne,
    brandAddressTwo,
    brandLongitude,
    brandLatitude
}) {
    /* #region VARS */

    /* #endregion */

    /* #region METHODS */
    const onGooglePlusCodeChange = e => {
        const googlePlusCode = e.target.value;
        setGooglePlusCode(googlePlusCode);

        fetchData(googlePlusCode);
    };
    /* #endregion */

    async function fetchData(googlePlusCode) {
        if (googlePlusCode) {
            const res = await getLatLngUsingPlusCode(googlePlusCode);
            if (res) {
                setBrandLongitude(res.lat);
                setBrandLatitude(res.lng);

                fetchAddressFromCoordinates(res.lat, res.lng).then(res2 => {
                    setBrandAddressOne(res2?.street);
                    setBrandAddressTwo(res2?.streetNumber);
                    setBrandPostalCode(res2?.postCode);
                    setBrandCountry(res2?.country);
                    setBrandCity(res2?.city);
                });
            } else {
                setBrandLongitude("");
                setBrandLatitude("");
                setBrandAddressOne("");
                setBrandAddressTwo("");
                setBrandPostalCode("");
                setBrandCountry("");
                setBrandCity("");
            }
        }
    }

    return (
        <StyledStoreLocation className="input-field-card-layout-3">
            <div className="frame-245">
                <div className="frame-248">
                    <div className="frame-247">
                        <div className="simple">
                            <div className="frame-51 ov-hidden">
                                <img className="info-circle" src={InfoCircle} alt="" />
                                <div className="title w-100">Google Plus Code</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={googlePlusCode ? "frame-49" : "frame-493"}></div>
                                <div className="search">
                                    <input
                                        className="frame-48 input-remove-default w-100 text-green-500"
                                        type="text"
                                        onChange={onGooglePlusCodeChange}
                                        value={googlePlusCode}
                                    />
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="dropdown-selector hover-not-allowed">
                            <div className="frame-492">
                                <img className="info-circle2" src={InfoCircle} alt="" />
                                <div className="title">Country</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={brandCountry ? "frame-49" : "frame-493"}></div>
                                <div className="search2">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            onChange={e => setBrandCountry(e.target.value)}
                                            value={brandCountry}
                                        />
                                        <img className="chevron-down" src={ChevronDown} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="dropdown-selector hover-not-allowed">
                            <div className="frame-492">
                                <img className="info-circle3" src={InfoCircle} alt="" />
                                <div className="title">City</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={brandCity ? "frame-49" : "frame-493"}></div>
                                <div className="search2">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            onChange={e => setBrandCity(e.target.value)}
                                            value={brandCity}
                                        />
                                        <img className="chevron-down2" src={ChevronDown} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                    </div>
                    <div className="frame-2482">
                        <div className="simple hover-not-allowed">
                            <div className="frame-51">
                                <img className="info-circle4" src={InfoCircle} alt="" />
                                <div className="title">Postal Code</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={brandPostalCode ? "frame-49" : "frame-493"}></div>
                                <div className="search">
                                    <div className="frame-48">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            onChange={e => setBrandPostalCode(e.target.value)}
                                            value={brandPostalCode}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="simple hover-not-allowed">
                            <div className="frame-51">
                                <img className="info-circle5" src={InfoCircle} alt="" />
                                <div className="title">Address 1</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={brandAddressOne ? "frame-49" : "frame-493"}></div>
                                <div className="search">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            onChange={e => setBrandAddressOne(e.target.value)}
                                            value={brandAddressOne}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="simple hover-not-allowed">
                            <div className="frame-51">
                                <img className="info-circle6" src={InfoCircle} alt="" />
                                <div className="title">Address 2</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={"frame-49"}></div>
                                <div className="search">
                                    <div className="frame-48">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            onChange={e => setBrandAddressTwo(e.target.value)}
                                            value={brandAddressTwo}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                    </div>
                    <div className="frame-249">
                        <div className="simple hover-not-allowed">
                            <div className="frame-51">
                                <img className="info-circle8" src={InfoCircle} alt="" />
                                <div className="title">Latitude</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={brandLongitude ? "frame-49" : "frame-493"}></div>
                                <div className="search">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            onChange={e => setBrandLongitude(e.target.value)}
                                            value={brandLongitude}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="simple hover-not-allowed">
                            <div className="frame-51">
                                <img className="info-circle9" src={InfoCircle} alt="" />
                                <div className="title">Longitude</div>
                            </div>
                            <div className="input-selector-inverted">
                                <div className={brandLatitude ? "frame-49" : "frame-493"}></div>
                                <div className="search">
                                    <div className="frame-482">
                                        <input
                                            disabled
                                            className="frame-48 input-remove-default w-100 hover-not-allowed px-0"
                                            type="text"
                                            onChange={e => setBrandLatitude(e.target.value)}
                                            value={brandLatitude}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-50"></div>
                        </div>
                        <div className="simple hover-not-allowed"></div>
                    </div>
                </div>
                <div className="map">
                    <div className="frame-241">
                        <img className="info-circle10" src={InfoCircle} alt="" />
                        <div className="on-map">On map</div>
                    </div>
                    {!brandLongitude || !brandLatitude ? (
                        <div className="frame-483">
                            <div className="frame-244">
                                <div className="frame-178">
                                    <img className="map-01" src={Map} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <GoogleMapsStore
                            storeName={legalBusinessName}
                            setLatitude={setBrandLongitude}
                            setLongitude={setBrandLatitude}
                            setGPlusCode={setGooglePlusCode}
                            setAddressOne={setBrandAddressOne}
                            setAddressTwo={setBrandAddressTwo}
                            setPostalCode={setBrandPostalCode}
                            setCountry={setBrandCountry}
                            setCity={setBrandCity}
                            googlePlusCode={googlePlusCode}
                            latitude={brandLongitude}
                            longitude={brandLatitude}
                        />
                    )}
                </div>
            </div>
        </StyledStoreLocation>
    );
}

const GoogleMapsStore = ({
    storeName,
    setLatitude,
    setLongitude,
    setGPlusCode,
    setAddressOne,
    setAddressTwo,
    setPostalCode,
    setCountry,
    setCity,
    latitude,
    longitude,
    googlePlusCode
}) => {
    //const [location, setLocation] = useState({ lat: 0, lng: 0 });

    const location = { lat: latitude, lng: longitude };
    const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;
    const options = { closeBoxURL: "", enableEventPropagation: false };

    /* #region EFFECTS */

    /* #endregion */

    /* #region METHODS */
    const handleDragMarker = async e => {
        const center = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        };
        setLatitude(center.lat);
        setLongitude(center.lng);
        //setLocation(center);
        getPlusCodeUsingLatLng(center.lat, center.lng);

        const res = await fetchAddressFromCoordinates(center.lat, center.lng);
        if (res) {
            setAddressOne(res?.street);
            setAddressTwo(res?.streetNumber);
            setPostalCode(res?.postCode);
            setCountry(res?.country);
            setCity(res?.city);
        }
    };

    const getPlusCodeUsingLatLng = (lat, lng) => {
        axios.get("https://plus.codes/api?address=" + lat + "," + lng).then(res => {
            if (res.data?.status === "OK") {
                setGPlusCode(res.data?.plus_code?.global_code);
            }
        });
    };

    /* #endregion */

    return (
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
            <GoogleMap
                mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px"
                }}
                center={location}
                zoom={10}
                clickableIcons={false}
                options={{ draggable: true }}>
                {/* <Marker onDragEnd={handleDragMarker} position={location} draggable={true} /> */}
                <Marker onDragEnd={handleDragMarker} position={location} draggable={true} />
                <InfoBox options={options} position={location}>
                    <div style={{ width: "140px", opacity: 1, paddingTop: "9px" }}>
                        <div style={{ fontSize: "12px", width: "140px", color: "red", padding: "0px" }}>
                            {storeName || "Unnamed Store"}
                        </div>
                    </div>
                </InfoBox>
            </GoogleMap>
        </LoadScript>
    );
};
