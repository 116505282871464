import React, { useState } from "react";
import styled from "styled-components";
import info from "../../../assets/icons/info-circle-white.svg";
const InputStyles = styled.div`
    .input-selector-inverted,
    .input-selector-inverted * {
        box-sizing: border-box;
    }
    .input-selector-inverted {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 38px;
        position: relative;
        width: 100%;
    }
    .frame-49 {
        background: var(--text-and-icons-gray-300, #4f5b6d);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 38px;
        position: relative;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 0px 4px 4px 0px;
        padding: 6px 12px 6px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
        outline: none;
        border: none;
        color: #00bb00;
        width: 100%;
        font-size: 12px;
    }
`;

const GlobalInput = ({ disabled, title, placeholder, onChange, value }) => {
    return (
        <InputStyles style={{ width: "100%" }}>
            <div className="input-selector-inverted">
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <div style={{ display: "flex", gap: "4px", alignItems: "center", marginBottom: "5px" }}>
                        <img src={info} alt="" />
                        <div style={{ fontSize: "10px" }}>{title}</div>
                    </div>

                    <div style={{ display: "flex" }}>
                        <div
                            className="frame-49"
                            style={{
                                background:
                                    value?.length > 0 && !disabled
                                        ? "rgb(20, 25, 34)"
                                        : disabled
                                        ? "#3C485A"
                                        : "#FF9900"
                            }}></div>
                        <input
                            type="text"
                            className="frame-48"
                            placeholder={placeholder}
                            disabled={disabled}
                            onChange={onChange}
                            value={value}
                            style={{ color: disabled ? "#3C485A" : "#00bb00" }}
                        />
                    </div>
                </div>
            </div>
        </InputStyles>
    );
};

export default GlobalInput;
