import { configureStore } from "@reduxjs/toolkit";
import userDataReducer from "../redux/userDataSlice";
import navigationAccess from "../redux/navigationAccessSlice";

export default configureStore({
    reducer: {
        userData: userDataReducer,
        navigationAccesses: navigationAccess
    }
});
