import React from "react";
import { Link } from "react-router-dom";
import packageJson from "../../../../package.json";
import moment from "moment";
import FooterLogo from "../../../assets/images/footer-logologin.png";
import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    @media only screen and (max-width: 1200px) {
        .compact-menu {
            display: none !important;
        }
    }

    .footer-container {
        width: 100%;
        height: 40px;
        background: var(--backgrounds-lines-n-400);
        z-index: 1032;
        min-width: 1024px;
    }

    /* #region FOOTER LOGO */
    .footer-logo {
        display: flex;
        flex-shrink: 0;
        width: 220px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-right: 1px solid var(--backgrounds-lines-n-200);
    }

    .footer-logo img {
        margin: 8px 16px;
        width: 98px;
    }
    /* #endregion */

    /* #region FOOTER LEFT */
    .left-footer-menu {
        height: 40px;
        padding-left: 15px;
    }

    .control-component {
        padding-right: 35px;
    }

    .control-description {
        color: var(--text-icons-gray-100);
        font-size: 10px;
        line-height: 40px;
        margin-right: 10px;
    }

    .left-footer-menu .switch {
        display: flex;
        align-self: center;
        height: 19px;
        margin: 0;
        position: relative;
        width: 45px;
    }

    .left-footer-menu .switch input {
        height: 0;
        opacity: 0;
        width: 0;
    }

    .left-footer-menu .slider.round {
        border-radius: 34px;
        outline: none;
    }

    .left-footer-menu .slider {
        background-color: var(--backgrounds-lines-n-600);
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.4s;
    }

    .left-footer-menu .slider.round:before {
        border-radius: 50%;
    }

    .left-footer-menu .slider:before {
        background-color: var(--text-icons-gray-300);
        border: 5px solid var(--backgrounds-lines-n-400);
        bottom: -1px;
        content: "";
        height: 22px;
        left: 0;
        position: absolute;
        transition: 0.4s;
        width: 22px;
        filter: drop-shadow(0px 0px 10px var(--backgrounds-lines-n-600));
    }

    .left-footer-menu input:checked + .slider {
        background-color: var(--backgrounds-lines-n-600);
    }

    .left-footer-menu .slider.round {
        border-radius: 34px;
        outline: none;
    }

    .left-footer-menu input:checked + .slider:before {
        background-color: var(--text-icons-gray-300);
        -webkit-transform: translateX(25px);
        transform: translateX(25px);
    }
    .left-footer-menu .slider.round:before {
        border-radius: 50%;
    }
    /* #endregion */

    /* #region FOOTER RIGHT */
    .footer-links {
        display: flex;
    }

    .footer-links span {
        display: flex;
        list-style: none;
        font-size: 10px;
        margin-left: 20px;
    }
    /* #endregion */
`;
/* #endregion */

/**
 * Footer component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className] - Additional CSS class name for the component.
 * @param {string} props.isVisibleTermsCondition - The function to handle the visibility of the terms and conditions.
 * @param {string} props.isVisiblePrivacyPolicy - The function to handle the visibility of the privacy policy.
 * @returns {JSX.Element} The rendered Footer component.
 */
function Footer(props) {
    /* #region METHODS */
    const isVisibleTermsCondition = e => {
        e.preventDefault();
    };

    const isVisiblePrivacyPolicy = e => {
        e.preventDefault();
    };
    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div className="footer-container flex-row">
                <div className="footer-logo">
                    <img alt="logo" src={FooterLogo} />
                </div>
                <div className="left-footer-menu align-items-center">
                    <div className="control-component flex-1 compact-menu">
                        <span className="control-description">Compact Menu</span>
                        <label className="switch">
                            <input type="checkbox" disabled />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="control-component flex-1 dark-mode">
                        <span className="control-description">Dark Mode</span>
                        <label className="switch">
                            <input type="checkbox" disabled />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className="control-component flex-1 tooltips">
                        <span className="control-description">Tooltips</span>
                        <label className="switch">
                            <input type="checkbox" defaultChecked disabled />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <div className="right-footer-menu flex-1 align-items-center justify-content-end pr-15px">
                    <div className="footer-links">
                        <div className="link">
                            <Link to="#" className="text-default text-hover-default " onClick={isVisibleTermsCondition}>
                                <span
                                    onClick={() => {
                                        props?.setShowTAC(true);
                                    }}>
                                    Terms & Conditions
                                </span>
                            </Link>
                        </div>
                        <div className="link">
                            <Link to="#" className="text-default text-hover-default " onClick={isVisiblePrivacyPolicy}>
                                <span
                                    onClick={() => {
                                        props?.setShowTAC(true);
                                    }}>
                                    Privacy & Security
                                </span>
                            </Link>
                        </div>
                        <div className="text-inactive">
                            <span>BOtag Norge AS © {moment().format("yyyy")}</span>
                        </div>
                        <div className="text-inactive">
                            <span>{`App version ${packageJson.version}`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}

export default Footer;
