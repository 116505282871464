import FormTemplatesPage from "../components/pages/templates/forms/FormsTemplatePage";
import TemplatePage from "../components/pages/PageTemplate";

const routes = [
    {
        path: "/templates/page",
        element: <TemplatePage />
    },
    {
        path: "/templates/forms",
        element: <FormTemplatesPage />
    }
];
export default routes;
