import React from "react";
import CardComponentContainer from "../cardContainers/CardComponentContainer";
import FormSimpleComponent from "./FormSimpleComponent2";
import { v4 as uuidv4 } from "uuid";

export default function FormContainer(props) {
    const { title, showDefaultRightLegends, numOfFieldsPerLine = 4, formFields = [], children } = props;

    return (
        <div>
            <CardComponentContainer
                title={title}
                spacing="16px"
                rightComponent={showDefaultRightLegends ? <FieldsRightLegends /> : undefined}>
                <div
                    className="FormFieldsContainer"
                    style={{
                        display: !children && "inline-grid",
                        width: "100%",
                        gap: !children && "16px",
                        gridTemplateColumns: !children && `repeat(${numOfFieldsPerLine}, 1fr)`
                    }}>
                    {children
                        ? children
                        : formFields?.map(field => (
                              <FormSimpleComponent key={uuidv4()} className={field?.className} {...field} />
                          ))}
                </div>
            </CardComponentContainer>
        </div>
    );
}

const FieldsRightLegends = () => {
    const Frame186 = {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "4px",
        textTransform: "none"
    };

    const Rectangle7 = {
        width: "6px",
        height: "6px",
        borderRadius: "600px"
    };

    const legendTitle = {
        color: "#7c868b",
        fontSize: "10px",
        fontFamily: "Roboto",
        fontWeight: 400,
        wordWrap: "break-word"
    };

    return (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#00AEEF" }} />
                <div className="legendTitle" style={legendTitle}>
                    recommended
                </div>
            </div>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#FF9900" }} />
                <div className="legendTitle" style={legendTitle}>
                    mandatory
                </div>
            </div>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#4F5B6D" }} />
                <div className="legendTitle" style={legendTitle}>
                    locked
                </div>
            </div>
        </div>
    );
};
