import { useEffect } from "react";

function useClickOutside(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            // Check if the clicked target is not within the referenced element
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener to the document
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Cleanup the event listener on component unmount
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}

export default useClickOutside;
