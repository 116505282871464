import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router";

/**
 * Renders an empty page while fetching user data and know which page to redirect to.
 *
 * @component
 * @returns {JSX.Element} The rendered EmptyPage component.
 */
export default function EmptyPage() {
    /* #region VARS */
    const navigate = useNavigate();
    const OutletContext = useOutletContext();
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        setupHeaders(OutletContext, navigate);
    }, []);
    /* #endregion */

    return <div></div>;
}

function setupHeaders(OutletContext, navigate) {
    if (!localStorage.getItem("token")) {
        navigate("/login");
    }

    if (!OutletContext) return;
    const { MainLayoutProps, layoutType } = OutletContext;

    if (!MainLayoutProps) return;

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([]);
    props.setControlBarRightBtns([]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: undefined
    });
}
