import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InfoCircle from "../../../assets/icons/info-circle1.svg";
import { formatPrice } from "../../../utils/Formatter";
import { ReactComponent as Check } from "../../../assets/icons/check2.svg";

import check0 from "../../../assets/icons/formsCheck.svg";
import checkEnabled from "../../../assets/icons/formsCheckEnabled.svg";
import { Link } from "react-router-dom";

const StyledPage = styled.div`
    &.ui-card-pricing,
    &.ui-card-pricing * {
        box-sizing: border-box;
    }
    &.ui-card-pricing {
        border-radius: var(--Spacings-2xl, 24px);
        background: linear-gradient(194deg, #0b0 -36.93%, rgba(20, 25, 34, 0) 27.51%), #141922;
        /* background: linear-gradient(194.26deg, rgba(0, 187, 0, 1) 0%, rgba(20, 25, 34, 0) 100%),
            linear-gradient(to left, #141922, #141922); */
        border-radius: 24px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 280px;
        position: relative;
        overflow: hidden;
    }
    .group-12 {
        //opacity: 0.3;
        flex-shrink: 0;
        width: 129.71px;
        height: 158.81px;
        position: absolute;
        left: 159.29px;
        top: 329px;
        overflow: visible;
    }
    .frame-313259 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .b-oimage-plan {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--heading-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-3-font-size, 20px);
        font-weight: var(--heading-3-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313262 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .followed-by-standard-plan {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 12px;
    }
    .frame-313288 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-1 {
        margin: -1px -24px 0px -24px;
        border-style: solid;
        border-color: #262e3e;
        border-width: 1px 0 0 0;
        flex-shrink: 0;
        width: 347px;
        height: 0px;
        position: relative;
        top: 0px;
    }
    .frame-313272 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .frame-313319 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313283 {
        padding: 16px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .monthly-fees {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--subtitle-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313262 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .first-2-months-free {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .frame-313266 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313264 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313261 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922373 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._200 {
        color: var(--text-and-icons-gray-100, #ffffff);
        text-align: center;
        position: relative;
    }
    ._200-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: var(--heading-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-2-font-size, 24px);
        letter-spacing: var(--heading-2-letter-spacing, -0.005em);
        font-weight: var(--heading-2-font-weight, 400);
    }
    ._200-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: var(--subtitle-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
    }
    .frame-313337,
    .frame-313336 {
        background: var(--text-and-icons-green-500, #00bb00);
        border-radius: 58px;
        padding: 2px 4px 2px 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: absolute;
        left: 84px;
        top: -5px;
        height: 16px;
    }
    .frame-313336 {
        left: 57px;
        top: -4px;
    }
    ._350 {
        color: var(--backgrounds-and-lines-n-500, #141922);
        text-align: left;
        position: relative;
    }
    ._350-span {
        color: var(--backgrounds-and-lines-n-500, #141922);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 10px;
        font-weight: 400;
        text-decoration: line-through;
    }
    ._350-span2 {
        color: var(--backgrounds-and-lines-n-500, #141922);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 8px;
        font-weight: 400;
        text-decoration: line-through;
    }
    .per-brand {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--label-xs-lowercase-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--label-xs-lowercase-font-size, 8px);
        font-weight: var(--label-xs-lowercase-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-3132662 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-295922374 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._60 {
        color: var(--text-and-icons-gray-100, #ffffff);
        text-align: center;
        position: relative;
    }
    ._60-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: var(--heading-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-2-font-size, 24px);
        letter-spacing: var(--heading-2-letter-spacing, -0.005em);
        font-weight: var(--heading-2-font-weight, 400);
    }
    ._60-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: var(--subtitle-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
    }
    ._75 {
        color: var(--backgrounds-and-lines-n-500, #141922);
        text-align: left;
        position: relative;
    }
    ._75-span {
        color: var(--backgrounds-and-lines-n-500, #141922);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 10px;
        font-weight: 400;
        text-decoration: line-through;
    }
    ._75-span2 {
        color: var(--backgrounds-and-lines-n-500, #141922);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 8px;
        font-weight: 400;
        text-decoration: line-through;
    }
    .per-store {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--label-xs-lowercase-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--label-xs-lowercase-font-size, 8px);
        font-weight: var(--label-xs-lowercase-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-3132882 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 347px;
        height: 0px;
        position: relative;
    }
    .frame-313284 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313270 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-2 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-200, #262e3e);
        border-width: 1px 0 0 0;
        flex-shrink: 0;
        width: 232px;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(-0.247deg) scale(1, 1);
    }
    .commission-fee {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        letter-spacing: 0.0025em;
        font-weight: 400;
        position: relative;
        align-self: stretch;
    }
    .frame-313328 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313331 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .as-a-discount-on-purchase {
        color: var(--text-and-icons-green-500, #00bb00);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313327 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133282 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922375 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    ._9-75 {
        color: var(--text-and-icons-gray-100, #ffffff);
        text-align: center;
        position: relative;
    }
    ._9-75-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: var(--heading-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-2-font-size, 24px);
        letter-spacing: var(--heading-2-letter-spacing, -0.005em);
        font-weight: var(--heading-2-font-weight, 400);
    }
    ._9-75-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: var(--subtitle-1-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 14px);
        letter-spacing: var(--subtitle-1-letter-spacing, 0.0025em);
        font-weight: var(--subtitle-1-font-weight, 400);
    }
    ._11-75 {
        color: var(--backgrounds-and-lines-n-500, #141922);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 10px;
        font-weight: 400;
        text-decoration: line-through;
        position: relative;
    }
    .frame-295922376 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .charged-to-the-seller-per-sale {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--label-xs-lowercase-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--label-xs-lowercase-font-size, 8px);
        font-weight: var(--label-xs-lowercase-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313289 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .frame-313286 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313269 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-162 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .description-text {
        margin-top: 16px;
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .description-text-span {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .description-text-span2 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: "Roboto-SemiBold", sans-serif;
        font-size: 12px;
        font-weight: 600;
    }
    .description-text-span3 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .description-text-span4 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: "Roboto-SemiBold", sans-serif;
        font-size: 12px;
        font-weight: 600;
    }
    .description-text-span5 {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .offer-valid-time {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        font-family: "Roboto-Regular", sans-serif;
        font-size: 8px;
        font-weight: 400;
    }
    .frame-313333 {
        background: linear-gradient(95.35deg, rgba(255, 153, 0, 1) 0%, rgba(255, 153, 0, 0) 100%);
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 132.09px;
        height: 20px;
        position: absolute;
        left: 201.69px;
        top: -24px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        transform-origin: 0 0;
        transform: rotate(35.78deg) scale(1, 1);
        z-index: 999;
    }
    .campaign {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-family: var(--label-xs-uppercase-font-family, "Roboto-Medium", sans-serif);
        font-size: var(--label-xs-uppercase-font-size, 8px);
        letter-spacing: var(--label-xs-uppercase-letter-spacing, 0.0125em);
        font-weight: var(--label-xs-uppercase-font-weight, 500);
        text-transform: uppercase;
        position: relative;
    }
`;

const StyledBulletPointsAndSelect = styled.div`
    &.frame-313287,
    &.frame-313287 * {
        box-sizing: border-box;
    }
    &.frame-313287 {
        display: flex;
        flex-direction: column;
        gap: 12px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313332 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 188px;
        position: relative;
    }
    .frame-144 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .frame-313286 {
        display: flex;
        flex-direction: column;
        gap: 22px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313269 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-162 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 12px;
    }
    .check {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .power-images-with-b-otag {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .frame-313261 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 12px;
    }
    .check2 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .create-a-fulfillment-centers-network {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .frame-313262 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 12px;
    }
    .check3 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .create-an-influencers-network {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .frame-313285 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .checkbox-label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        height: 20px;
        margin: 22px 0 16px 0;
    }
    .checkbox {
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
    }
    .check5 {
        width: 16px;
        height: 16px;
    }
    .text {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "-", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .text-span {
        color: var(--text-and-icons-gray-200, #7c868b);
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
    }
    .text-span2 {
        color: var(--text-and-icons-gray-200, #7c868b);
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        text-decoration: underline;
    }
    .btn-large-solid-inverted-01 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 214px;
        height: 35px;
        position: relative;
        cursor: not-allowed;
    }
    .btn-small-filled {
        background: var(--buttons-all-platforms-gray-500, #10141b);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 35px;
        position: relative;
    }
    .btn-small-unfilled {
        background: var(--text-icons-red-500, #ff3636);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 35px;
        position: relative;
    }
    .btn-large-solid-inverted-01.enabled {
        color: #d1d1d1;
        cursor: pointer;
    }
    .btn-large-solid-inverted-01.disabled {
        pointer-events: none;
    }
    .btn-large-solid-inverted-01.enabled .btn-small-filled {
        background: #008800;
        color: #d1d1d1;
    }
    .btn-large-solid-inverted-01.enabled .btn-small-filled:hover {
        background: #009900;
    }
    .btn-large-solid-inverted-01.enabled .share {
        color: #d1d1d1;
    }

    .share {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        text-align: left;
        font-family: var(--button-text-large-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-large-font-size, 12px);
        letter-spacing: var(--button-text-large-letter-spacing, 0.0125em);
        font-weight: var(--button-text-large-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
    .unshare {
        color: #fff;
        text-align: left;
        font-family: var(--button-text-large-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-large-font-size, 12px);
        letter-spacing: var(--button-text-large-letter-spacing, 0.0125em);
        font-weight: var(--button-text-large-font-weight, 400);
        text-transform: uppercase;
        position: relative;
        cursor: not-allowed;
    }
`;

/**
 * Renders a billing plan card component.
 * @param {Object} props - The component props.
 * @param {Object} props.subscriptionPlanData - The subscription plan data.
 * @param {boolean} props.isMonthly - The billing period.
 * @param {Function} props.onSelectPlan - The function to call when the plan is selected.
 * @param {boolean} props.showLabelBrandPrices - Whether to show the label brand prices.
 * @returns {JSX.Element} The rendered billing plan card component.
 */
export default function BillingPlanCard({ subscriptionPlanData, isMonthly, onSelectPlan, showLabelBrandPrices }) {
    /* #region VARS */

    const {
        _id,
        //type,
        displayName,
        backgroundColorCode,
        ribbonColorCode,
        ribbonText,
        labelBrandsSubPlan,
        currency,
        isSmallCard,
        smallCardText,
        offerValidFor1Year,
        isSelectable,
        storesSubPlan,
        launchingSoon,
        followedBySubscriptionPlanID,
        monthsFree
    } = subscriptionPlanData;

    const [checkboxLabelBrandSelected, setCheckboxLabelBrandSelected] = useState(false);

    const currentSubscriptionID = localStorage.getItem("subscriptionPlanID");
    const hasSubscriptionPlanTMP = ![null, undefined, "", "undefined", "null"].includes(
        localStorage.getItem("subscriptionPlanID")
    );
    const cardOpacity = hasSubscriptionPlanTMP && currentSubscriptionID !== _id ? "0.5" : "1";
    const cardAlreadySelected = hasSubscriptionPlanTMP && currentSubscriptionID === _id;

    const brandPrice = isMonthly ? labelBrandsSubPlan?.monthlyFee : labelBrandsSubPlan?.annualFee;
    const oldBrandPrice = isMonthly ? labelBrandsSubPlan?.oldMonthlyFee : labelBrandsSubPlan?.oldAnnualFee;
    const shouldShowOldBrandPrice =
        !["0", "", 0, null, undefined].includes(oldBrandPrice) && oldBrandPrice !== brandPrice;

    const storePrice = isMonthly ? storesSubPlan?.monthlyFee : storesSubPlan?.annualFee;
    const oldStorePrice = isMonthly ? storesSubPlan?.oldMonthlyFee : storesSubPlan?.oldAnnualFee;
    const shouldShowOldStorePrice =
        !["0", "", 0, null, undefined].includes(oldStorePrice) && oldStorePrice !== storePrice;

    const commissionFeePercentage = storesSubPlan?.commissionFeePercentage?.toString()?.replace(".", ",");
    const oldCommissionFeePercentage = storesSubPlan?.oldCommissionFeePercentage?.toString()?.replace(".", ",");
    const shouldShowOldCommissionFeePercentage =
        !["0", "", 0, null, undefined].includes(oldCommissionFeePercentage) &&
        oldCommissionFeePercentage !== commissionFeePercentage;
    /* #endregion */

    useEffect(() => {
        setCheckboxLabelBrandSelected(false);
    }, [isMonthly]);

    return (
        <StyledPage
            className="ui-card-pricing"
            style={{
                width: "280px",
                height: isSmallCard ? "369px" : "522px",
                background: `linear-gradient(194deg, ${
                    backgroundColorCode + "4D"
                } -36.93%, rgba(20, 25, 34, 0) 27.51%), #141922`,
                opacity: cardOpacity
            }}>
            <div className="group-12">
                <GetGroupSVGwithColor color={backgroundColorCode} />
            </div>

            <div className="frame-313259">
                <div className="b-oimage-plan" style={{ height: "23px" }}>
                    {displayName || "--"}
                </div>
                {followedBySubscriptionPlanID && (
                    <div className="frame-313262">
                        <img className="info-circle" src={InfoCircle} alt="" />
                        <div className="followed-by-standard-plan">Followed by Standard Plan</div>
                    </div>
                )}
            </div>
            <div className="frame-313288">
                <div className="line-1"></div>
                <div className="frame-313272"></div>
                <div className="frame-313319" style={{ marginTop: !isSmallCard ? "8px" : "0px" }}>
                    <div className="frame-313283">
                        <div className="monthly-fees">{isMonthly ? "Monthly fees" : "Annual fees"}</div>
                    </div>
                    {monthsFree > 0 && (
                        <div className="frame-313262">
                            <img className="info-circle" src={InfoCircle} alt="" />
                            <div className="first-2-months-free">First {monthsFree} months free</div>
                        </div>
                    )}
                    <div className="frame-313266" style={{ marginTop: "8px" }}>
                        <div className={`frame-313264 ${showLabelBrandPrices ? "" : "d-none"}`}>
                            <div className="frame-313261">
                                <div className="frame-295922373" style={{ height: "28px" }}>
                                    <div className="_200">
                                        <span>
                                            <span className="_200-span">{formatPrice(brandPrice, "", "")}</span>
                                            <span className="_200-span2">{currency}</span>
                                        </span>
                                    </div>
                                    {shouldShowOldBrandPrice && (
                                        <div
                                            className="frame-313337"
                                            style={{ left: brandPrice > 1000 ? "90px" : "84px" }}>
                                            <div className="_350">
                                                <span>
                                                    <span className="_350-span">
                                                        {shouldShowOldBrandPrice
                                                            ? formatPrice(oldBrandPrice, "", "")
                                                            : "--"}
                                                    </span>
                                                    <span className="_350-span2">{currency}</span>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="frame-313262">
                                    <div className="per-brand">Per brand</div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-3132662">
                            <div className="frame-313261">
                                <div className="frame-295922374" style={{ height: "28px" }}>
                                    <div className="_60">
                                        <span>
                                            <span className="_60-span">{formatPrice(storePrice, "", "")}</span>
                                            <span className="_60-span2">{currency}</span>
                                        </span>
                                    </div>
                                    {shouldShowOldStorePrice && (
                                        <div
                                            className="frame-313337"
                                            style={{
                                                left: !showLabelBrandPrices
                                                    ? "135px"
                                                    : storePrice > 1000
                                                    ? "90px"
                                                    : "84px"
                                            }}>
                                            <div className="_75">
                                                <span>
                                                    <span className="_75-span">
                                                        {formatPrice(oldStorePrice, "", "")}
                                                    </span>
                                                    <span className="_75-span2">{currency}</span>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="frame-313262">
                                    <div className="per-store">Per store</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-3132882"></div>
            </div>
            <div className="frame-313284">
                <div className="frame-313270">
                    <div className="frame-313270" style={{ height: "25px" }}>
                        <div className="line-2"></div>
                        <div className="commission-fee">Commission Fee</div>
                    </div>
                </div>
                <div className="frame-313328" style={{ height: "12px" }}>
                    <div className="frame-313331">
                        <img className="info-circle" src={InfoCircle} alt="" />
                        <div className="as-a-discount-on-purchase">as a discount on purchase</div>
                    </div>
                </div>
                <div className="frame-313327" style={{ height: "41px" }}>
                    <div className="frame-3133282">
                        <div className="frame-295922375" style={{ height: "28px" }}>
                            <div className="_9-75">
                                <span>
                                    <span className="_9-75-span">{commissionFeePercentage}</span>
                                    <span className="_9-75-span2">%</span>
                                </span>
                            </div>
                            {shouldShowOldCommissionFeePercentage && (
                                <div
                                    className="frame-313336"
                                    style={{ left: storesSubPlan?.commissionFeePercentage > 10 ? "72px" : "57px" }}>
                                    <div className="_11-75" style={{ height: "100%" }}>
                                        {oldCommissionFeePercentage}%
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="frame-295922376">
                            <div className="charged-to-the-seller-per-sale" style={{ height: "9px" }}>
                                Charged to the seller, per sale
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-313289">
                <div className="frame-144">
                    <div className="line-2" />
                </div>
                <div className="frame-313286">
                    <div
                        className="frame-313269"
                        style={{ display: offerValidFor1Year || launchingSoon || smallCardText ? "flex" : "none" }}>
                        <div className="frame-162">
                            <div className="description-text">
                                <span
                                    className="description-text-span"
                                    dangerouslySetInnerHTML={{ __html: smallCardText }}
                                />

                                {offerValidFor1Year && (
                                    <span className="offer-valid-time">*Offer valid for one year</span>
                                )}

                                {launchingSoon && (
                                    <span className="offer-valid-time" style={{ color: "#0B0" }}>
                                        Launching soon!
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="frame-313333"
                style={{
                    background: `linear-gradient(95.35deg, ${ribbonColorCode} 0%, rgba(255, 153, 0, 0.00) 95.84%)`
                }}>
                <div className="campaign">{ribbonText}</div>
            </div>

            {isSelectable && (
                <BulletPointsAndSelection
                    subscriptionPlanID={_id}
                    onSelectPlan={onSelectPlan}
                    subscriptionPlanData={subscriptionPlanData}
                    cardAlreadySelected={cardAlreadySelected}
                    checkboxLabelBrandSelected={checkboxLabelBrandSelected}
                    setCheckboxLabelBrandSelected={setCheckboxLabelBrandSelected}
                />
            )}
        </StyledPage>
    );
}

const GetGroupSVGwithColor = ({ color }) => {
    let idCounter = Math.floor(Math.random() * 1002130);
    const generateUniqueId = () => `unique-id-${idCounter++}`;
    const gradientId0 = generateUniqueId();
    const gradientId1 = generateUniqueId();

    return (
        <svg
            width="130"
            height="160"
            viewBox="0 0 130 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}>
            <g opacity="0.3">
                <g filter="url(#filter0_f_4063_50659)">
                    <path
                        d="M0.289062 16.101C0.289062 7.48482 6.8922 0.5 15.0376 0.5H115.252C123.397 0.5 130 7.48482 130 16.101V122.108C130 130.724 123.397 137.709 115.252 137.709H15.0376C6.8922 137.709 0.289062 130.724 0.289062 122.108V16.101Z"
                        fill={`url(#${gradientId0})`}
                    />
                </g>
                <path
                    d="M33.2402 96.5063C33.2402 87.8901 40.2251 80.9053 48.8413 80.9053H96.0444C104.661 80.9053 111.645 87.8901 111.645 96.5063V143.709C111.645 152.326 104.661 159.31 96.0444 159.31H48.8413C40.2251 159.31 33.2402 152.326 33.2402 143.709V96.5063Z"
                    fill={`url(#${gradientId1})`}
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_4063_50659"
                    x="-1.71094"
                    y="-1.5"
                    width="133.711"
                    height="141.209"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_4063_50659" />
                </filter>
                <linearGradient
                    id={gradientId0}
                    x1="-77"
                    y1="-111.5"
                    x2="66.9326"
                    y2="88.1194"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0.431522" stopColor="currentColor" />
                    <stop offset="1" stopColor="#11141B" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id={gradientId1}
                    x1="146.733"
                    y1="14.3866"
                    x2="63.9851"
                    y2="131.88"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor" />
                    <stop offset="1" stopColor="#141922" />
                </linearGradient>
            </defs>
        </svg>
    );
};

const BulletPointsAndSelection = ({
    subscriptionPlanID,
    onSelectPlan,
    subscriptionPlanData,
    cardAlreadySelected,
    checkboxLabelBrandSelected,
    setCheckboxLabelBrandSelected
}) => {
    return (
        <StyledBulletPointsAndSelect className="frame-313287">
            <div className="frame-313332">
                <div className="line-22"></div>
                <div className="frame-313286">
                    <div className="frame-313269">
                        <div className="frame-162">
                            <Check className="check" />
                            <div className="power-images-with-b-otag">Power images with BOtag</div>
                        </div>
                        <div className="frame-313261">
                            <Check className="check2" />
                            <div className="create-a-fulfillment-centers-network">
                                Create a Fulfillment Centers Network
                            </div>
                        </div>
                        <div className="frame-313262">
                            <Check className="check3" />
                            <div className="create-an-influencers-network">Create an Influencers Network</div>
                        </div>
                    </div>

                    <div className="frame-313285" style={{ opacity: cardAlreadySelected ? "0.5" : "1" }}>
                        <div className="checkbox-label">
                            <div
                                className="checkbox cursor-pointer"
                                onClick={() => setCheckboxLabelBrandSelected(!checkboxLabelBrandSelected)}>
                                {/* <div className="rectangle-1"></div>
                                <div className="check4" /> */}
                                <img
                                    className="check5"
                                    src={checkboxLabelBrandSelected ? checkEnabled : check0}
                                    alt="check"
                                />
                            </div>
                            <div className="text">
                                <span>
                                    <span className="text-span">I agree with the&nbsp;</span>
                                    <Link to="https://home.botag.no/terms/" target="_blank">
                                        <span className="text-span2">Terms &amp; Conditions</span>
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div
                            className={
                                !localStorage.getItem("subscriptionPlanID") && checkboxLabelBrandSelected
                                    ? "btn-large-solid-inverted-01 enabled"
                                    : "btn-large-solid-inverted-01"
                            }
                            onClick={
                                !localStorage.getItem("subscriptionPlanID") && checkboxLabelBrandSelected
                                    ? () => onSelectPlan(subscriptionPlanData)
                                    : null
                            }>
                            <div
                                className={
                                    localStorage.getItem("subscriptionPlanID")
                                        ? "btn-small-unfilled"
                                        : "btn-small-filled"
                                }>
                                <div className={localStorage.getItem("subscriptionPlanID") ? "unshare" : "share"}>
                                    {localStorage.getItem("subscriptionPlanID") ? "CANCEL PLAN" : "SELECT PLAN"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledBulletPointsAndSelect>
    );
};
