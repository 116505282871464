import React, { useMemo, useRef, useState } from "react";
import countries from "countries-and-timezones";
import { getCountryCallingCode } from "libphonenumber-js";
import styled from "styled-components";

import useClickOutside from "../../../utils/hooks/useClickOutside";

/* #region scoped css */
const CountrySelectDropdownStyles = styled.div`
    .frame-60,
    .frame-60 * {
        box-sizing: border-box;
    }
    .frame-60 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .frame-50 {
        background: var(--text-and-icons-orange-500, #ff9900);
        border-radius: 4px 0px 0px 4px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 5px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .frame-49 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 0px 1px 0px 0px;
        padding: 6px 0px 6px 0px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 50px;
        height: 37px;
        position: relative;
        overflow: hidden;
    }
    .input-indicator-types {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .globe-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .frame-4811 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 0px 4px 4px 0px;
        padding: 0px 10px 0px 10px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 37px;
        position: relative;
        overflow: hidden;
        color: #00bb00;
        font-size: 12px;
        font-weight: 400;
    }
    .placeholder {
        text-align: left;
        font-family: var(--subtitle-2-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
        background: var(--backgrounds-and-lines-n-600, #10141b);
        outline: none;
        border: none;
    }
    .chevron-right {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .input-field-dropdown,
    .input-field-dropdown * {
        box-sizing: border-box;
    }
    .input-field-dropdown {
        background: #141922;
        border-radius: 4px;
        padding: 12px 8px 12px 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        position: sticky;
        box-shadow: var(--drop-shadow-100-box-shadow, 0px 0px 10px 0px rgba(17, 22, 32, 1));
    }
    .frame-74 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-80 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .search {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 30px;
        position: relative;
    }
    .frame-48 {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-100, #3c485a);
        border-width: 1px;
        padding: 6px 8px 6px 8px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .search-sm {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .search-list {
        color: var(--text-and-icons-gray-300, #4f5b6d);
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
        background: var(--backgrounds-and-lines-n-600, #10141b);
        width: 100%;
        border: none;
        outline: none;
        color: #fff;
    }
    .frame-742 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 180px;
        position: relative;
        overflow-y: auto;
    }
    /* WebKit custom scrollbar */
    .frame-742::-webkit-scrollbar {
        width: 3px;
    }

    .frame-742::-webkit-scrollbar-thumb {
        background-color: #3c485a;
        border-radius: 10px;
    }

    .frame-742::-webkit-scrollbar-track {
        background: #141922;
        box-shadow: none;
    }

    .frame-742::-webkit-scrollbar-corner {
        display: none;
    }

    .frame-349 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .frame-348 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-348 div:hover {
        background-color: rgba(30, 38, 49, 1);
    }
    .frame-75 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
        margin-right: 4px;
    }
    .dropdown-text {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: left;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
    .frame-76 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-77 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-78 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-79 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-802 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-350 {
        padding: 0px 0px 0px 4px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
`;
/* #endregion */
const CountrySelectDropdown = ({
    callback = () => {},
    value = "",
    setState = () => {},
    disabled = false,
    dataType = "",
    callingCode = "",
    preImgSrc = "",
    type = "text",
    taxNumber = false,
    preEnrollmentVarient = false
}) => {
    /* #region hooks */
    const [showDetails, setShowDetails] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const ref = useRef();
    useClickOutside(ref, () => {
        setShowDetails(false);
        setSearchValue("");
    });
    /* #endregion */

    /* #region variables */
    const formatTimezone = utcOffset => {
        const sign = utcOffset.startsWith("-") ? "-" : "+";
        const [hours, minutes] = utcOffset.slice(1).split(":").map(Number);
        return `GMT${sign}${hours}:${minutes === 0 ? "00" : minutes}`;
    };

    const options = useMemo(() => {
        return Object.values(countries.getAllCountries())
            .filter(country => country.name.toLowerCase().includes(searchValue.toLowerCase()))
            .map(country => {
                const timezoneInfo = countries.getTimezone(country.timezones[0]);

                let callingCode = null;
                try {
                    callingCode = getCountryCallingCode(country.id);
                } catch (error) {
                    callingCode = "N/A";
                }

                return {
                    value: country.name,
                    label: country.name,
                    code: country.id,
                    shortName: country.id,
                    timeZone: country.timezones[0],
                    formattedTimeZone: formatTimezone(timezoneInfo.utcOffsetStr),
                    callingCode
                };
            });
    }, [countries, getCountryCallingCode, formatTimezone, searchValue]);
    /* #endregion */

    /* #region methods */
    const handleChange = selectedOption => {
        setShowDetails(false);
        setSearchValue("");
        callback(selectedOption);
    };
    /* #endregion */
    return (
        <CountrySelectDropdownStyles style={{ width: "100%", pointerEvents: disabled && "none" }}>
            <div className="frame-60">
                {preEnrollmentVarient ? null : (
                    <div
                        className="frame-50"
                        style={{
                            backgroundColor: value?.length >= 1 || disabled ? "#141922" : "#ff9900"
                        }}></div>
                )}

                {dataType === "number" ? (
                    <div
                        className="frame-49"
                        style={{ justifyContent: !taxNumber && "flex-end", width: "55px" }}
                        onClick={() => {
                            setShowDetails(true);
                        }}>
                        <div className="input-indicator-types" style={{ marginRight: "8px" }}>
                            <span
                                style={{ fontSize: "12px", fontWeight: 400, color: taxNumber ? "#00bb00" : "#D1D1D1" }}>
                                {callingCode ? `${taxNumber ? "" : "+"}${callingCode}` : null}
                            </span>{" "}
                            {taxNumber ? null : (
                                <i className="fa-solid fa-chevron-down" style={{ fontSize: "7px" }}></i>
                            )}
                        </div>
                    </div>
                ) : (
                    preImgSrc && (
                        <div className="frame-49">
                            <div className="input-indicator-types">
                                <img className="globe-01" src={preImgSrc} alt="" />
                            </div>
                        </div>
                    )
                )}

                {preEnrollmentVarient ? (
                    <PreEnrollmentVarient
                        setShowDetails={setShowDetails}
                        value={value}
                        setSearchValue={setSearchValue}
                        setState={setState}
                    />
                ) : (
                    <div
                        className="frame-4811"
                        onClick={() => {
                            setShowDetails(true);
                        }}>
                        <input
                            style={{
                                width: "100%",
                                height: "100%",
                                color: disabled && "#3C485A",
                                marginRight: taxNumber && "50px",
                                borderRight: taxNumber && "1px solid #3C485A"
                            }}
                            className="placeholder"
                            type={type}
                            value={value}
                            onChange={e => {
                                setSearchValue(e.target.value);
                                setState(e.target.value);
                            }}
                            // style={{ color: "textColor" }}
                        />
                        {dataType === "number" ? null : (
                            <i className="fa-solid fa-chevron-down" style={{ fontSize: "10px", color: "white" }}></i>
                        )}
                    </div>
                )}

                {showDetails && options?.length >= 1 && !taxNumber && (
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            top: "40px",
                            marginTop: "10px",
                            zIndex: 9999
                        }}
                        ref={ref}>
                        <div className="input-field-dropdown">
                            <div className="frame-74">
                                <div className="frame-80">
                                    <div className="frame-742">
                                        <div className="frame-349">
                                            <div className="frame-348">
                                                {options?.map(i => {
                                                    if (dataType === "country") {
                                                        return <CountriesComponent handleChange={handleChange} i={i} />;
                                                    } else if (dataType === "timezone") {
                                                        return <TimeZoneComponent handleChange={handleChange} i={i} />;
                                                    } else if (dataType === "number") {
                                                        return <NumberComponent handleChange={handleChange} i={i} />;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </CountrySelectDropdownStyles>
    );
};

export default CountrySelectDropdown;
/* #region small components */
const CountriesComponent = ({ handleChange, i }) => {
    return (
        <div
            className="frame-75"
            onClick={() => {
                handleChange(i);
            }}>
            <div className="dropdown-text" style={{ color: "textColor" }}>
                {i?.value}
            </div>
        </div>
    );
};
const TimeZoneComponent = ({ handleChange, i }) => {
    return (
        <div
            className="frame-75"
            onClick={() => {
                handleChange(i);
            }}>
            <div className="dropdown-text">
                {i?.formattedTimeZone} {`(${i?.timeZone})`}
            </div>
        </div>
    );
};
const NumberComponent = ({ handleChange, i }) => {
    return (
        <div
            className="frame-75"
            onClick={() => {
                handleChange(i);
            }}>
            <div className="dropdown-text">
                +{i?.callingCode} {`(${i?.value})`}
            </div>
        </div>
    );
};

const PreEnrollmentVarient = ({ setShowDetails, value, setSearchValue, setState }) => {
    return (
        <div
            onClick={() => {
                setShowDetails(true);
            }}
            style={{ width: "100%", borderBottom: "1px solid rgb(60, 72, 90)", display: "flex" }}>
            <input
                type="text"
                style={{
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "0.75rem",
                    color: "#fff",
                    borderBottom: "none",
                    marginBottom: "12px"
                }}
                value={value}
                onChange={e => {
                    setSearchValue(e.target.value);
                    setState(e.target.value);
                }}
            />
            <span style={{ marginRight: "10px" }}>
                <i className="fa-solid fa-chevron-down" style={{ fontSize: "10px" }}></i>
            </span>
        </div>
    );
};

/* #endregion */
