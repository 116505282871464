import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import loader from "../../../assets/images/loader.svg";

/* #region STYLED CSS */
const StyledPage = styled.div`
    &.ImageBeaconsContainer {
        position: relative;
        flex: 1 1 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 9px;
        display: flex;
        width: fit-content;
        height: 100%;

        min-height: 400px !important;
        margin: 0 auto;
    }

    .BeaconsContainerElement {
        width: 100%;
        height: auto !important;
        object-fit: contain;
        max-height: 100%;
    }

    .BeaconsContainerElement.loading {
        width: 50px;
        height: 50px;
    }
`;

/*
const StyledBeacon = styled.div`
    @keyframes pulse-white {
        0% {
            transform: scale(1.4);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }

        100% {
            transform: scale(1);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
    }

    &.BeaconContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: absolute;
        user-select: none;
        touch-action: none;
    }

    .BotagBeacon {
        height: 28px;
        width: 28px;
        background: var(--text-icons-green-500);
        border: 3px solid #10141b;
        background-color: #fff;
        border-radius: 50px;
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
        animation: pulse-white 1.9s infinite;
    }

    .product-label {
        background-color: rgba(16, 20, 27, 0.75);
        border-radius: 5px;
        color: #fff;
        padding: 5px 10px;
        position: absolute;
        top: 45px;
        white-space: nowrap;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
*/

const StyledBeaconV2 = styled.div`
    &.BeaconContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: absolute;
        user-select: none;
        touch-action: none;
    }

    .BotagBeacon {
        display: flex;
        width: 25px;
        height: 25px;
        padding: 6.4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6.4px;
        border-radius: 37.12px;
        outline: 6.4px solid rgba(0, 96, 204, 0.2);
        background: var(--Buttons-All-Platforms-btn-action-blue, #0060cc);
    }

    .product-label {
        position: absolute;
        top: 0px;
        color: #fff;

        /* Caption */
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.08px;
    }
`;

const StyledBeaconV3 = styled.div`
    &.BeaconContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        position: absolute;
        user-select: none;
        touch-action: none;
    }

    .BotagBeacon1 {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 37.12px;
        backdrop-filter: blur(calc(var(--blur-20, 20px) / 2));
        background: rgba(0, 0, 0, 0.3);
    }
    .BotagBeacon {
        display: flex;
        width: 11px;
        height: 11px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 37.12px;
        background: #fff;
    }

    .product-label {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 6999.3px;
        backdrop-filter: blur(calc(var(--blur-20, 20px) / 2));

        padding: 5px 10px;
        position: absolute;
        top: 30px;
        white-space: nowrap;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;

        color: #fff;
        text-align: center;
        font-family: "Open Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 166.667% */
    }
`;
/* #endregion */

/**
 * Renders an image with beacons on it.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @param {Object} props.style - The inline styles for the component.
 * @param {string} props.src - The source URL of the image.
 * @param {Array} props.BOTagsList - The list of tags to be displayed on the image.
 * @param {boolean} props.isLoading - A flag to indicate if the image is loading.
 * @param {boolean} props.onBeaconClick - The function to be called when a beacon is clicked.
 * @param {boolean} props.useStyleV2 - A flag to indicate if the component should use the new style.
 * @param {string} props.maxHeight - The maximum height of the image.
 * @returns {JSX.Element} The rendered ImageBeacons component.
 */
export default function ImageBeacons(props) {
    /* #region USE STATES */
    //const BOTagsList = props.BOTagsList;
    const imageRef = useRef(null);

    const [imageScale, setImageScale] = useState(1);
    const [imageOffsetTop, setImageOffsetTop] = useState(0);
    const [BOTagsList, setBOTagsList] = useState(props.BOTagsList);

    /* #endregion */

    /* #region USE EFFECTS */
    useEffect(() => {
        window.addEventListener("resize", reloadImageScale);

        return () => {
            window.removeEventListener("resize", reloadImageScale);
        };
    }, []);

    useEffect(() => {
        const reloadWithDelay = () => {
            setTimeout(() => {
                reloadImageScale();
            }, 100);
        };
        if (imageRef.current) imageRef.current.addEventListener("load", reloadWithDelay);

        return () => {
            if (imageRef.current) imageRef.current.removeEventListener("load", reloadWithDelay);
        };
    }, [imageRef.current?.src, props?.isLoading]);

    useEffect(() => {
        if (!BOTagsList || BOTagsList.length === 0 || props?.isLoading) return;
        reloadBOTagsScaling();
    }, [imageScale, props?.isLoading, imageOffsetTop, imageRef.current?.src]);
    /* #endregion */

    /* #region METHODS */
    const reloadImageScale = () => {
        if (!imageRef.current || !imageRef.current?.getBoundingClientRect()) return;
        const { width } = imageRef.current?.getBoundingClientRect();
        const naturalWidth = imageRef.current.naturalWidth;
        const scale = width / naturalWidth;
        setImageScale(scale);
        setImageOffsetTop(imageRef.current?.offsetTop ?? 0);
    };

    const reloadBOTagsScaling = () => {
        const newBOTagsList = BOTagsList.map(tag => {
            const naturalX = tag.beaconXposition ?? 0;
            const naturalY = tag.beaconYposition ?? 0;
            return {
                ...tag,
                x: naturalX * imageScale,
                y: naturalY * imageScale + imageOffsetTop
            };
        });
        setBOTagsList(newBOTagsList);
    };
    /* #endregion */

    return (
        <StyledPage className={` ImageBeaconsContainer ${props?.className ?? ""}`} style={props?.style}>
            {props?.isLoading && <img className="BeaconsContainerElement loading" src={loader} alt="Loading" />}

            <img
                className={`BeaconsContainerElement ${props?.isLoading ? "hidden" : ""}`}
                ref={imageRef}
                src={props?.src}
                alt="BOImage Canvas"
                onClick={reloadBOTagsScaling}
                style={{ maxHeight: props?.maxHeight ?? "100%" }}
            />

            {!props?.isLoading &&
                BOTagsList.map((tag, index) =>
                    !props?.useStyleV2 ? (
                        <Beacon
                            key={index}
                            x={tag.x}
                            y={tag.y}
                            onClick={() => {
                                if (props?.onBeaconClick) props?.onBeaconClick(tag);
                            }}
                            label={tag?.productType}
                            clicable={props?.onBeaconClick ? true : false}
                            number={index}
                        />
                    ) : (
                        <BeaconV2
                            key={index}
                            x={tag.x}
                            y={tag.y}
                            onClick={() => {
                                if (props?.onBeaconClick) props?.onBeaconClick(tag);
                            }}
                            label={(index + 1).toString()}
                            clicable={props?.onBeaconClick ? true : false}
                            number={index}
                        />
                    )
                )}
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */
/**
 * Represents a tag element on the image.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.x - The x-coordinate of the tag element.
 * @param {number} props.y - The y-coordinate of the tag element.
 * @param {string} props.label - The label to be displayed on the tag element.
 * @param {Function} props.onClick - The function to be called when the tag element is clicked.
 * @param {boolean} props.clicable - A flag to indicate if the tag element is clicable.
 * @returns {JSX.Element} The rendered tag element.
 */
const Beacon = ({ x, y, label, onClick, clicable, number }) => {
    const dimensionsNotSet = !x || !y;
    x = x ?? 0;
    y = y ?? 0;

    return (
        <StyledBeaconV3
            className="BeaconContainer"
            onClick={() => (onClick ? onClick() : null)}
            style={{
                top: y - 12.5,
                left: x - 12.5,
                display: dimensionsNotSet ? "none" : "flex",
                cursor: clicable ? "pointer" : "default",
                zIndex: 999 + (number ?? 0)
            }}>
            <div className="BotagBeacon1">
                <div className="BotagBeacon" />
            </div>
            {label && <div className="product-label">{label}</div>}
        </StyledBeaconV3>
    );
};

export const tagObject = ({ x, y, number, naturalX, naturalY, selected = false, completed = false, data = {} }) => {
    return { x, y, number, naturalX, naturalY, selected, completed, data };
};

/* #region SMALL COMPONENTS */
/**
 * Represents a tag element on the image.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.x - The x-coordinate of the tag element.
 * @param {number} props.y - The y-coordinate of the tag element.
 * @param {string} props.label - The label to be displayed on the tag element.
 * @param {Function} props.onClick - The function to be called when the tag element is clicked.
 * @param {boolean} props.clicable - A flag to indicate if the tag element is clicable.
 * @returns {JSX.Element} The rendered tag element.
 */
const BeaconV2 = ({ x, y, label, onClick, clicable, number }) => {
    const dimensionsNotSet = !x || !y;
    x = x ?? 0;
    y = y ?? 0;

    return (
        <StyledBeaconV2
            className="BeaconContainer"
            onClick={() => (onClick ? onClick() : null)}
            style={{
                top: y - 12,
                left: x - 12,
                display: dimensionsNotSet ? "none" : "flex",
                cursor: clicable ? "pointer" : "default",
                zIndex: 999 + (number ?? 0)
            }}>
            <div className="BotagBeacon" />
            {label && <div className="product-label">{label}</div>}
        </StyledBeaconV2>
    );
};
/* #endregion */
