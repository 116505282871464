import DashboardRoutes from "../routes/DashboardRoutes";
import BOImagesRoutes from "../routes/BOImagesRoutes";
import FullfillmentCentersRoutes from "../routes/FulfillmentCentersRoutes";
import StoresRoutes from "../routes/StoresRoutes";
import OrderRoutes from "../routes/OrderRoutes";
import ProductRoutes from "../routes/ProductRoutes";
import BrandsRoutes from "../routes/BrandsRoutes";
import CompaniesRoutes from "../routes/CompaniesRoutes";
import AccountRoutes from "../routes/AccountRoutes";
import OtherRoutes from "../routes/OtherRoutes";
import RetailersRoutes from "../routes/RetailerRoutes";
import TemplateRoutes from "../routes/TemplateRoutes";

export const AllRoutes = [
    ...OtherRoutes,
    ...DashboardRoutes,
    ...BOImagesRoutes,
    ...FullfillmentCentersRoutes,
    ...StoresRoutes,
    ...OrderRoutes,
    ...ProductRoutes,
    ...BrandsRoutes,
    ...CompaniesRoutes,
    ...AccountRoutes,
    ...RetailersRoutes,
    ...TemplateRoutes
];
