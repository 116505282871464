import React from "react";
import styled from "styled-components";
import image1 from "../../assets/images/login-top-img.png";
import BOstageBlogo from "../../assets/images/footer-logologin.png";
import packageJson from "../../../package.json";

const StyledPage = styled.div`
    &.screen-4-better-on-desktop,
    &.screen-4-better-on-desktop * {
        box-sizing: border-box;
    }
    &.screen-4-better-on-desktop {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        height: 100dvh;
        position: relative;
        overflow: hidden;
    }
    .group-16 {
        position: absolute;
        inset: 0;
    }
    .ellipse-2 {
        background: #00bb00;
        border-radius: 50%;
        width: 388.59px;
        height: 388.59px;
        position: absolute;
        left: -76.92px;
        top: 635.33px;
        filter: blur(201.45px);
    }
    .ellipse-1 {
        background: #ffe2b6;
        border-radius: 50%;
        width: 183.8px;
        height: 183.8px;
        position: absolute;
        left: -89px;
        top: 798.15px;
        filter: blur(130.95px);
    }

    .rectangle-10 {
        background: rgba(16, 20, 27, 0.7);
        width: 100%;
        height: 137px;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .rectangle-11 {
        background: rgba(16, 20, 27, 0.7);
        width: 100%;
        height: 100dvh;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .frame-313865 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: calc(50% - 88px);
        bottom: 20px;
    }
    .frame-313866 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .terms-conditions {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .privacy-security {
        color: var(--primary-white-500, #ffffff);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .b-otag-norge-as-2024 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .app-version-1-2-0 {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-313864 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        position: sticky;
        left: 0px;
        top: 0px;
    }
    .status-bar {
        padding: 25px 14px 15px 34px;
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: flex-end;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        backdrop-filter: blur(40px);
    }
    .time {
        flex-shrink: 0;
        width: 26.62px;
        height: 10.36px;
        position: relative;
        overflow: visible;
    }
    .stack {
        display: flex;
        flex-direction: row;
        gap: 5px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 12px;
        position: relative;
    }
    .cellular {
        flex-shrink: 0;
        width: 17px;
        height: 10.67px;
        position: relative;
        overflow: visible;
    }
    .wifi {
        flex-shrink: 0;
        width: 15.27px;
        height: 10.97px;
        position: relative;
        overflow: visible;
    }
    .battery {
        flex-shrink: 0;
        width: 24.33px;
        height: 11.33px;
        position: relative;
        overflow: visible;
    }
    .header {
        padding: 24px 12px var(--spacings-2xl, 24px) 24px;
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        height: 64px;
        position: relative;
        backdrop-filter: blur(40px);
    }
    .image-1 {
        flex-shrink: 0;
        width: 96px;
        height: 30.55px;
        position: relative;
        object-fit: cover;
    }
    .frame-29222 {
        opacity: 0;
        display: flex;
        flex-direction: row;
        gap: 15px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29518 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .marker-pin-04 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .frame-29219 {
        opacity: 0;
        display: flex;
        flex-direction: row;
        gap: 15px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .bell-02 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .frame-257 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        position: sticky;
        left: calc(50% - 128.5px);
        top: calc(50% - 116px);
        width: 257px;
    }
    .image-12 {
        flex-shrink: 0;
        width: 165px;
        height: 40.46px;
        position: relative;
        object-fit: contain;
    }
    .line-4 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-200, #262e3e);
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .frame-258 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-286 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .better-on-desktop {
        color: #ffffff;
        text-align: center;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 30px;
        font-weight: 700;
        position: relative;
    }
    .frame-285 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-160 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .b-ostage-b-your-backoffice-for-b-otag-is-not-prepared-for-mobile-please-use-go-to-login-button-in-the-email-on-your-desktop {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-family: var(--heading-4-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--heading-4-font-size, 18px);
        font-weight: var(--heading-4-font-weight, 400);
        position: relative;
        width: 272px;
    }
`;

export default function MobileNotAvailable() {
    return (
        <StyledPage className="screen-4-better-on-desktop">
            <div className="ellipse-2"></div>
            <div className="ellipse-1"></div>
            <div className="rectangle-10"></div>
            <div className="rectangle-11"></div>
            <div className="frame-313865">
                <div className="frame-313866">
                    <div className="terms-conditions">Terms &amp; Conditions</div>
                    <div className="privacy-security">Privacy &amp; Security</div>
                </div>
                <div className="b-otag-norge-as-2024">BOtag Norge AS © {new Date().getFullYear()}</div>
                <div className="app-version-1-2-0">App Version {packageJson.version}</div>
            </div>
            <div className="frame-313864">
                <div className="header">
                    <img className="image-1" src={image1} alt="BOtag" />
                    <div className="frame-29222">
                        <div className="frame-29518"></div>
                    </div>
                    <div className="frame-29219">
                        <div className="frame-29518"></div>
                    </div>
                </div>
            </div>
            <div className="frame-257">
                <img className="image-12" src={BOstageBlogo} alt="BOstageB" />
                <div className="line-4"></div>
                <div className="frame-258">
                    <div className="frame-286">
                        <div className="better-on-desktop">Better on desktop</div>
                    </div>
                    <div className="frame-285">
                        <div className="frame-160">
                            <div className="b-ostage-b-your-backoffice-for-b-otag-is-not-prepared-for-mobile-please-use-go-to-login-button-in-the-email-on-your-desktop">
                                BOstageB, your backoffice for BOtag, is not prepared for mobile.
                                <br />
                                <br />
                                Please use GO TO LOGIN button in the email, on your desktop.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
