import CountrySelectDropdown from "../../common/countrySelectDropdown/CountrySelectDropdown";
import FormContainer from "../../common/forms/FormContainer";
import info from "../../../assets/icons/info-circle-white.svg";
import GlobalInput from "../../common/forms/GlobalInput";
import ImageUploading from "../../common/forms/ImageUploading";
import Upload from "../../../assets/icons/upload-010.svg";
import XClose0 from "../../../assets/icons/x-close0.svg";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Globe from "../../../assets/images/globe-01.png";
import Clock from "../../../assets/images/Clock.png";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import * as Library from "../../../utils/Library";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import { toast } from "react-toastify";
import success from "../../../assets/images/success.png";
import { useDispatch, useSelector } from "react-redux";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import LayoutContext from "../../../LayoutContext";
import { BLOB_ACCOUNT_IMAGE } from "../../../config/constant";

/* #region SCOPED CSS */
const OrganizationAddPageStyles = styled.div`
    .frame-288 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .frame-199 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        text-align: center;
    }
`;
const StyledImagePreview = styled.div`
    .upload-card,
    .upload-card * {
        box-sizing: border-box;
    }
    .upload-card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-51 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .store-facade {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-197 {
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-500, #141922);
        border-width: 0px 0px 0px 5px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 100%;
        position: relative;
    }
    .x-close-container {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: static;
        z-index: 8888;
    }
    .ellipse-5 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        position: absolute;
        //left: 766px;
        right: 12px;
        top: 12px;
    }
    .x-close {
        width: 16px;
        height: 16px;
        position: absolute;
        //left: 770px;
        right: 16px;
        top: 16px;
        overflow: visible;
        z-index: 8888;
        cursor: pointer;
    }
`;
/* #endregion */
const OrganizationAddPage = () => {
    /* #region hooks  */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { setShowIsAccountSavedPopup } = React.useContext(LayoutContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [imageStoreFacadeDataBorder, setImageStoreFacadeDataBorder] = useState(false);
    const [imageStoreInteriorDataBorder, setImageInteriorDataBorder] = useState(false);
    const [OrganizationDetails, setOrganizationDetails] = useState({
        country: "",
        timeZone: "",
        phoneNumber: "",
        callingCode: "",
        accountOwnerName: localStorage.getItem("userFirstName"),
        lastName: localStorage.getItem("userLastName"),
        accountName: "",
        email: localStorage.getItem("userEmail"),
        profileFacadeData: null,
        largeLogoFacadeData: null,
        smallLogoFacadeData: null
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showDialogBox, setShowDialogBox] = useState(false);
    const navigations = useSelector(state => state.navigationAccesses);
    const dispatch = useDispatch();
    /* #endregion */

    /* #region  variables */
    const isValid = Object.entries(OrganizationDetails)
        .filter(([key]) => key !== "smallLogoFacadeData")
        .every(([_, value]) => !!value);

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };

    const { country, timeZone, phoneNumber, callingCode, profileFacadeData, largeLogoFacadeData, smallLogoFacadeData } =
        OrganizationDetails;

    const formData = {
        organizationID: localStorage.getItem("currentOrganizationID"),
        BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
        organizationName: OrganizationDetails?.accountName,
        countryID: country,
        timeZoneID: timeZone,
        phoneNumberCountryID: callingCode,
        PhoneNumber: phoneNumber,
        profilePhotoData: profileFacadeData,
        mainLogoData: largeLogoFacadeData,
        compactLogoData: smallLogoFacadeData
    };

    /* #endregion */

    /* #region methods */
    const handleUpdateOrganization = () => {
        Library.makeCommonPostRequest("Organization", "updateOrganization", formData, false, setIsLoading).then(res => {
            if (res?.data?.status === 200) {
                const updatedNavigations = {
                    ...navigations.navigationAccesses,
                    organization: {
                        ...navigations.navigationAccesses.organization,
                        createModeOnly: false
                    },
                    companies: {
                        ...navigations.navigationAccesses.companies,
                        navigationPanel: true,
                        createModeOnly: true
                    }
                };
                dispatch(setNavigations({ navigationAccesses: updatedNavigations }));
                localStorage.setItem("currentOrganizationName", OrganizationDetails?.accountName);
                if (res?.data?.data?.mainImageUrl) {
                    localStorage.setItem("currentOrganizationLogo", res?.data?.data?.mainImageUrl);
                }
                navigate("/companies/create");
                setShowIsAccountSavedPopup(true);
            }
        });
    };

    const handleOnImageUpload = (imageType, data) => {
        if (imageType === "storeFacade") {
            setOrganizationDetails({
                ...OrganizationDetails,
                smallLogoFacadeData: data
            });
            setImageStoreFacadeDataBorder(true);
        } else if (imageType === "storeInterior") {
            setOrganizationDetails({
                ...OrganizationDetails,
                largeLogoFacadeData: data
            });
            setImageInteriorDataBorder(true);
        } else if (imageType === "profilePic") {
            setOrganizationDetails({
                ...OrganizationDetails,
                profileFacadeData: data
            });
        }
    };
    /* #endregion */

    /* #region effects */
    useEffect(() => {
        setupHeaders(props, location);
    }, [props?.layoutType, navigations]);
    /* #endregion */
    return (
        <OrganizationAddPageStyles>
            <div>
                <FullScreenOverlay show={showDialogBox}>
                    <DialogBoxComponent
                        headingColor="var(--text-icons-blue-500)"
                        title="Ready to save"
                        message="You can still change it later. No worries:)"
                        onClickYes={() => {
                            handleUpdateOrganization();
                        }}
                        loading={isLoading}
                        loadingHeading={"SUBSCRIBING.."}
                        onClickNo={() => {
                            setShowDialogBox(false);
                        }}
                        cancelText="No"
                        confirmText="Yes"
                    />
                </FullScreenOverlay>
                <FullScreenOverlay show={isLoading}>
                    <DialogBoxComponent
                        headingColor="var(--text-icons-green-500)"
                        heading="SAVING..."
                        showCancel={false}
                        showConfirm={false}
                        loading={isLoading}
                    />
                </FullScreenOverlay>
                <div
                    className="row"
                    style={{ marginBottom: "40px", display: "flex", alignItems: "center", justifyContent: "right" }}>
                    <div className="col-md-3" style={{ width: "auto" }}>
                        <BtnLargeSolid
                            className="mr-7px"
                            text={"SAVE"}
                            disabled={isValid ? false : true}
                            onClick={() => {
                                setShowDialogBox(true);
                            }}
                        />
                    </div>
                </div>
                <FormContainer title="Account INFO" showDefaultRightLegends={true} numOfFieldsPerLine={4}>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            gap: "30px"
                        }}>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column"
                            }}>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                                <GlobalInput
                                    title="Account owner name"
                                    onChange={() => {}}
                                    disabled={true}
                                    value={OrganizationDetails?.accountOwnerName}
                                />
                                <GlobalInput
                                    title="Last name"
                                    onChange={() => {}}
                                    disabled={true}
                                    value={OrganizationDetails?.lastName}
                                />
                                <GlobalInput
                                    title="Account name"
                                    onChange={e => {
                                        setOrganizationDetails({
                                            ...OrganizationDetails,
                                            accountName: e.target.value
                                        });
                                    }}
                                    disabled={false}
                                    value={OrganizationDetails?.accountName}
                                />
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                    <GlobalInput
                                        title="Email"
                                        onChange={() => {}}
                                        disabled={true}
                                        value={OrganizationDetails?.email}
                                    />
                                    <div
                                        style={{
                                            marginTop: "20px",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            color: "#00bb00",
                                            fontSize: "8px",
                                            fontWeight: "500"
                                        }}>
                                        VERIFIED
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: "16px"
                                }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "4px",
                                            alignItems: "center",
                                            marginBottom: "5px"
                                        }}>
                                        <img src={info} alt="" />
                                        <div style={{ fontSize: "10px" }}>Country</div>
                                    </div>
                                    <CountrySelectDropdown
                                        preEnrollmentVarient={false}
                                        disabled={false}
                                        setState={e => {
                                            setOrganizationDetails({ ...OrganizationDetails, country: e });
                                        }}
                                        value={OrganizationDetails.country}
                                        callback={e => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                country: e.value,
                                                timeZone: `${e?.formattedTimeZone} (${e.timeZone})`,
                                                callingCode: e?.callingCode
                                            });
                                        }}
                                        dataType="country"
                                        preImgSrc={Globe}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "4px",
                                            alignItems: "center",
                                            marginBottom: "5px"
                                        }}>
                                        <img src={info} alt="" />
                                        <div style={{ fontSize: "10px" }}>Time zone</div>
                                    </div>
                                    <CountrySelectDropdown
                                        preEnrollmentVarient={false}
                                        disabled={false}
                                        setState={e => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                timeZone: e
                                            });
                                        }}
                                        value={OrganizationDetails.timeZone}
                                        callback={e => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                timeZone: `${e?.formattedTimeZone} (${e.timeZone})`,
                                                [!OrganizationDetails?.country ? "country" : undefined]:
                                                    !OrganizationDetails?.country ? e?.value : undefined
                                            });
                                        }}
                                        dataType="timezone"
                                        preImgSrc={Clock}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "4px",
                                            alignItems: "center",
                                            marginBottom: "5px"
                                        }}>
                                        <img src={info} alt="" />
                                        <div style={{ fontSize: "10px" }}>Phone number</div>
                                    </div>
                                    <CountrySelectDropdown
                                        preEnrollmentVarient={false}
                                        disabled={false}
                                        setState={e => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                phoneNumber: e
                                            });
                                        }}
                                        value={OrganizationDetails.phoneNumber}
                                        callback={e => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                phoneNumber: "",
                                                callingCode: e?.callingCode
                                            });
                                        }}
                                        callingCode={OrganizationDetails?.callingCode}
                                        dataType={"number"}
                                        type="tel"
                                    />
                                    <div
                                        style={{
                                            marginTop: "3px",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            color: "#FF9900",
                                            fontSize: "8px",
                                            fontWeight: "500"
                                        }}>
                                        NOT VERIFIED
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "4px",
                                            alignItems: "center",
                                            marginBottom: "5px"
                                        }}>
                                        <img src={info} alt="" />
                                        <div style={{ fontSize: "10px" }}>Two factor authentication (2FA)</div>
                                    </div>
                                    <CountrySelectDropdown
                                        preEnrollmentVarient={false}
                                        country={false}
                                        clock={false}
                                        numberDropDown={false}
                                        disabled={true}
                                        value={"Not available"}
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "120px",
                                height: "120px"
                            }}>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "4px",
                                    alignItems: "center",
                                    marginBottom: "5px"
                                }}>
                                <img src={info} alt="" />
                                <div style={{ fontSize: "10px" }}>Profile Photo</div>
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#10141B",
                                    width: "120px",
                                    height: "120px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    border: "1px dashed #3C485A"
                                }}>
                                {OrganizationDetails?.profileFacadeData ? (
                                    <ImagePreview
                                        onCloseClicked={() => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                profileFacadeData: null
                                            });
                                        }}
                                        imageURL={OrganizationDetails?.profileFacadeData}
                                    />
                                ) : (
                                    <div className="frame-197">
                                        <div className="frame-198">
                                            <ImageUploading
                                                onImageUpload={data => handleOnImageUpload("profilePic", data)}
                                                uploadToContainer={BLOB_ACCOUNT_IMAGE}>
                                                <div className="frame-288">
                                                    <img className="upload-012" src={Upload} alt="" />
                                                </div>
                                            </ImageUploading>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </FormContainer>
                <FormContainer title="ACCOUNT LOGO" showDefaultRightLegends={true} numOfFieldsPerLine={4}>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "45%"
                            }}>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "4px",
                                    alignItems: "center",
                                    marginBottom: "5px"
                                }}>
                                <img src={info} alt="" />
                                <div style={{ fontSize: "10px" }}>Main logo</div>
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#10141B",
                                    width: "100%",
                                    height: "300px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    borderLeft: imageStoreInteriorDataBorder ? "#141922" : "5px solid rgb(255, 153, 0)"
                                }}>
                                {OrganizationDetails?.largeLogoFacadeData ? (
                                    <ImagePreview
                                        imageURL={OrganizationDetails?.largeLogoFacadeData}
                                        onCloseClicked={() => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                largeLogoFacadeData: null
                                            });
                                            setImageInteriorDataBorder(false);
                                        }}
                                    />
                                ) : (
                                    <div className="frame-197">
                                        <div className="frame-198">
                                            <ImageUploading
                                                onImageUpload={data => {
                                                    handleOnImageUpload("storeInterior", data);
                                                }}
                                                uploadToContainer={BLOB_ACCOUNT_IMAGE}>
                                                <div className="frame-288">
                                                    <img className="upload-012" src={Upload} alt="" />
                                                    <div className="frame-199">
                                                        <div className="browse-files-from-device">
                                                            Browse files from device
                                                        </div>
                                                        <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                            Logo size should be 16:9 in aspect ratio
                                                            <br />
                                                            Max file size accepted is 300kb
                                                        </div>
                                                    </div>
                                                </div>
                                            </ImageUploading>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "25%"
                            }}>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "4px",
                                    alignItems: "center",
                                    marginBottom: "5px"
                                }}>
                                <img src={info} alt="" />
                                <div style={{ fontSize: "10px" }}>Compact logo</div>
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#10141B",
                                    width: "300px",
                                    height: "300px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    borderLeft: imageStoreFacadeDataBorder ? "#141922" : "5px solid #00AEEF"
                                }}>
                                {OrganizationDetails?.smallLogoFacadeData ? (
                                    <ImagePreview
                                        imageURL={OrganizationDetails?.smallLogoFacadeData}
                                        onCloseClicked={() => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                smallLogoFacadeData: null
                                            });
                                            setImageStoreFacadeDataBorder(false);
                                        }}
                                    />
                                ) : (
                                    <div className="frame-197">
                                        <div className="frame-198">
                                            <ImageUploading
                                                onImageUpload={data => handleOnImageUpload("storeFacade", data)}
                                                uploadToContainer={BLOB_ACCOUNT_IMAGE}>
                                                <div className="frame-288">
                                                    <img className="upload-012" src={Upload} alt="" />
                                                    <div className="frame-199">
                                                        <div className="browse-files-from-device">
                                                            Browse files from device
                                                        </div>
                                                        <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                            Logo size should be 1:1 in aspect ratio
                                                            <br />
                                                            Max file size accepted is 300kb
                                                        </div>
                                                    </div>
                                                </div>
                                            </ImageUploading>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </FormContainer>
            </div>
        </OrganizationAddPageStyles>
    );
};

export default OrganizationAddPage;

const ImagePreview = ({ imageURL, onCloseClicked, imageBase64 }) => {
    let imageSource = imageURL;
    if (!imageURL && imageBase64)
        imageSource = imageBase64.startsWith("data:") ? imageBase64 : `data:image;base64,${imageBase64}`;

    return (
        <StyledImagePreview className="upload-card" style={{ width: "100%", height: "100%" }}>
            <div
                className="frame-197"
                style={{
                    background: `url(${imageSource}) center / cover no-repeat`
                }}>
                {onCloseClicked && (
                    <div className="x-close-container">
                        <div className="ellipse-5"></div>
                        <img className="x-close" src={XClose0} alt="" onClick={onCloseClicked} />
                    </div>
                )}
            </div>
        </StyledImagePreview>
    );
};

const setupHeaders = (props, location) => {
    props.setControlBarCenterBtns([
        { to: "", active: true, disabled: false, title: "ACCOUNT" },
        { to: "", active: false, disabled: true, title: "USERS" }
    ]);

    const createMode = props?.navigations?.navigationAccesses?.organization?.createModeOnly;
    if (location.pathname === "/account/edit") {
        props.setControlBarRightBtns([
            createMode || createMode === undefined
                ? { to: "", active: true, disabled: false, title: "Add", icon: "plus" }
                : { to: "/account", active: false, disabled: false, title: "Details", icon: "microscope" }
        ]);
    }

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: true,
        listEnabled: true,
        onRefreshAction: undefined
    });
};
