import React, { useEffect, useRef, useState } from "react";
import { APP_TITLE } from "../../../../config/constant";
import { useOutletContext } from "react-router";
import styled from "styled-components";
import FormCard from "../../../common/forms/FormCard";
import BtnLargeSolid from "../../../common/buttons/BtnLargeSolid";
import FormField from "../../../common/forms/FormField";
import * as Library from "../../../../utils/Library";

const StyledPage = styled.div``;

export default function FormsTemplatePage() {
    /* #region DEFAULT PAGE SETTINGS */
    document.title = `${APP_TITLE} - Forms Templates`;

    // VARS
    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };

    const [, setViewLayoutStyle] = useState("Grid");
    // END VARS

    // EFFECTS
    useEffect(() => {
        setViewLayoutStyle(props?.layoutType);
        setupHeaders(props);
    }, [props?.layoutType]);
    // END EFFECTS
    /* #endregion */

    /* #region TEMPLATE VARS */
    const formCardRef = useRef();
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

    const formFields1Refs = []; //TODO: CHECK IF WE CAN MAKE FORM FIELDS REFS DYNAMIC

    const FormCardFields1 = [
        <FormField
            type="text"
            title="First Name"
            tooltip="Enter First Name"
            placeholder="Enter First Name"
            requiredType="mandatory"
            assistiveText="Assistive Text"
            varName="firstName"
            ref={el => (formFields1Refs.firstName = el)}
        />,
        <FormField
            type="text"
            title="Last Name"
            placeholder="Enter Last Name"
            requiredType="recommended"
            assistiveText="Assistive Text"
            varName="lastName"
            ref={el => (formFields1Refs.lastName = el)}
        />,
        <FormField
            type="text"
            title="Email"
            placeholder="Enter Email"
            requiredType="locked"
            value="test@botag.no"
            leftContent={<span className="fa fa-envelope"></span>}
            assistiveText="Assistive Text"
            varName="email"
            ref={el => (formFields1Refs.email = el)}
        />,
        <FormField
            type="phoneNumber"
            title="Phone"
            phoneNumberCountryCode="+351"
            placeholder="Enter Phone"
            requiredType="mandatory"
            assistiveText="Assistive Text"
            varName="phone"
            ref={el => (formFields1Refs.phone = el)}
        />,
        <FormField
            type="text"
            title="Length between 5 and 10"
            varName="lengthtest"
            ref={el => (formFields1Refs.lengthtest = el)}
            minLength={5}
            maxLength={10}
        />,
        <FormField
            type="date"
            title="Date example"
            leftContent={<span className="fa fa-calendar"></span>}
            varName="date"
            ref={el => (formFields1Refs.date = el)}
        />,
        <FormField
            type="datetime-local"
            title="DateTime example"
            leftContent={<span className="fa fa-calendar"></span>}
            varName="datetime"
            ref={el => (formFields1Refs.datetime = el)}
        />,
        <FormField
            type="taxNumber"
            title="Tax example"
            leftContent="NO"
            rightContent="MVA"
            taxNumberCountryCode="NO"
            varName="tax"
            ref={el => (formFields1Refs.tax = el)}
        />,
        <FormField
            type="droppable"
            title="Droppable"
            varName="droppable"
            ref={el => (formFields1Refs.droppable = el)}
        />,
        <FormField
            type="droppable"
            title="Dragged"
            requiredType="mandatory"
            value="Dropped field"
            varName="dragged"
            ref={el => (formFields1Refs.dragged = el)}
        />,
        <FormField
            type="text"
            numberOfLines={3}
            title="Multi-line example"
            varName="multiline"
            ref={el => (formFields1Refs.multiline = el)}
        />,
        <FormField
            type="tags"
            title="Tags"
            requiredType="recommended"
            tags={[{ tagName: "Tag 1" }, { tagName: "Tag 2" }]}
            varName="tags"
            ref={el => (formFields1Refs.tags = el)}
        />,
        <FormField
            type="dropdown"
            title="Dropdown single item"
            dropdownData={[
                { value: "PT", name: "Portugal" },
                { value: "ES", name: "Spain" },
                { value: "FR", name: "France" },
                { value: "DE", name: "Germany" }
            ]}
            varName="dropdownSingleItem"
            isDropdownMultiselect={false}
            isDropdownSearchable={true}
            ref={el => (formFields1Refs.dropdownSingleItem = el)}
        />
    ];

    /*<FormField
        type="dropdown"
        title="Dropdown multi items"
        dropdownData={[
            { value: "1", name: "Portugal", selected: true },
            { value: "2", name: "Spain" },
            { value: "3", name: "France", selected: true },
            { value: "4", name: "Germany" },
            { value: "5", name: "Italy", selected: true }
        ]}
        varName="dropdownMultiItem"
        isDropdownMultiselect={true}
        isDropdownSearchable={true}
        ref={el => (formFields1Refs.dropdownMultiItem = el)}
    /> */

    /* #endregion */

    /* #region TEMPLATE METHODS */

    const checkFormIsValid = () => {
        const allFieldsAreValid = formCardRef.current.allFieldsAreValid(false); // false -> don't highlight invalid fields
        setSaveButtonEnabled(allFieldsAreValid);
        return allFieldsAreValid;
    };

    const handleSaveClicked = () => {
        if (!formCardRef) {
            Library.showErrorMessage("FormCard references not found.");
            return;
        }

        // Check if all fields are valid
        const allFieldsAreValid = formCardRef.current.allFieldsAreValid(true); // true -> highlight invalid fields
        if (!allFieldsAreValid) {
            Library.showErrorMessage("Please fill in all mandatory fields.");
            return;
        }

        // Get all field values
        const fieldValues = formCardRef.current.getAllFieldValues();
        console.log("fieldValues:", fieldValues);

        // DO SOMETHING WITH THE FIELD VALUES
        Library.showSuccessMessage("Form saved successfully.");
    };
    /* #endregion */

    return (
        <StyledPage>
            <div className="flex justify-end" style={{ textAlign: "right", marginBottom: "40px" }}>
                <BtnLargeSolid
                    type="submit"
                    onClick={handleSaveClicked}
                    className=""
                    disabled={!saveButtonEnabled}
                    text="SAVE"
                />
            </div>

            <FormCard
                ref={formCardRef}
                title="Forms Template"
                numOfFieldsPerLine={4}
                showDefaultRightLegends={true}
                formFields={FormCardFields1}
                formFieldRefs={formFields1Refs}
                onFormChanged={checkFormIsValid}
            />
        </StyledPage>
    );
}

/* #region DEFAULT PAGE CONFIG */
function setupHeaders(props) {
    props.setControlBarLeftBtns([]);
    props.setControlBarCenterBtns([{ to: "#", active: true, disabled: false, title: "Forms" }]);
    props.setControlBarRightBtns([]);

    props.setFilterHeaderProperties({
        visible: false,
        gridEnabled: true,
        listEnabled: false,
        onRefreshAction: undefined
    });
}
/* #endregion */
