import FormContainer from "../../common/forms/FormContainer";
import info from "../../../assets/icons/info-circle-white.svg";
import ImageUploading from "../../common/forms/ImageUploading";
import Upload from "../../../assets/icons/upload-010.svg";
import XClose0 from "../../../assets/icons/x-close0.svg";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Globe01 from "../../../assets/icons/globe-01.svg";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import * as Library from "../../../utils/Library";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import { useDispatch, useSelector } from "react-redux";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import LayoutContext from "../../../LayoutContext";
import { APP_TITLE } from "../../../config/constant";
import FormCard from "../../common/forms/FormCard";
import FormField from "../../common/forms/FormField";
import { getDropdownData } from "../../../utils/dropdownData";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";

/* #region SCOPED CSS */
const OrganizationAddPageStyles = styled.div`
    .frame-288 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .frame-199 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        text-align: center;
    }
    .frame-197 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .browse-files-from-device {
        color: var(--text-and-icons-gray-100, #d1d1d1);
        text-align: center;
        font-size: var(--subtitle-2-font-size, 12px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: center;
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
`;
const StyledImagePreview = styled.div`
    .upload-card,
    .upload-card * {
        box-sizing: border-box;
    }
    .upload-card {
        display: flex;
        flex-direction: column;
        gap: 4px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-51 {
        padding: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-circle {
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .store-facade {
        color: var(--text-and-icons-gray-200, #7c868b);
        text-align: left;
        font-family: var(--subtitle-3-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--subtitle-3-font-size, 10px);
        font-weight: var(--subtitle-3-font-weight, 400);
        position: relative;
    }
    .frame-197 {
        border-radius: 4px;
        border-style: solid;
        border-color: var(--backgrounds-and-lines-n-500, #141922);
        border-width: 0px 0px 0px 5px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 100%;
        position: relative;
    }
    .x-close-container {
        width: 6.667px;
        height: 6.667px;
        flex-shrink: 0;
        position: static;
        z-index: 8888;
    }
    .ellipse-5 {
        background: var(--text-and-icons-red-500, #ff3636);
        border-radius: 50%;
        width: 14px;
        height: 14px;
        position: absolute;
        //left: 766px;
        right: 12px;
        top: 12px;
    }
    .x-close {
        width: 12px;
        height: 12px;
        position: absolute;
        //left: 770px;
        right: 13px;
        top: 13px;
        overflow: visible;
        z-index: 8888;
        cursor: pointer;
    }
`;
/* #endregion */

const AccountEditPage = () => {
    const location = useLocation();
    document.title = `${APP_TITLE} - Account ${location.pathname === "/account/create" ? "Create" : "Edit"}`;

    /* #region vars  */
    const formCardRef = useRef();
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { setShowIsAccountSavedPopup } = React.useContext(LayoutContext);
    const navigate = useNavigate();
    const [imageStoreFacadeDataBorder, setImageStoreFacadeDataBorder] = useState(false);
    const [imageStoreInteriorDataBorder, setImageInteriorDataBorder] = useState(false);
    const [OrganizationDetails, setOrganizationDetails] = useState({
        country: "",
        timeZone: "",
        phoneNumber: "",
        callingCode: "",
        accountOwnerName: localStorage.getItem("userFirstName"),
        lastName: localStorage.getItem("userLastName"),
        accountName: "",
        email: localStorage.getItem("userEmail"),
        profileFacadeData: null,
        largeLogoFacadeData: null,
        smallLogoFacadeData: null
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showDialogBox, setShowDialogBox] = useState(false);
    const navigations = useSelector(state => state.navigationAccesses);
    const userAppData = useSelector(state => state.userData.userAppData);
    const dispatch = useDispatch();

    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };

    const [isEditPage] = useState(location.pathname === "/account/edit");
    const [countryOptions] = useState(getDropdownData("countryOptions"));
    const [timeZoneOptions] = useState(getDropdownData("timeZoneOptions"));
    /* #endregion */

    /* #region methods */
    const handleUpdateOrganization = fieldValues => {
        const formData = {
            organizationID: localStorage.getItem("currentOrganizationID"),
            BOstagesAccountID: localStorage.getItem("BOstagesAccountID"),
            organizationName: fieldValues?.accountName,
            countryID: fieldValues?.country?.value,
            timeZoneID: fieldValues?.timeZone?.name,
            phoneNumberCountryID: fieldValues?.phoneNumber?.split(" ")[0]?.replace("+", ""),
            PhoneNumber: fieldValues?.phoneNumber?.split(" ")[1],
            profilePhotoData: profileFacadeData,
            mainLogoData: largeLogoFacadeData,
            compactLogoData: smallLogoFacadeData
        };

        Library.makeCommonPostRequest("Organization", "updateOrganization", formData, false, setIsLoading).then(res => {
            if (res?.data?.status === 200) {
                const updatedNavigations = {
                    ...navigations.navigationAccesses,
                    organization: {
                        ...navigations.navigationAccesses.organization,
                        createModeOnly: false
                    },
                    companies: {
                        ...navigations.navigationAccesses.companies,
                        navigationPanel: true,
                        createModeOnly: true
                    }
                };
                dispatch(setNavigations({ navigationAccesses: updatedNavigations }));
                localStorage.setItem("currentOrganizationName", fieldValues?.accountName);
                if (res?.data?.data?.mainImageUrl) {
                    localStorage.setItem("currentOrganizationLogo", res?.data?.data?.mainImageUrl);
                }

                if (!isEditPage) {
                    navigate("/companies/create");
                    setShowIsAccountSavedPopup(true);
                } else {
                    setShowDialogBox(false);
                    //navigate("/account");
                }
            }
        });
    };

    const handleOnImageUpload = (imageType, data) => {
        if (imageType === "storeFacade") {
            setOrganizationDetails({
                ...OrganizationDetails,
                smallLogoFacadeData: data
            });
            setImageStoreFacadeDataBorder(true);
        } else if (imageType === "storeInterior") {
            setOrganizationDetails({
                ...OrganizationDetails,
                largeLogoFacadeData: data
            });
            setImageInteriorDataBorder(true);
        } else if (imageType === "profilePic") {
            setOrganizationDetails({
                ...OrganizationDetails,
                profileFacadeData: data
            });
        }
    };

    const selectTimezoneAndPhoneNumber = data => {
        const countryId = data?.id;
        const countryName = data?.name;

        const timeZoneOptions = getDropdownData("timeZoneOptions");
        const phoneCountryCodes = getDropdownData("countryCodeOptions");

        const selectedTimeZone = timeZoneOptions.find(timezone => timezone.id === countryId);
        const selectedPhoneCountryCode = phoneCountryCodes.find(phoneCC => phoneCC.id === countryId);

        const selectedTimeZoneValue = selectedTimeZone?.name;
        const selectedPhoneCountryCodeValue = selectedPhoneCountryCode?.value;

        setOrganizationDetails({
            ...OrganizationDetails,
            country: countryName,
            timeZone: selectedTimeZoneValue,
            callingCode: selectedPhoneCountryCodeValue
        });
    };

    const checkFormIsValid = () => {
        const allFieldsAreValid = formCardRef.current.allFieldsAreValid(false); // false -> don't highlight invalid fields

        const mainLogoIsValid = OrganizationDetails?.largeLogoFacadeData ? true : false;
        //const profilePhotoIsValid = OrganizationDetails?.profileFacadeData ? true : false;

        setSaveButtonEnabled(allFieldsAreValid && mainLogoIsValid);
        return allFieldsAreValid;
    };

    const handleSaveClicked = () => {
        if (!formCardRef) {
            Library.showErrorMessage("FormCard references not found.");
            return;
        }

        // Check if all fields are valid
        const allFieldsAreValid = formCardRef.current.allFieldsAreValid(true); // true -> highlight invalid fields
        if (!allFieldsAreValid) {
            Library.showErrorMessage("Please fill in all mandatory fields.");
            return;
        }

        // Get all field values
        const fieldValues = formCardRef.current.getAllFieldValues();

        handleUpdateOrganization(fieldValues);
    };
    /* #endregion */

    /* #region form fields */
    const { profileFacadeData, largeLogoFacadeData, smallLogoFacadeData } = OrganizationDetails;

    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const formFields1Refs = []; //TODO: CHECK IF WE CAN MAKE FORM FIELDS REFS DYNAMIC
    const FormCardFields1 = [
        <FormField
            type="text"
            title="Account owner name"
            tooltip="Enter account owner name"
            requiredType="locked"
            varName="accountOwnerName"
            value={OrganizationDetails?.accountOwnerName}
            ref={el => (formFields1Refs.accountOwnerName = el)}
        />,
        <FormField
            type="text"
            title="Last name"
            tooltip="Enter last name"
            requiredType="locked"
            varName="lastName"
            value={OrganizationDetails?.lastName}
            ref={el => (formFields1Refs.lastName = el)}
        />,
        <FormField
            type="text"
            title="Account name"
            tooltip="Enter account name"
            requiredType="mandatory"
            varName="accountName"
            value={OrganizationDetails?.accountName}
            ref={el => (formFields1Refs.accountName = el)}
        />,
        <FormField
            type="email"
            title="Email"
            tooltip="Enter email"
            requiredType="locked"
            assistiveText="VERIFIED"
            assistiveTextColorOverride="#00bb00"
            value={OrganizationDetails?.email}
            varName="email"
            ref={el => (formFields1Refs.email = el)}
        />,
        <FormField
            type="dropdown"
            dropdownData={countryOptions}
            title="Country"
            isDropdownSearchable={true}
            tooltip="Select country"
            requiredType="mandatory"
            varName="country"
            leftContent={<img src={Globe01} alt="" />}
            value={OrganizationDetails.country}
            ref={el => (formFields1Refs.country = el)}
            onDropdownChange={selectTimezoneAndPhoneNumber}
        />,
        <FormField
            type="dropdown"
            dropdownData={timeZoneOptions}
            title="Time zone"
            tooltip="Select time zone"
            requiredType="mandatory"
            varName="timeZone"
            value={OrganizationDetails.timeZone}
            ref={el => (formFields1Refs.timeZone = el)}
        />,
        <FormField
            type="phoneNumber"
            title="Phone number"
            tooltip="Enter phone number"
            requiredType="mandatory"
            varName="phoneNumber"
            assistiveText="NOT VERIFIED"
            assistiveTextColorOverride="#FF9900"
            ref={el => (formFields1Refs.phoneNumber = el)}
            value={OrganizationDetails?.phoneNumber}
            phoneNumberCountryCode={OrganizationDetails?.callingCode}
        />,
        <FormField
            type="text"
            title="Two factor authentication (2FA)"
            tooltip="Two factor authentication (2FA) is not available yet"
            requiredType="locked"
            varName="twoFactorAuthentication"
            placeholder="Not available"
            ref={el => (formFields1Refs.twoFactorAuthentication = el)}
        />
    ];
    /* #endregion */

    /* #region effects */
    useEffect(() => {
        checkFormIsValid();
    }, [OrganizationDetails]);

    useEffect(() => {
        setupHeaders(props, location, saveButtonEnabled, setShowDialogBox);
    }, [props?.layoutType, saveButtonEnabled]);

    useEffect(() => {
        if (!userAppData) return;

        // ON PAGE LOAD - IF IS CREATE PAGE, SET COUNTRY, TIMEZONE, PHONE NUMBER COUNTRY CODE BASED ON USER DATA
        if (!isEditPage) {
            const countryValue = userAppData?.companies?.[0]?.countryValue;
            if (!countryValue) return;
            const countrySelectedID = countryOptions.find(country => country.value === countryValue)?.id;
            selectTimezoneAndPhoneNumber({ id: countrySelectedID, name: countryValue });
        }
    }, [userAppData]);

    useEffect(() => {
        if (isEditPage) {
            // FETCH ORGANIZATION DETAILS
            Library.makeCommonPostRequest(
                "Organization",
                "getOrganizationAccount",
                {
                    id: localStorage.getItem("token")
                },
                false,
                setIsLoading
            ).then(res => {
                if (res?.data?.status === 200) {
                    const organizationDetails = res?.data?.data?.[0];
                    const OwnerAccount = organizationDetails?.OrganizationOwnerAccount?.BOstagesAccountID;
                    const OrganizationInfo = organizationDetails?.OrganizationInfo;

                    const orgTemp = {
                        accountOwnerName: OwnerAccount?.firstName,
                        lastName: OwnerAccount?.lastName,
                        accountName: OrganizationInfo?.name,
                        email: OwnerAccount?.email,
                        country: OrganizationInfo?.countryValue,
                        timeZone: OrganizationInfo?.timeZoneValue,
                        phoneNumber: `${OwnerAccount?.phoneNumber}`,
                        callingCode: OwnerAccount?.phoneNumberCountryCode,
                        profileFacadeData: OwnerAccount?.profilePhotoUrl
                            ? { url: OwnerAccount?.profilePhotoUrl }
                            : null,
                        largeLogoFacadeData: OrganizationInfo?.mainImageUrl
                            ? { url: OrganizationInfo?.mainImageUrl }
                            : null,
                        smallLogoFacadeData: OrganizationInfo?.squareImageUrl
                            ? { url: OrganizationInfo?.squareImageUrl }
                            : null
                    };
                    setOrganizationDetails(orgTemp);
                } else {
                    Library.showErrorMessage("Failed to fetch organization details.");
                }
            });
        }
    }, [isEditPage]);
    /* #endregion */

    return (
        <OrganizationAddPageStyles>
            <div>
                <FullScreenOverlay show={showDialogBox}>
                    <DialogBoxComponent
                        headingColor="var(--text-icons-blue-500)"
                        title="Ready to save"
                        message="You can still change it later. No worries:)"
                        onClickYes={() => {
                            handleSaveClicked();
                        }}
                        loading={isLoading}
                        loadingHeading={"SUBSCRIBING.."}
                        onClickNo={() => {
                            setShowDialogBox(false);
                        }}
                        cancelText="No"
                        confirmText="Yes"
                    />
                </FullScreenOverlay>

                <FullScreenOverlay show={isLoading}>
                    <DialogBoxComponent
                        headingColor="var(--text-icons-green-500)"
                        heading="SAVING..."
                        showCancel={false}
                        showConfirm={false}
                        loading={isLoading}
                    />
                </FullScreenOverlay>

                <FormCard
                    ref={formCardRef}
                    title="Account INFO"
                    numOfFieldsPerLine={4}
                    showDefaultRightLegends={true}
                    formFields={FormCardFields1}
                    formFieldRefs={formFields1Refs}
                    onFormChanged={checkFormIsValid}
                    rightContent={ProfilePhotoComponent({
                        OrganizationDetails,
                        setOrganizationDetails,
                        handleOnImageUpload
                    })}
                />

                <FormContainer title="ACCOUNT LOGO" showDefaultRightLegends={true} numOfFieldsPerLine={4}>
                    <div style={{ display: "flex", gap: "16px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                //width: "45%",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                flex: "1",
                                position: "relative"
                            }}>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "4px",
                                    alignItems: "center",
                                    marginBottom: "5px"
                                }}>
                                <img src={info} alt="" />
                                <div style={{ fontSize: "12px" }}>Main logo</div>
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#10141B",
                                    width: "100%",
                                    height: "259px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    borderLeft: imageStoreInteriorDataBorder ? "#141922" : "5px solid rgb(255, 153, 0)"
                                }}>
                                {OrganizationDetails?.largeLogoFacadeData ? (
                                    <ImagePreview
                                        //imageURL={OrganizationDetails?.largeLogoFacadeData}
                                        imageBase64={OrganizationDetails?.largeLogoFacadeData?.base64}
                                        imageURL={OrganizationDetails?.largeLogoFacadeData?.url}
                                        onCloseClicked={() => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                largeLogoFacadeData: null
                                            });
                                            setImageInteriorDataBorder(false);
                                        }}
                                        imgHasPadding={true}
                                        objectFit="contain"
                                    />
                                ) : (
                                    <div className="frame-197">
                                        <ImageUploading
                                            onImageUpload={data => {
                                                handleOnImageUpload("storeInterior", data);
                                            }}>
                                            <div className="frame-288">
                                                <img className="upload-012" src={Upload} alt="" />
                                                <div className="frame-199">
                                                    <div className="browse-files-from-device">
                                                        Browse files from device
                                                    </div>
                                                    <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                        Logo size should be 16:9 in aspect ratio
                                                        <br />
                                                        Max file size accepted is 300kb
                                                    </div>
                                                </div>
                                            </div>
                                        </ImageUploading>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                //width: "25%"
                                alignItems: "flex-start",
                                justifyContent: "center",
                                flex: "1",
                                position: "relative"
                            }}>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "4px",
                                    alignItems: "center",
                                    marginBottom: "5px"
                                }}>
                                <img src={info} alt="" />
                                <div style={{ fontSize: "12px" }}>Compact logo</div>
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#10141B",
                                    width: "259px",
                                    height: "259px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    borderLeft: imageStoreFacadeDataBorder ? "#141922" : "5px solid #00AEEF"
                                }}>
                                {OrganizationDetails?.smallLogoFacadeData ? (
                                    <ImagePreview
                                        //imageURL={OrganizationDetails?.smallLogoFacadeData}
                                        imageBase64={OrganizationDetails?.smallLogoFacadeData?.base64}
                                        imageURL={OrganizationDetails?.smallLogoFacadeData?.url}
                                        onCloseClicked={() => {
                                            setOrganizationDetails({
                                                ...OrganizationDetails,
                                                smallLogoFacadeData: null
                                            });
                                            setImageStoreFacadeDataBorder(false);
                                        }}
                                        imgHasPadding={true}
                                        objectFit="contain"
                                    />
                                ) : (
                                    <div className="frame-197">
                                        <ImageUploading
                                            onImageUpload={data => handleOnImageUpload("storeFacade", data)}>
                                            <div className="frame-288">
                                                <img className="upload-012" src={Upload} alt="" />
                                                <div className="frame-199">
                                                    <div className="browse-files-from-device">
                                                        Browse files from device
                                                    </div>
                                                    <div className="logo-size-should-be-16-9-in-aspect-ratio-max-file-size-accepted-is-300-kb">
                                                        Logo size should be 1:1 in aspect ratio
                                                        <br />
                                                        Max file size accepted is 300kb
                                                    </div>
                                                </div>
                                            </div>
                                        </ImageUploading>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </FormContainer>
            </div>
        </OrganizationAddPageStyles>
    );
};

export default AccountEditPage;

const ImagePreview = ({ imageURL, onCloseClicked, imageBase64, imgHasPadding = false, objectFit = "cover" }) => {
    let imageSource = imageURL;
    if (!imageURL && imageBase64)
        imageSource = imageBase64.startsWith("data:") ? imageBase64 : `data:image;base64,${imageBase64}`;

    return (
        <StyledImagePreview className="upload-card" style={{ width: "100%", height: "100%", position: "relative" }}>
            <img
                className="frame-197"
                style={{
                    //background: `url(${imageSource}) center / cover no-repeat`,
                    border: "0px",
                    minWidth: "0px",
                    maxWidth: "100%",
                    objectFit: objectFit,
                    padding: imgHasPadding ? "30px" : "0px"
                }}
                alt=""
                src={imageSource}></img>
            {onCloseClicked && (
                <div className="x-close-container">
                    <div className="ellipse-5"></div>
                    <img className="x-close" src={XClose0} alt="" onClick={onCloseClicked} />
                </div>
            )}
        </StyledImagePreview>
    );
};

/* #region small components */
const ProfilePhotoComponent = props => {
    const { OrganizationDetails, setOrganizationDetails, handleOnImageUpload } = props;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "120px"
            }}>
            <div
                style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    marginBottom: "5px"
                }}>
                <img src={info} alt="" />
                <div style={{ fontSize: "10px" }}>Profile Photo</div>
            </div>
            <div
                style={{
                    backgroundColor: "#10141B",
                    width: "120px",
                    height: "126px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    border: "1px dashed #3C485A"
                }}>
                {OrganizationDetails?.profileFacadeData ? (
                    <ImagePreview
                        onCloseClicked={() => {
                            setOrganizationDetails({
                                ...OrganizationDetails,
                                profileFacadeData: null
                            });
                        }}
                        //imageURL={OrganizationDetails?.profileFacadeData}
                        imageBase64={OrganizationDetails?.profileFacadeData?.base64}
                        imageURL={OrganizationDetails?.profileFacadeData?.url}
                    />
                ) : (
                    <div className="frame-197">
                        <ImageUploading onImageUpload={data => handleOnImageUpload("profilePic", data)}>
                            <div className="frame-288">
                                <img className="upload-012" src={Upload} alt="" />
                            </div>
                        </ImageUploading>
                    </div>
                )}
            </div>
        </div>
    );
};
/* #endregion */

const setupHeaders = (props, location, saveButtonEnabled, setShowDialogBox) => {
    props.setControlBarCenterBtns([
        { to: "", active: true, disabled: false, title: "ACCOUNT" },
        { to: "", active: false, disabled: true, title: "USERS" }
    ]);

    const createMode = location.pathname === "/account/create";
    props.setControlBarRightBtns([
        createMode
            ? { to: "", active: true, disabled: false, title: "Add", icon: "plus" }
            : { to: "/account", active: false, disabled: false, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader
                btnTitle="SAVE"
                btnEnabled={saveButtonEnabled}
                onClick={() => {
                    setShowDialogBox(true);
                }}
            />
        )
    });
};
