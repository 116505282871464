import React, { useState } from "react";

const LayoutContext = React.createContext();

export const LayoutProvider = ({ children }) => {
    // LOADINGS STATES
    const [isLoadingAccountPage, setIsLoadingAccountPage] = useState(false);

    // POPUPS STATES
    const [showIsSubscribedPopup, setShowIsSubscribedPopup] = useState(false);
    const [showIsAccountSavedPopup, setShowIsAccountSavedPopup] = useState(false);
    const [showIsBrandSavedPopup, setShowIsBrandSavedPopup] = useState(false);
    const [showIsCompanySavedPopup, setShowIsCompanySavedPopup] = useState(false);
    const [readyToSavePopup, setReadyToSavePopup] = useState(false);

    return (
        <LayoutContext.Provider
            value={{
                isLoadingAccountPage,
                setIsLoadingAccountPage,
                showIsSubscribedPopup,
                setShowIsSubscribedPopup,
                showIsAccountSavedPopup,
                setShowIsAccountSavedPopup,
                showIsBrandSavedPopup,
                setShowIsBrandSavedPopup,
                showIsCompanySavedPopup,
                setShowIsCompanySavedPopup,
                readyToSavePopup,
                setReadyToSavePopup
            }}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutContext;
