import Announcement01 from "@untitled-ui/icons-react/build/esm/Announcement01";
import Box from "@untitled-ui/icons-react/build/esm/Box";
import Building07 from "@untitled-ui/icons-react/build/esm/Building07";
import Speedometer04 from "@untitled-ui/icons-react/build/esm/Speedometer04";
import Tag03 from "@untitled-ui/icons-react/build/esm/Tag03";
import Dataflow01 from "@untitled-ui/icons-react/build/esm/Dataflow01";
import UserSquare from "@untitled-ui/icons-react/build/esm/UserSquare";
import { ReactComponent as FFCIcon } from "../assets/icons/ffcenter-icon.svg";
import { ReactComponent as Shop1 } from "../assets/icons/shop-1.svg";
import { ReactComponent as Shirt } from "../assets/icons/tshirt-linear.svg";
import { ReactComponent as id } from "../assets/icons/id.svg";

export const navigationBar = (path, navigations) => {
    const navigation = navigations?.navigationAccesses;

    //most of the routes are not ready "temp" are the not ready routes
    return [
        {
            to: "/dashboard",
            enabled: navigation?.dashboard?.navigationPanel,
            title: "Dashboard",
            iconSVG: Speedometer04,
            isActive: path.toLocaleLowerCase().includes("/dashboard")
        },
        {
            to: navigation?.BOimages?.createModeOnly ? "/boimages/create" : "/boimages",
            enabled: navigation?.BOimages?.navigationPanel,
            title: "BOimages",
            iconSVG: UserSquare,
            isActive: path.toLocaleLowerCase().includes("/boimages")
        },
        {
            to: navigation?.fulfillmentCenters?.createModeOnly ? "/account" : "/ffcenters", //temp
            enabled: navigation?.fulfillmentCenters?.navigationPanel,
            title: "Fulfillment Centers",
            iconSVG: FFCIcon,
            isActive: path.toLocaleLowerCase().includes("/ffcenters")
        },
        {
            to: navigation?.stores?.createModeOnly ? "/stores/create" : "/stores",
            enabled: navigation?.stores?.navigationPanel,
            title: "Stores",
            iconSVG: Shop1,
            isActive: path.toLocaleLowerCase().includes("/stores")
        },
        {
            to: "/retailers", //temp
            enabled: navigation?.retailers?.navigationPanel,
            title: "Retailers",
            iconSVG: Dataflow01,
            isActive: path.toLocaleLowerCase().includes("/retailers")
        },
        {
            to: navigation?.orders?.createModeOnly ? "/account" : "/orders", //temp
            enabled: navigation?.orders?.navigationPanel,
            title: "Orders",
            iconSVG: Box,
            isActive: path.toLocaleLowerCase().includes("/orders")
        },
        {
            to: navigation?.products?.createModeOnly ? "/account" : "/products", //temp
            enabled: navigation?.products?.navigationPanel,
            title: "Products",
            iconSVG: Shirt,
            isActive: path.toLocaleLowerCase().includes("/products")
        },
        {
            to: navigation?.labelBrands?.createModeOnly ? "/brands/create" : "/brands", //done
            enabled: navigation?.labelBrands?.navigationPanel,
            title: "Brands",
            iconSVG: Tag03,
            isActive: path.toLocaleLowerCase().includes("/brands")
        },

        {
            to: navigation?.promoters?.createModeOnly ? "/account" : "/account", //temp
            enabled: navigation?.promoters?.navigationPanel,
            title: "Promoters",
            iconSVG: Announcement01,
            isActive: path.toLocaleLowerCase().includes("/promoters")
        },
        {
            to: navigation?.companies?.createModeOnly ? "/companies/create" : "/companies", //done
            enabled: navigation?.companies?.navigationPanel,
            title: "Companies",
            iconSVG: Building07,
            isActive: path.toLocaleLowerCase().includes("/companies")
        },
        {
            to: navigation?.organization?.createModeOnly ? "/account/create" : "/account",
            enabled: navigation?.organization?.navigationPanel,
            title: "Account",
            iconSVG: id,
            isActive: path.toLocaleLowerCase().includes("/account")
        }
    ];
};
