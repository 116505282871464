import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import TagComponent from "./TagComponent";
import { v4 as uuidv4 } from "uuid";
import DropdownOverlay, { OverlayType } from "../dropdownOverlay/DropdownOverlay";
import { getDropdownData } from "../../../utils/dropdownData";
import Mail05Icon from "../../../assets/icons/mail-05.svg";

const StyledElems = styled.div`
    & {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .FormComponentContainer {
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        display: inline-flex;
        position: relative;
    }

    .Frame51 {
        align-self: stretch;
        padding-left: 5px;
        padding-right: 5px;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        display: inline-flex;
    }

    .Icon {
        width: 10px;
        height: 10px;
        font-size: 9px;
        font-weight: 500;
        color: var(--text-icons-gray-200);
        position: relative;
        top: -1px;
    }

    .Title {
        color: var(--text-icons-gray-200);
        font-size: 0.75rem;
        font-family: Roboto;
        font-weight: 400;
        word-wrap: break-word;
    }

    .InputSelectorInverted {
        align-self: stretch;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
    }

    .Frame49 {
        width: 5px;
        height: 37px;
        padding-top: 6px;
        padding-bottom: 6px;
        background: var(--text-icons-orange-500);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .Search {
        flex: 1 1 0;
        height: 38px;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;
    }

    .Frame48 {
        width: 100%;
        flex: 1 1 0;
        height: 37px;
        background: #10141b;
        border-radius: 4px;
        background: var(--backgrounds-lines-n-600);
        border: 0;
        border-left: 5px solid var(--backgrounds-lines-n-500);
        outline: none;
        color: var(--text-icons-gray-100);
        gap: 0;
    }

    .Frame48.simple {
        border-left: 5px solid var(--backgrounds-lines-n-500);
    }

    .Frame48.disabled {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .Frame48.mandatory {
        border-left: 5px solid var(--text-icons-orange-500);
    }

    .Frame48.recommended {
        border-left: 5px solid var(--text-icons-blue-500);
    }

    .Frame48.locked {
        border-left: 5px solid var(--text-icons-gray-300);
        cursor: not-allowed !important;
    }

    .Frame48.invalid {
        border-left: 5px solid var(--text-icons-red-500);
    }

    .dragged {
        border: 1px solid var(--text-icons-blue-500);
        border-radius: 4px;
    }

    .dropped {
        border: 1px solid var(--text-icons-green-500);
        border-radius: 4px;
    }

    .droppedRemove,
    .dropdownArrow {
        position: absolute;
        right: 12px;
        top: 9px;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    .dropdownArrow {
        transition: transform 0.2s;
    }

    .dropdownArrow.active {
        transform: rotate(180deg);
    }

    .Frame48LeftContent {
        display: flex;
        width: 50px;
        height: 37px;
        padding: 6px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-right: 1px solid var(--backgrounds-lines-n-100);
        flex-shrink: 0;
        overflow: hidden;
    }

    .Frame48RightContent {
        display: flex;
        width: 50px;
        height: 37px;
        padding: 6px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-left: 1px solid var(--backgrounds-lines-n-100);
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 0;
    }

    .Frame48Input {
        flex: 1 1 0;
        width: 100%;
        height: 100%;
        padding-left: 12px;
        padding-right: 12px;
        background: var(--backgrounds-lines-n-600);
        border: 0;
        outline: none;
        color: var(--text-icons-gray-100);

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    textarea.Frame48Input {
        resize: none;
        height: auto !important;
        padding-top: 10px;
        padding-bottom: 6px;
    }

    input.Frame48Input::placeholder {
    }

    //WHEN NUMBER REMOVE THE UP AND DOWN ARROWS
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    //WHEN NUMBER REMOVE THE UP AND DOWN ARROWS (FIREFOX)
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .Frame48Input::placeholder,
    TagsInput::placeholder {
        color: var(--text-icons-gray-200);
        font-style: italic;

        //color: var(--text-icons-gray-200, #7c868b);
        color: var(--text-icons-gray-300);
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }

    .Frame48TagsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin: 6px 0px 6px 8px;
        width: 100%;
    }

    .TagsInput {
        color: #d1d1d1;
        background: transparent;
        border: 0;
        outline: none;

        /* Subtitle 2 */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Frame48.disabled .Frame48Input {
        color: var(--text-icons-gray-300);
        cursor: not-allowed;
    }

    .Frame50 {
        align-self: stretch;
        padding-left: 5px;
        padding-right: 5px;
        text-align: right;
    }

    .AssistiveText {
        /* Label/XS - Uppercase */
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: uppercase;
        height: 9px;
    }

    input.datepicker {
        display: inline-block;
        position: relative;
        color-scheme: dark;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        color-scheme: dark;
    }

    div.no-date {
        background: none;
    }

    div.no-date .placeholder {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--backgrounds-lines-n-600);
        height: 37px;
        width: 100%;
        line-height: 37px;
        padding-left: 12px;
        padding-right: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    div.has-date .placeholder {
        display: none;
    }
`;

/**
 * A simple form component that renders an input field with optional tags and tooltip.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the input field.
 * @param {string} props.varName - The variable name of the input field.
 * @param {string} props.type - The type of the input field. Possible values are "text", "number", "email", "password", "date", "datetime-local", "dropdown", "tags", "droppable", "taxNumber" and "phoneNumber"
 * @param {string} [props.requiredType="simple"] - The type of required field indicator. Possible values are "simple", "mandatory", "recommended" and "locked".
 * @param {string} props.value - The value of the input field.
 * @param {function} props.onTextChange - The callback function to handle text changes in the input field.
 * @param {function} props.onTextBlur - The callback function to handle text blur in the input field.
 * @param {function} props.onDropdownChange - The callback function to handle dropdown changes.
 * @param {string} props.tooltip - The tooltip text for the input field.
 * @param {ReactNode} [props.leftContent] - The icon to display on the left side of the input field.
 * @param {string} [props.rightContent] - The text to display on the right side of the input field.
 * @param {string[]} [props.tags] - The array of tags to display below the input field.
 * @param {string} [props.tagAddedColor="#1E2631"] - The color of the tag added to the input field.
 * @param {string} [props.className=""] - The class name for the component.
 * @param {string} [props.minDate=""] - The minimum date for the date input field.
 * @param {string} [props.maxDate=""] - The maximum date for the date input field.
 * @param {string} [props.assistiveText=""] - The bottom right assistive text for the input field.
 * @param {string} [props.assistiveTextColorOverride=""] - The color of the assistive text.
 * @param {string} [props.invalidAssistiveText="Invalid Format"] - The assistive text to display when the input field is invalid.
 * @param {int} [props.numOfLines=1] - The number of lines for the input field. Only applicable for type "text".
 * @param {int} [props.minLength=0] - The minimum length of the input field. Only applicable for type "text".
 * @param {int} [props.maxLength=99] - The maximum length of the input field. Only applicable for type "text".
 * @param {boolean} [props.isDropdownMultiselect=false] - Whether the dropdown is multi-select. Only applicable for type "dropdown".
 * @param {boolean} [props.isDropdownSearchable=false] - Whether the dropdown is searchable. Only applicable for type "dropdown".
 * @param {Object[]} [props.dropdownData=[]] - The data for the dropdown. Only applicable for type "dropdown". Selected one will be the ones that have selected: true.
 * @param {string} [props.phoneNumberCountryCode=""] - The country code for the phone number input field. Only applicable for type "phoneNumber".
 * @param {string} [props.taxNumberCountryCode=""] - The country code for the tax number input field. Only applicable for type "taxNumber".
 * @param {string} [props.onCountryCodeChange=()=>{}] - This is a function triggers when the country code is changed. Only applicable for type "phoneNumber" and "taxNumber".
 * @param {int} [props.formFieldColumSize=1] - The column count size of the form field from 1 up to 6.
 *
 * @returns {JSX.Element} The rendered FormField component.
 *
 */
const FormField = forwardRef((props, ref) => {
    const {
        title,
        type,
        requiredType = "simple",
        value,
        onTextChange,
        onTextBlur,
        onDropdownChange,
        onFormTextChange,
        onFormTextBlur,
        tooltip,
        rightContent,
        tags = [],
        tagAddedColor = "#1E2631",
        className = "",
        minDate = "",
        maxDate = "",
        assistiveTextColorOverride = "",
        invalidAssistiveText = "Invalid Format",
        numberOfLines = 1,
        minLength = 0,
        maxLength = 50,
        isDropdownMultiselect = false,
        isDropdownSearchable = false,
        dropdownData = [],
        phoneNumberCountryCode = "",
        taxNumberCountryCode = "",
        placeholder,
        onCountryCodeChange = () => {},
        onCurrentValueChange
    } = props;
    let { assistiveText, leftContent } = props;

    /* #region STATES */

    const inputRef = useRef();
    const [currentValue, setCurrentValue] = useState(
        type !== "dropdown"
            ? value
            : dropdownData
                  ?.filter(item => item.selected)
                  .map(item => item.name)
                  .join(", ")
    );
    const [currentTags, setCurrentTags] = useState(tags);
    const [dropdownSelectedData, setDropdownSelectedData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [inputID, setInputID] = useState(uuidv4());
    const [fieldIsInvalid, setFieldIsInvalid] = useState(false);
    const [typeInvalidAssistiveText, setTypeInvalidAssistiveText] = useState("");
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [currentDropdownData, setCurrentDropdownData] = useState(dropdownData);
    const [showDropdown, setShowDropdown] = useState(false);

    const [phoneNumberCountryCodeState, setPhoneNumberCountryCodeState] = useState(
        phoneNumberCountryCode.replace("+", "")
    );
    const [taxNumberCountryCodeState, setTaxNumberCountryCode] = useState(taxNumberCountryCode);

    /* #endregion */

    /* #region METHODS */
    const getTextColor = () => {
        if (fieldIsInvalid) return "var(--text-icons-red-500)";
        if (requiredType === "locked") return "var(--text-icons-gray-300)";
        return "var(--text-icons-green-500)";
    };

    const getAssistiveTextColor = () => {
        if (assistiveTextColorOverride && !fieldIsInvalid) return assistiveTextColorOverride;
        if (fieldIsInvalid) {
            assistiveText = invalidAssistiveText;
            return "var(--text-icons-red-500)";
        }
        //if (requiredType === "locked") return "var(--text-icons-gray-300)";
        return "var(--text-icons-blue-500)";
    };

    const isValid = (highlightValidation = true) => {
        if (requiredType === "mandatory" && !currentValue) {
            if (highlightValidation) setFieldIsInvalid(true);
            return false;
        }
        if (!isTypeValid(false)) {
            if (highlightValidation) setFieldIsInvalid(true);
            return false;
        }
        if (highlightValidation) setFieldIsInvalid(false);
        return true;
    };

    const isTypeValid = (highlightValidation = true) => {
        if (type === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(currentValue) && currentValue?.length > 0) {
            if (highlightValidation) {
                setTypeInvalidAssistiveText("Invalid Email Format");
                setFieldIsInvalid(true);
            }
            return false;
        }
        if (type === "phoneNumber") {
            if (!/^\+?[0-9]{6,14}$/.test(currentValue) && currentValue?.length > 0) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Phone Number");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            if (!phoneNumberCountryCodeState && currentValue?.length > 0) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Country Code");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            if (currentValue?.length > 0 && currentValue?.length < 6) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Phone Number");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            setTypeInvalidAssistiveText("");
            setFieldIsInvalid(false);
            return true;
        }
        if (type === "taxNumber") {
            if (!/^[0-9]{9}$/.test(currentValue) && currentValue?.length > 0) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Tax Number");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            if (!taxNumberCountryCodeState && currentValue?.length > 0) {
                if (highlightValidation) {
                    setTypeInvalidAssistiveText("Invalid Country Code");
                    setFieldIsInvalid(true);
                }
                return false;
            }
            setTypeInvalidAssistiveText("");
            setFieldIsInvalid(false);
            return true;
        }
        if (minLength > 0 && currentValue?.length < minLength) {
            if (highlightValidation) {
                setTypeInvalidAssistiveText(`Minimum ${minLength} characters`);
                setFieldIsInvalid(true);
            }
            return false;
        }
        setTypeInvalidAssistiveText("");
        return true;
    };

    const textChange = e => {
        setCurrentValue(e.target.value);
        if (onTextChange) onTextChange(e.target.value);
        if (onFormTextChange) onFormTextChange(true);
    };

    const textBlur = e => {
        if (onTextBlur) onTextBlur(e.target.value);
        if (onFormTextBlur) onFormTextBlur(true);
        isTypeValid(true);
    };

    const onTagAdd = e => {
        if (type === "tags" && tagsChange && e.key === "Enter") {
            const newTag = { bgColor: tagAddedColor, tagName: e.target.value };
            const newTags = [...currentTags, newTag];
            tagsChange(newTags);
            setCurrentValue("");
        }
        if (fieldIsInvalid) isValid();
    };

    const tagsChange = newTags => {
        setCurrentTags(newTags);
    };

    const onDroppedRemove = () => {
        setCurrentValue("");
    };

    const getValue = () => {
        if (type === "tags") return currentTags;
        if (type === "dropdown") return dropdownSelectedData;
        if (type === "phoneNumber") return "+" + phoneNumberCountryCodeState + " " + currentValue;
        if (type === "taxNumber") return currentValue;
        return currentValue;
    };

    const onDropdownClick = () => {
        if (type !== "dropdown" || requiredType === "locked") return;
        setDropdownVisible(!dropdownVisible);
    };

    const onDropdownSingleSelect = data => {
        if (isDropdownMultiselect) return;

        if (type === "phoneNumber") {
            setPhoneNumberCountryCodeState(data.value);
            onCountryCodeChange(data);
            setDropdownVisible(false);
            setShowDropdown(false);
            isTypeValid(true);
            if (onTextChange) onTextChange("+" + data.value + " " + currentValue);
            if (onFormTextChange) onFormTextChange("+" + data.value + " " + currentValue);
            if (onDropdownChange) onDropdownChange(data);
            return;
        }
        if (type === "taxNumber") {
            setTaxNumberCountryCode(data.value);
            onCountryCodeChange(data);
            setDropdownVisible(false);
            setShowDropdown(false);
            isTypeValid(true);
            if (onTextChange) onTextChange(data.value + " " + currentValue);
            if (onFormTextChange) onFormTextChange(data.value + " " + currentValue);
            if (onDropdownChange) onDropdownChange(data);
            return;
        }

        if (onTextChange) onTextChange(data);
        if (onFormTextChange) onFormTextChange(data);
        if (onDropdownChange) onDropdownChange(data);
        setCurrentValue(data.name);
        setDropdownSelectedData(data);
        setDropdownVisible(false);
    };

    const onDropdownMultiSelect = data => {
        if (!isDropdownMultiselect) return;
        const selectedData = data.filter(item => item.selected);

        /* const selectedTags = selectedData.map(item => {
            return { tagName: item.name };
        });
        setCurrentTags(selectedTags); */
        setDropdownSelectedData(selectedData);
        if (onTextChange) onTextChange(selectedData);
        if (onFormTextChange) onFormTextChange(selectedData);
        setCurrentValue(selectedData.map(item => item.name).join(", "));
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (type === "dropdown") {
            if (isDropdownMultiselect) {
                /* TODO: Implement multiselect dropdown value handling */
            } else {
                //const selectedData = dropdownData.map(item => ({ ...item, selected: item.name === value }));
                const selectedData = dropdownData.find(item => item.name === value);
                setDropdownSelectedData(selectedData);
                console.log("selectedData", selectedData);
                setCurrentValue(selectedData);
            }
        }

        setCurrentValue(value);
    }, [value]);

    useEffect(() => {
        if (type !== "dropdown") return;

        setCurrentDropdownData(dropdownData);
        if (isDropdownMultiselect) setDropdownSelectedData(dropdownData.filter(item => item.selected));
        else setDropdownSelectedData(dropdownData.find(item => item.selected));
        setCurrentValue(
            dropdownData
                ?.filter(item => item.selected)
                .map(item => item.name)
                .join(", ")
        );
    }, [dropdownData]);

    useEffect(() => {
        if (type === "phoneNumber") {
            setPhoneNumberCountryCodeState(phoneNumberCountryCode.replace("+", ""));
        }
        if (type === "taxNumber") {
            setTaxNumberCountryCode(taxNumberCountryCode);
        }
    }, [phoneNumberCountryCode, taxNumberCountryCode]);

    useEffect(() => {
        if (onCurrentValueChange) onCurrentValueChange(currentValue);
    }, [currentValue]);
    /* #endregion */

    /* #region COMPUTED VALUES */
    const required = requiredType === "mandatory";
    const disabled = requiredType === "locked" || type === "droppable" || type === "dropdown";
    const textColor = getTextColor();

    if (type === "phoneNumber") {
        leftContent = LeftContentPhoneNumber({
            showDropdown,
            setShowDropdown,
            setCurrentDropdownData,
            setDropdownVisible,
            phoneNumberCountryCode: phoneNumberCountryCodeState,
            fieldIsInvalid
        });
    }

    if (type === "taxNumber") {
        leftContent = LeftContentTaxNumber({
            taxNumberCountryCodeState,
            fieldIsInvalid
        });
    }

    if (type === "email") {
        leftContent = <img className="mailIcon" src={Mail05Icon} alt="" />;
    }

    /* #endregion */

    useImperativeHandle(ref, () => ({
        isValid,
        getValue
    }));

    return (
        <StyledElems
            className={`FormField ${className} ${requiredType}`}
            style={{ gridColumn: `span ${props.formFieldColumSize}` }}>
            <div className="FormComponentContainer">
                <div className="Frame51">
                    {tooltip && (
                        <Tooltip title={tooltip} color="#10141b" overlayStyle={{ fontSize: "0.75rem" }}>
                            <i
                                className={`Icon fas fa-info-circle ${fieldIsInvalid ? "text-red-500" : ""}`}
                                aria-hidden="true"></i>
                        </Tooltip>
                    )}
                    {!tooltip && <i className="Icon fas fa-info-circle hidden" aria-hidden="true"></i>}
                    <div
                        className={`Title ${
                            fieldIsInvalid ? "text-red-500" : requiredType === "locked" ? "text-gray-300" : ""
                        }`}>
                        {title}
                    </div>
                </div>
                <div
                    className={`InputSelectorInverted ${
                        type !== "droppable" ? "" : currentValue ? "dropped" : "dragged"
                    }`}>
                    {" "}
                    <div
                        className={`Search Frame48 ${
                            fieldIsInvalid
                                ? "invalid"
                                : (props.type === "tags" && currentTags?.length === 0) ||
                                  (props.type !== "tags" && !currentValue) ||
                                  requiredType === "locked"
                                ? requiredType
                                : "simple"
                        } ${
                            disabled && type === "droppable"
                                ? ""
                                : disabled && type === "dropdown"
                                ? "cursor-pointer"
                                : disabled
                                ? "disabled"
                                : ""
                        }`}
                        style={{ height: "unset", minHeight: "37px" }}>
                        {leftContent !== undefined && (
                            <>
                                <div
                                    className="Frame48LeftContent"
                                    style={{
                                        cursor:
                                            requiredType === "locked"
                                                ? "not-allowed"
                                                : type === "date" || type === "datetime-local"
                                                ? "pointer"
                                                : "default"
                                    }}
                                    onClick={() =>
                                        type === "date" || type === "datetime-local"
                                            ? inputRef.current.showPicker()
                                            : null
                                    }>
                                    {leftContent}
                                </div>
                            </>
                        )}
                        {type === "tags" && (
                            <div className="Frame48TagsContainer">
                                {currentTags.map((tag, index) => (
                                    <TagComponent
                                        key={uuidv4()}
                                        index={index}
                                        bgColor={tag?.bgColor ?? tagAddedColor}
                                        tagName={tag?.tagName ?? "--"}
                                        hideRemoveButton={tag?.hideRemoveButton}
                                        onRemoveClick={() => {
                                            const newTags = currentTags.filter((_item, idx) => idx !== index);
                                            tagsChange(newTags);
                                        }}
                                    />
                                ))}
                                <input
                                    type="text"
                                    className="TagsInput"
                                    value={currentValue}
                                    onChange={textChange}
                                    onKeyUp={onTagAdd}
                                    placeholder="Press enter to add tags"
                                />
                            </div>
                        )}
                        {type === "dropdown" && (
                            <>
                                <div
                                    className="Frame48Input"
                                    style={{
                                        cursor: requiredType === "locked" ? "not-allowed" : "pointer",
                                        color: textColor,
                                        height: "37px",
                                        display: "flex",
                                        alignItems: "center",
                                        position: "relative"
                                    }}
                                    onClick={onDropdownClick}>
                                    <label
                                        className="text-one-line"
                                        onClick={onDropdownClick}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            alignContent: "center",
                                            overflow: "hidden",
                                            paddingRight: "24px"
                                        }}>
                                        {currentValue}
                                    </label>
                                    <div className={dropdownVisible ? "dropdownArrow active" : "dropdownArrow"}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="17"
                                            viewBox="0 0 16 17"
                                            fill="none">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M3.52864 6.02864C3.78899 5.76829 4.2111 5.76829 4.47145 6.02864L8.00004 9.55723L11.5286 6.02864C11.789 5.76829 12.2111 5.76829 12.4714 6.02864C12.7318 6.28899 12.7318 6.7111 12.4714 6.97145L8.47145 10.9714C8.2111 11.2318 7.78899 11.2318 7.52864 10.9714L3.52864 6.97145C3.26829 6.7111 3.26829 6.28899 3.52864 6.02864Z"
                                                fill="#D1D1D1"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </>
                        )}
                        {type !== "tags" && type !== "dropdown" && (
                            <div
                                className={`${type === "date" && !currentValue ? "no-date" : "has-date"}`}
                                style={{ position: "relative", width: "100%", flexShrink: "1", overflow: "hidden" }}>
                                <CustomInputField
                                    numoflines={numberOfLines}
                                    id={`myDateInput-${inputID}`}
                                    ref={inputRef}
                                    type={type === "phoneNumber" || type === "taxNumber" ? "number" : type}
                                    min={minDate}
                                    max={maxDate}
                                    placeholder={placeholder}
                                    onBlur={textBlur}
                                    disabled={disabled}
                                    required={required}
                                    value={currentValue}
                                    onChange={textChange}
                                    minLength={minLength}
                                    maxLength={maxLength}
                                    onKeyUp={onTagAdd}
                                    //onKeyDown={onKeyDown}
                                    className={`Frame48Input ${
                                        type === "date" || type === "datetime-local"
                                            ? !currentValue
                                                ? "datepicker no-date"
                                                : "datepicker has-date"
                                            : ""
                                    }`}
                                    style={{
                                        //minWidth: "100px",
                                        height: "37px",
                                        color: textColor,
                                        cursor:
                                            type === "dropdown"
                                                ? "pointer"
                                                : type === "droppable"
                                                ? "default"
                                                : disabled
                                                ? "not-allowed"
                                                : "text",
                                        textOverflow: "ellipsis"
                                    }}
                                />
                                {(type === "date" || type === "datetime-local") && props?.datePickerPlaceholder && (
                                    <label
                                        htmlFor={`myDateInput-${inputID}`}
                                        className="placeholder"
                                        style={{ display: "none", color: textColor, cursor: "pointer" }}
                                        onClick={() => inputRef.current.showPicker()}>
                                        {props?.datePickerPlaceholder}
                                    </label>
                                )}
                                {type === "droppable" && currentValue && (
                                    <div className="droppedRemove" onClick={onDroppedRemove}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="17"
                                            viewBox="0 0 16 17"
                                            fill="none">
                                            <path
                                                d="M11.5286 12.9714C11.7889 13.2318 12.211 13.2318 12.4714 12.9714C12.7317 12.7111 12.7317 12.289 12.4714 12.0286L8.94277 8.50004L12.4714 4.97145C12.7317 4.7111 12.7317 4.28899 12.4714 4.02864C12.211 3.76829 11.7889 3.76829 11.5286 4.02864L7.99996 7.55723L4.47136 4.02864C4.21101 3.76829 3.7889 3.76829 3.52855 4.02864C3.2682 4.28899 3.2682 4.7111 3.52855 4.97144L7.05715 8.50004L3.52855 12.0286C3.2682 12.289 3.2682 12.7111 3.52855 12.9714C3.7889 13.2318 4.21101 13.2318 4.47136 12.9714L7.99996 9.44285L11.5286 12.9714Z"
                                                fill="#D1D1D1"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        )}
                        {rightContent !== undefined && (
                            <div className="Frame48RightContent" style={{ color: getTextColor() }}>
                                {rightContent}
                            </div>
                        )}
                    </div>
                </div>

                {(type === "dropdown" || showDropdown) && (
                    <div className="dropdownContainer" style={{ position: "absolute", width: "100%", zIndex: "9999" }}>
                        <DropdownOverlay
                            isSearchable={isDropdownSearchable}
                            data={currentDropdownData}
                            onSingleItemClick={onDropdownSingleSelect}
                            isChangeColor={true}
                            overlayType={isDropdownMultiselect ? OverlayType.MULTI_SELECT : OverlayType.UNI_SELECT}
                            multiSelectData={onDropdownMultiSelect}
                            isVisible={dropdownVisible}
                            style={{
                                width: "100%",
                                top: "65px",
                                zIndex: 1000
                            }}
                        />
                    </div>
                )}

                <div className="Frame50" style={{ color: getAssistiveTextColor() }}>
                    {(assistiveText || typeInvalidAssistiveText) && (
                        <div className="AssistiveText">{typeInvalidAssistiveText || assistiveText}</div>
                    )}
                </div>
            </div>
        </StyledElems>
    );
});

export default FormField;

/* #region SMALL COMPONENTS */
const CustomInputField = forwardRef((props, ref) => {
    const { numoflines, type } = props;

    const onKeyDown = e => {
        const type = props.type;

        if (
            ["e", "E", "+", "-", " ", "."].includes(e.key) &&
            (type === "number" || type === "phoneNumber" || type === "taxNumber")
        )
            e.preventDefault();
    };

    return numoflines > 1 && type === "text" ? (
        <textarea {...props} rows={numoflines} ref={ref} />
    ) : (
        <input {...props} ref={ref} onKeyDown={onKeyDown} />
    );
});

const LeftContentPhoneNumber = props => {
    //const [showPhoneOverlay, setShowPhoneOverlay] = useState(false);
    const {
        showDropdown,
        setShowDropdown,
        setCurrentDropdownData,
        setDropdownVisible,
        phoneNumberCountryCode,
        fieldIsInvalid
    } = props;

    const onPhoneClick = () => {
        //props.setDropdownSelectedData(getDropdownData("countryOptions") ?? []);
        setShowDropdown(!showDropdown);
        setCurrentDropdownData(getDropdownData("countryCodeOptions"));
        setDropdownVisible(!showDropdown);
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
                cursor: "pointer",
                height: "calc(100% + 12px)"
            }}
            onClick={onPhoneClick}>
            <div
                style={{
                    width: "27px",
                    fontSize: "12px",
                    color: !fieldIsInvalid ? "#00BB00" : "var(--text-icons-red-500)",
                    overflow: "hidden"
                }}>
                {phoneNumberCountryCode ? `+${phoneNumberCountryCode}` : ""}
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.70529 3.95541C2.86801 3.79269 3.13183 3.79269 3.29455 3.95541L5.49992 6.16079L7.70529 3.95541C7.86801 3.79269 8.13183 3.79269 8.29455 3.95541C8.45726 4.11813 8.45726 4.38195 8.29455 4.54467L5.79455 7.04467C5.63183 7.20739 5.36801 7.20739 5.20529 7.04467L2.70529 4.54467C2.54257 4.38195 2.54257 4.11813 2.70529 3.95541Z"
                    fill="#D1D1D1"
                />
            </svg>
        </div>
    );
};

const LeftContentTaxNumber = props => {
    const { taxNumberCountryCodeState, fieldIsInvalid } = props;

    return (
        <div
        /*style={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
                cursor: "pointer",
                height: "calc(100% + 12px)"
            }}*/
        >
            <div
                style={{
                    width: "27px",
                    fontSize: "12px",
                    color: !fieldIsInvalid ? "#00BB00" : "var(--text-icons-red-500)",
                    overflow: "hidden"
                }}>
                {taxNumberCountryCodeState ? `${taxNumberCountryCodeState}` : ""}
            </div>
        </div>
    );
};
/* #endregion */
