import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../config/constant";
import ContainerLoader from "../../common/loaders/ContainerLoader";
import BtnLargeSolid from "../../common/buttons/BtnLargeSolid";
import CardComponentContainer from "../../common/cardContainers/CardComponentContainer";
import { useNavigate, useOutletContext } from "react-router";
import BrandMainOffice from "./createBrandComponents/BrandMainOffice";
import BrandLogo from "./createBrandComponents/BrandLogo";
import BrandIDCard from "./createBrandComponents/BrandIDCard";
import HashtagAndTarget from "./createBrandComponents/HashtagAndTarget";
import * as Library from "../../../utils/Library";
import { useDispatch, useSelector } from "react-redux";
import FullScreenOverlay from "../../common/overlay/FullScreenOverlay";
import DialogBoxComponent from "../../common/dialogs/DialogBoxComponent";
import LayoutContext from "../../../LayoutContext";
import { setNavigations } from "../../../utils/redux/navigationAccessSlice";
import SimpleButtonContentHeader from "../companies/createCompanyComponents/SimpleButtonContentHeader";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .input-remove-default {
        border: none;
        outline: none;
        background: none;
    }

    .selects {
        background: var(--backgrounds-and-lines-n-600, #10141b);
    }

    .selects.mandatory {
        border: 1px solid transparent;
        border-left: 5px solid var(--text-icons-orange-500);
        border-radius: 4px;
        outline: none;
    }

    .selects.mandatory > div {
        border: none;
        cursor: pointer;
    }

    .selects.mandatory.error {
        border: 1px solid var(--text-icons-red-500);
        border-left: 5px solid var(--text-icons-red-500);
    }

    .main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 68px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--buttons-all-platforms-gray-200, #4f5b6d);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
`;
const RequiredLabelStyle = styled.div`
    &.main-conatainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        font-size: 10px;
        font-family: "Roboto";
        color: #7c868b;
        text-align: center;
        justify-content: center;
        align-items: center;
        text-transform: lowercase;
    }
    .recommended {
        display: flex;
        flex-direction: row;
        gap: 4px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .recommended-icon {
        height: 6px;
        width: 6px;
        border: 1px solid #00aeef;
        background-color: #00aeef;
        border-radius: 50%;
    }
    .mandatory-icon {
        height: 6px;
        width: 6px;
        border: 1px solid #ff9900;
        background-color: #ff9900;
        border-radius: 50%;
    }
    .locked-icon {
        height: 6px;
        width: 6px;
        border: 1px solid #4f5b6d;
        background-color: #4f5b6d;
        border-radius: 50%;
    }
`;
/* #endregion SCOPED CSS */

export default function CreateBrandPage() {
    document.title = `${APP_TITLE} - Brands Create`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const { showIsCompanySavedPopup, setShowIsCompanySavedPopup, setShowIsBrandSavedPopup } =
        React.useContext(LayoutContext);

    const dispatch = useDispatch();

    const navigations = useSelector(state => state.navigationAccesses);
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData,
        navigations: navigations
    };
    const [isLoading, setIsLoading] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

    const [brand, setBrand] = useState("");
    const [toCompany, setToCompany] = useState("");
    const [industry, setIndustry] = useState("");
    const [lifestyle, setLifestyle] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [website, setWebsite] = useState("");
    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");

    const [mainLogo, setMainLogo] = useState(null);
    const [compactLogo, setCompactLogo] = useState(null);
    const [mainLogoWhite, setMainLogoWhite] = useState(null);
    const [mainLogoBlack, setMainLogoBlack] = useState(null);
    const [compactLogoWhite, setCompactLogoWhite] = useState(null);
    const [compactLogoBlack, setCompactLogoBlack] = useState(null);

    const [googlePlusCode, setGooglePlusCode] = useState("");
    const [brandCountry, setBrandCountry] = useState("");
    const [brandCity, setBrandCity] = useState("");
    const [brandPostalCode, setBrandPostalCode] = useState("");
    const [brandAddressOne, setBrandAddressOne] = useState("");
    const [brandAddressTwo, setBrandAddressTwo] = useState("");
    const [brandLatitude, setBrandLatitude] = useState("");
    const [brandLongitude, setBrandLongitude] = useState("");

    const [genderTags, setGenderTags] = React.useState([]);
    const [ageGroupTags, setAgeGroupTags] = React.useState([]);
    const [lifestyleTags, setLifestyleTags] = React.useState([]);
    const [majorMarkets, setmajorMarkets] = React.useState([]);
    const [hashtags, setHashtags] = React.useState("");
    const navigate = useNavigate();
    /* #endregion STATES */

    /* #region EFFECTS */
    useEffect(() => {
        const saveIsEnabled = !saveBtnDisabled && !isLoading;

        setupHeaders(props, saveIsEnabled, onSaveClicked);
    }, [props?.layoutType, saveBtnDisabled, isLoading]);

    useEffect(() => {
        if (
            brand &&
            toCompany &&
            industry &&
            lifestyle &&
            productCategory &&
            website &&
            instagram &&
            facebook &&
            mainLogo &&
            mainLogoWhite &&
            mainLogoBlack &&
            googlePlusCode &&
            // brandCountry &&
            // brandCity &&
            // brandPostalCode &&
            // brandAddressOne &&
            brandLatitude &&
            brandLongitude &&
            genderTags &&
            ageGroupTags &&
            lifestyleTags &&
            majorMarkets &&
            hashtags
        ) {
            setSaveBtnDisabled(false);
        } else {
            setSaveBtnDisabled(true);
        }
    }, [
        brand,
        toCompany,
        industry,
        lifestyle,
        productCategory,
        website,
        instagram,
        facebook,
        mainLogo,
        mainLogoWhite,
        mainLogoBlack,
        googlePlusCode,
        brandCountry,
        brandCity,
        brandPostalCode,
        brandAddressOne,
        brandLatitude,
        brandLongitude,
        genderTags,
        ageGroupTags,
        lifestyleTags,
        majorMarkets,
        hashtags
    ]);
    /* #endregion */

    /* #region METHODS */
    const onSaveClicked = async () => {
        const labelBrandID = JSON.parse(localStorage.getItem("labelBrands"))[0]?._id || "";
        const jsonPostData = {
            labelBrandID: labelBrandID,
            name: brand,
            industry: industry,
            designAndLifestyle: lifestyle,
            mainProductCategory: productCategory,
            location: {
                latitude: brandLatitude,
                longitude: brandLongitude,
                googlePlusCode: googlePlusCode,
                countryValue: brandCountry,
                city: brandCity,
                postalCode: brandPostalCode,
                addressLine1: brandAddressOne,
                addressLine2: brandAddressTwo,
                addressNumber: ""
            },
            socialMedia: {
                website: website,
                facebook: facebook,
                instagram: instagram
            },
            logos: {
                mainLogoData: mainLogo,
                mainLogoWhiteData: mainLogoWhite,
                mainLogoBlackData: mainLogoBlack,
                alternativeLogoData: compactLogo,
                alternativeLogoWhiteData: compactLogoWhite,
                alternativeLogoBlackData: compactLogoBlack
            },
            images: {
                BOtagHomeData: mainLogo
            },
            targets: {
                gender: genderTags,
                ageGroups: ageGroupTags,
                lifestyles: lifestyleTags,
                majorMarkets: majorMarkets,
                hashtags: hashtags
            }
        };
        const res = await Library.makePostRequest("updateLabelBrand", jsonPostData, false, setIsLoading);

        if (res?.data?.status === 200) {
            Library.showSuccessMessage("LabelBrand created successfully");
            const updatedNavigations = {
                ...navigations.navigationAccesses,
                labelBrands: {
                    ...navigations.navigationAccesses.companies,
                    createModeOnly: false
                },
                BOimages: {
                    ...navigations.navigationAccesses.labelBrands,
                    createModeOnly: true,
                    navigationPanel: true
                }
            };
            dispatch(setNavigations({ navigationAccesses: updatedNavigations }));

            navigate("/boimages/create");
            setShowIsBrandSavedPopup(true);
        }
    };
    /* #endregion METHODS */

    return (
        <StyledPage style={{ marginTop: "-24px" }}>
            <FullScreenOverlay
                show={showIsCompanySavedPopup}
                children={
                    <DialogBoxComponent
                        buttonWidth={"50%"}
                        onClickYes={() => setShowIsCompanySavedPopup(false)}
                        title="Company is now saved!"
                        heading="Success!"
                        headingColor="#00BB00"
                        message={
                            "<div style='font-size: 12px; margin: 0 -5px;'>You can now proceed with enrolling your brand</div>"
                        }
                        // useImageHeader={{ src: BOStageBLogo, width: "165px" }}
                        showConfirm={() => setShowIsCompanySavedPopup(false)}
                        showCancel={false}
                        confirmText="CONTINUE"
                    />
                }></FullScreenOverlay>
            <ContainerLoader isLoading={isLoading} isLoadingOverlay={true} addBlurFilters={true}>
                <div className="flex flex-dir-column gap-16 w-100">
                    <CardComponentContainer title="BRAND ID" className="mt-24px" rightComponent={<RequiredLabel />}>
                        <BrandIDCard
                            brand={brand}
                            setBrand={setBrand}
                            toCompany={toCompany}
                            setToCompany={setToCompany}
                            industry={industry}
                            setIndustry={setIndustry}
                            lifestyle={lifestyle}
                            setLifestyle={setLifestyle}
                            productCategory={productCategory}
                            setProductCategory={setProductCategory}
                            website={website}
                            setWebsite={setWebsite}
                            instagram={instagram}
                            setInstagram={setInstagram}
                            facebook={facebook}
                            setFacebook={setFacebook}
                        />
                    </CardComponentContainer>
                    <CardComponentContainer title="BRAND LOGOS" className="mt-24px" rightComponent={<RequiredLabel />}>
                        <BrandLogo
                            mainLogo={mainLogo}
                            setMainLogo={setMainLogo}
                            compactLogo={compactLogo}
                            setCompactLogo={setCompactLogo}
                            mainLogoWhite={mainLogoWhite}
                            setMainLogoWhite={setMainLogoWhite}
                            mainLogoBlack={mainLogoBlack}
                            setMainLogoBlack={setMainLogoBlack}
                            compactLogoWhite={compactLogoWhite}
                            setCompactLogoWhite={setCompactLogoWhite}
                            compactLogoBlack={compactLogoBlack}
                            setCompactLogoBlack={setCompactLogoBlack}
                        />
                    </CardComponentContainer>
                    <CardComponentContainer
                        title="BRAND MAIN OFFICE"
                        className="mt-24px"
                        rightComponent={<RequiredLabel />}>
                        <BrandMainOffice
                            googlePlusCode={googlePlusCode}
                            setGooglePlusCode={setGooglePlusCode}
                            brandCountry={brandCountry}
                            setBrandCountry={setBrandCountry}
                            brandCity={brandCity}
                            setBrandCity={setBrandCity}
                            brandPostalCode={brandPostalCode}
                            setBrandPostalCode={setBrandPostalCode}
                            brandAddressOne={brandAddressOne}
                            setBrandAddressOne={setBrandAddressOne}
                            brandAddressTwo={brandAddressTwo}
                            setBrandAddressTwo={setBrandAddressTwo}
                            brandLatitude={brandLatitude}
                            setBrandLatitude={setBrandLatitude}
                            brandLongitude={brandLongitude}
                            setBrandLongitude={setBrandLongitude}
                        />
                    </CardComponentContainer>
                    <CardComponentContainer
                        title="TARGETS & HASHTAGS"
                        className="mt-24px"
                        rightComponent={<RequiredLabel />}>
                        <HashtagAndTarget
                            lifestyleTags={lifestyleTags}
                            setLifestyleTags={setLifestyleTags}
                            genderTags={genderTags}
                            setGenderTags={setGenderTags}
                            ageGroupTags={ageGroupTags}
                            setAgeGroupTags={setAgeGroupTags}
                            majorMarkets={majorMarkets}
                            setmajorMarkets={setmajorMarkets}
                            hashtags={hashtags}
                            setHashtags={setHashtags}
                        />
                    </CardComponentContainer>
                </div>
            </ContainerLoader>
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS AND METHODS */

function setupHeaders(props, saveButtonEnabled, onBtnClicked) {
    const brandsAvailable = JSON.parse(localStorage.getItem("labelBrands"))?.length;

    const createMode = props?.navigations?.navigationAccesses?.labelBrands?.createModeOnly;
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Brands", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([
        { to: "/brands/create", active: true, disabled: false, title: "Brands" },
        //{ to: "/brands/users", active: false, disabled: brandsAvailable ? false : true, title: "Users" }
        //todo: check if users should be disabled? last requirement was to disable users everywhere
        { to: "/brands/users", active: false, disabled: true, title: "Users" }
    ]);
    if (brandsAvailable) {
        props.setControlBarRightBtns([
            { to: "/brands/create", active: true, disabled: false, title: "Add", icon: "plus" },
            { to: "/brands", active: false, disabled: false, title: "View", icon: "eye" },
            { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
        ]);
    } else {
        props.setControlBarRightBtns([
            { to: "/brands/create", active: true, disabled: false, title: "Add", icon: "plus" }
        ]);
    }

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader btnTitle="SAVE" btnEnabled={saveButtonEnabled} onClick={onBtnClicked} />
        )
    });
}

function RequiredLabel() {
    return (
        <RequiredLabelStyle className="main-conatainer">
            <div className="recommended">
                <div className="recommended-icon"></div> recommended
            </div>
            <div className="recommended">
                <div className="mandatory-icon"></div> mandatory
            </div>
            <div className="recommended">
                <div className="locked-icon"></div> locked
            </div>
        </RequiredLabelStyle>
    );
}
/* #endregion */
