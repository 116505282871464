import AccountPageDetails from "../components/pages/account/AccountPageDetails";
import AccountUsersPage from "../components/pages/account/AccountUsersPage";
import BillingPage from "../components/pages/account/BillingPage";
import OrganizationAddPage from "../components/pages/account/OrganizationAddPage";
import AccountEditPage from "../components/pages/account/AccountEditPage";

const routes = [
    {
        path: "/account",
        element: <AccountPageDetails />
    },
    {
        path: "/account/edit",
        element: <AccountEditPage />
    },
    {
        path: "/account/create",
        element: <AccountEditPage />
    },
    {
        //TODO: REMOVE THIS ROUTE WHEN NO LONGER NEEDED
        path: "/account/old",
        element: <OrganizationAddPage />
    },
    {
        path: "/account/users",
        element: <AccountUsersPage />
    },
    {
        path: "/billing",
        element: <BillingPage />
    },
    {
        path: "/billing/:id",
        element: <BillingPage />
    }
];

export default routes;
