import { APP_HOMEPAGE_ROUTE } from "../config/constant";

/* TODO: CHECK IF TO REMOVE. THESE METHODS WERE REPLACED BY verifyUserIsOnboarding
export const getLastNavigation = (accessObject = {}) => {
    const accessEntries = Object.entries(accessObject || {});

    for (let i = accessEntries.length - 1; i >= 0; i--) {
        const [key, value] = accessEntries[i];

        if (value.navigationPanel) {
            return { [key]: value };
        }
    }

    return "/billing";
};

//most of the routes are not ready temp are the not ready routes
export const getPath = (object = {}) => {
    const navigationComponent = Object.keys(object || {})?.[0];

    if (navigationComponent === "BOimages") {
        if (object?.BOimages?.navigationPanel && object?.BOimages?.createModeOnly) {
            return "/boimages/create";
        } else if (object?.BOimages?.navigationPanel) {
            return "/boimages";
        }
    } else if (navigationComponent === "companies") {
        if (object?.companies?.navigationPanel && object?.companies?.createModeOnly) {
            return "/companies/create"; //done
        } else if (object?.companies?.navigationPanel) {
            return "/companies";
        }
    } else if (navigationComponent === "dashboard") {
        if (object?.dashboard?.navigationPanel && object?.dashboard?.createModeOnly) {
            return "/account"; //temp
        } else if (object?.dashboard?.navigationPanel) {
            return "/dashboard";
        }
    } else if (navigationComponent === "fulfillmentCenters") {
        if (object?.fulfillmentCenters?.navigationPanel && object?.fulfillmentCenters?.createModeOnly) {
            return "/account"; //temp
        } else if (object?.fulfillmentCenters?.navigationPanel) {
            return "/ffcenters";
        }
    } else if (navigationComponent === "labelBrands") {
        if (object?.labelBrands?.navigationPanel && object?.labelBrands?.createModeOnly) {
            return "/brands/create"; //done
        } else if (object?.labelBrands?.navigationPanel) {
            return "/brands";
        }
    } else if (navigationComponent === "orders") {
        if (object?.orders?.navigationPanel && object?.orders?.createModeOnly) {
            return "/account"; //temp
        } else if (object?.orders?.navigationPanel) {
            return "/orders";
        }
    } else if (navigationComponent === "organization") {
        if (object?.organization?.navigationPanel && object?.organization?.createModeOnly) {
            return "/account/create";
        } else if (object?.organization?.navigationPanel) {
            return "/account";
        }
    } else if (navigationComponent === "products") {
        if (object?.products?.navigationPanel && object?.products?.createModeOnly) {
            return "/account"; //temp
        } else if (object?.products?.navigationPanel) {
            return "/products";
        }
    } else if (navigationComponent === "stores") {
        if (object?.stores?.navigationPanel && object?.stores?.createModeOnly) {
            return "/stores/create";
        } else if (object?.stores?.navigationPanel) {
            return "/stores";
        }
    } else if (navigationComponent === "promoters") {
        if (object?.promoters?.navigationPanel && object?.promoters?.createModeOnly) {
            return "/account"; //temp
        } else if (object?.promoters?.navigationPanel) {
            return "/account"; //temp
        }
    } else if (navigationComponent === "retailBrands") {
        if (object?.retailBrands?.navigationPanel && object?.retailBrands?.createModeOnly) {
            return "/brands/create"; //done
        } else if (object?.retailBrands?.navigationPanel) {
            return "/brands"; //done
        }
    } else if (navigationComponent === "retailers") {
        if (object?.retailers?.navigationPanel && object?.retailers?.createModeOnly) {
            return "/account"; //temp
        } else if (object?.retailers?.navigationPanel) {
            return "/account"; //temp
        }
    }
};
*/

export const verifyUserIsOnboarding = navigationAccesses => {
    let onboardingRoute = APP_HOMEPAGE_ROUTE;

    if (!navigationAccesses) return undefined;

    const onboardingBillingPanel = !navigationAccesses.organization.navigationPanel;
    const onboardingOrganizationPanel =
        navigationAccesses.organization.navigationPanel && navigationAccesses.organization.createModeOnly;
    const onboardingCompanyPanel =
        navigationAccesses.companies.navigationPanel && navigationAccesses.companies.createModeOnly;
    const onboardingLabelBrandPanel =
        navigationAccesses.labelBrands.navigationPanel && navigationAccesses.labelBrands.createModeOnly;
    const onboardingBOimagePanel =
        navigationAccesses.BOimages.navigationPanel && navigationAccesses.BOimages.createModeOnly;
    const onboardingDashboardPanel = navigationAccesses.dashboard.navigationPanel;

    const homepageError =
        !onboardingBillingPanel &&
        !onboardingOrganizationPanel &&
        !onboardingCompanyPanel &&
        !onboardingLabelBrandPanel &&
        !onboardingBOimagePanel &&
        !onboardingDashboardPanel;

    if (homepageError) {
        localStorage.clear();
        console.error("Homepage Error: No navigation panel is enabled");
        onboardingRoute = "/login";
    } else if (onboardingBillingPanel) {
        onboardingRoute = "/billing";
    } else if (onboardingOrganizationPanel) {
        onboardingRoute = "/account/create";
    } else if (onboardingCompanyPanel) {
        onboardingRoute = "/companies/create";
    } else if (onboardingLabelBrandPanel) {
        onboardingRoute = "/companies";
    } else if (onboardingBOimagePanel) {
        onboardingRoute = "/companies";
    } else if (onboardingDashboardPanel) {
        onboardingRoute = APP_HOMEPAGE_ROUTE;
    }

    //console.log("onboardingRoute", onboardingRoute);
    return onboardingRoute;
};

//redirect /companies X

//You can now proceed with enrolling your brand -> temporarily remove and redirect to /companies
