import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import RetailerGridCard from "../gridCards/RetailerGridCard";
import * as Library from "../../../utils/Library";
import { formatDefaultDateTime, formatPrice } from "../../../utils/Formatter";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/* #endregion */

/**
 * Renders an infinite grid of brand cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.className] - The additional CSS class name for the component.
 * @param {number} [props.limit=10] - The maximum number of cards to load per page.
 * @param {string} [props.queryKey] - The query key for fetching data.
 * @param {number} [props.minimumCardsFill] - The minimum number of cards to fill the grid.
 * @param {number} [props.minColumnWidth] - The minimum width of each grid column.
 * @param {Function} [props.onEditAction] - The callback function for the edit action.
 * @param {Function} [props.onDeleteAction] - The callback function for the delete action.
 * @param {Function} [props.onSuspendAction] - The callback function for the suspend action.
 * * @param {string} [props.statusFromPath] - The status from the path.
 * @returns {JSX.Element} The rendered component.
 */

export default function InfinityGridBrandsCards(props) {
    const pageLimit = props?.limit || 8;

    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const [queryKey, setQueryKey] = useState(props?.queryKey ?? uuidv4());
    const [minimumCardsFill, setminimumCardsFill] = useState(props?.minimumCardsFill || pageLimit);

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        queryKey,
        "getLabelBrandsData",
        {
            companyID: localStorage.getItem("currentCompanyID"),
            limit: pageLimit
        },
        () => {
            setScroll(true);
        }
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    const getStatusValue = row => {
        const statusFromPath = props?.statusFromPath;
        const status = getArrayFieldValue(row, statusFromPath.split(".") || "Unknown");
        //console.log("status", status);
        return status;
    };

    const getArrayFieldValue = (row, array) => {
        if (!Array.isArray(array)) return "Unknown";

        let res = row;
        for (let i = 0; i < array.length; i++) {
            res = res?.[array[i]];
        }
        return res;
    };

    /* #endregion */

    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: pageLimit }, () => (
                        <CardGridSkeleton
                            key={uuidv4()}
                            height="420.28px"
                            minColumnWidth={props?.minColumnWidth || "395px"}
                        />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    <RetailerGridCard
                        key=""
                        image="blob:http://localhost:3000/ee994eda-b3f1-41de-a059-43814580beca"
                        title="FOLLESTAD"
                        titlePrefix="RETAIL Brand"
                        id="LOLPHTZE2"
                        isCardEnabled={true}
                        isCardSuspended={false}
                        data={{
                            invitedOn: "31/12/2023  10:45",
                            company: "Follestad Fashion AS",
                            contactPerson: "William Follestad",
                            status: "Invited",
                            contactEmail: "william@follestad.no",
                            maxStore: "21",
                            activeStore: "11",
                            brand: "Replay"
                        }}
                        edit={{ hidden: false, active: true }}
                        suspend={{
                            isReenable: false,
                            onClick: null,
                            active: true
                        }}
                        delete={{ active: true }}
                        primaryButton={{ title: "Details" }}
                        secondaryButton={{ title: "FFCenters" }}
                    />

                    {Array.from({ length: 3 }, (_, index) => (
                        <RetailerGridCard key={index} isCardEnabled={false} />
                    ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton key={index} height="420.28px" />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}
