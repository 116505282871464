import React, { forwardRef, useImperativeHandle } from "react";
import CardComponentContainer from "../cardContainers/CardComponentContainer";

/**
 * Renders a form container consisting of multiple form fields with a number of fields per line.
 * Note: The formFields prop is an array of FormField.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the form container.
 * @param {boolean} [props.showDefaultRightLegends=true] - Whether to show the default right legends: (recommended, mandatory, locked).
 * @param {number} [props.numOfFieldsPerLine=4] - The number of fields per line.
 * @param {Object[]} [props.formFields=[]] - The form fields to render.
 * @param {Object[]} [props.formFieldRefs=[]] - The form field refs.
 * @param {Function} [props.onFormChanged] - The function to call when the form changes.
 * @param {Object} [props.rightContent] - The right content to render.
 * @returns {JSX.Element} The rendered form container.
 */
const FormCard = forwardRef((props, ref) => {
    const {
        title,
        showDefaultRightLegends,
        numOfFieldsPerLine = 4,
        formFields = [],
        formFieldRefs = [],
        rightContent = undefined
    } = props;

    /* #region METHODS */
    const getAllFieldValues = () => {
        const fieldValues = {};

        for (let i = 0; i < formFields.length; i++) {
            const varName = formFields[i].props.varName;
            const fieldRef = formFieldRefs[varName];

            if (fieldRef) {
                fieldValues[varName] = fieldRef.getValue();
            } else {
                console.error(`FieldRef not found for '${varName}'`);
                return null;
            }
        }
        return fieldValues;
    };

    const allFieldsAreValid = highlightValidation => {
        let allFieldsValid = true;
        for (let i = 0; i < formFields.length; i++) {
            const varName = formFields[i].props.varName;
            const fieldRef = formFieldRefs[varName];
            if (fieldRef) {
                const isValid = fieldRef.isValid(highlightValidation);
                if (!isValid) {
                    allFieldsValid = false;
                    break;
                }
            } else {
                console.error("FieldRef not found for varName:", varName);
                return null;
            }
        }
        return allFieldsValid;
    };

    const onTextChange = () => {
        if (props.onFormChanged) {
            props.onFormChanged(true);
        }
    };
    /* #endregion */

    useImperativeHandle(ref, () => ({
        getAllFieldValues,
        allFieldsAreValid
    }));

    return (
        <div>
            <CardComponentContainer
                title={title}
                spacing="16px"
                rightComponent={showDefaultRightLegends ? <FieldsRightLegends /> : undefined}>
                <div style={{ display: "flex", gap: "16px" }}>
                    <div
                        className="FormFieldsContainer"
                        style={{
                            display: "inline-grid",
                            width: "100%",
                            gap: "16px",
                            gridTemplateColumns: `repeat(${numOfFieldsPerLine}, minmax(0, 1fr))`
                        }}>
                        {formFields.map((field, index) => {
                            //TODO: try to see if is possible to use the ref directly in the field component instead of using the formFieldRefs
                            const newField = {
                                ...field,
                                key: index,
                                props: {
                                    ...field.props,
                                    //onFormTextChange: onTextChange,
                                    //onFormTextBlur: onTextChange,
                                    onCurrentValueChange: onTextChange
                                }
                            };
                            //console.log("newField:", newField);
                            return newField;
                        })}
                    </div>
                    {rightContent && <div className="rightContentContainer">{rightContent}</div>}
                </div>
            </CardComponentContainer>
        </div>
    );
});

export default FormCard;

/* #region SMALL COMPONENTS */
const FieldsRightLegends = () => {
    /* #region VARS */
    const Frame186 = {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "4px",
        textTransform: "none"
    };

    const Rectangle7 = {
        width: "6px",
        height: "6px",
        borderRadius: "600px"
    };

    const legendTitle = {
        color: "#7c868b",
        fontSize: "10px",
        fontFamily: "Roboto",
        fontWeight: 400,
        wordWrap: "break-word"
    };
    /* #endregion */

    return (
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#00AEEF" }} />
                <div className="legendTitle" style={legendTitle}>
                    recommended
                </div>
            </div>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#FF9900" }} />
                <div className="legendTitle" style={legendTitle}>
                    mandatory
                </div>
            </div>
            <div className="Frame186" style={Frame186}>
                <div className="Rectangle7" style={{ ...Rectangle7, background: "#4F5B6D" }} />
                <div className="legendTitle" style={legendTitle}>
                    locked
                </div>
            </div>
        </div>
    );
};
/* #endregion */
