import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APP_TITLE } from "../../../../config/constant";
import { useOutletContext } from "react-router";
import ContainerLoader from "../../../common/loaders/ContainerLoader";
import CardComponentContainer from "../../../common/cardContainers/CardComponentContainer";
import ListComponent from "../../../common/lists/ListComponent";
import InviteCard from "./InviteCard";
import SimpleButtonContentHeader from "../../companies/createCompanyComponents/SimpleButtonContentHeader";

/* #region SCOPED CSS */
const StyledPage = styled.div`
    .input-remove-default {
        border: none;
        outline: none;
        background: none;
    }

    .selects {
        background: var(--backgrounds-and-lines-n-600, #10141b);
    }

    .selects.mandatory {
        border: 1px solid transparent;
        border-left: 5px solid var(--text-icons-orange-500);
        border-radius: 4px;
        outline: none;
    }

    .selects.mandatory > div {
        border: none;
        cursor: pointer;
    }

    .selects.mandatory.error {
        border: 1px solid var(--text-icons-red-500);
        border-left: 5px solid var(--text-icons-red-500);
    }

    .main-window-header {
        background: var(--backgrounds-and-lines-n-600, #10141b);
        display: flex;
        flex-direction: row;
        row-gap: 0px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 68px;
        position: relative;
    }
    .frame-40 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .btn-large-solid {
        background: var(--buttons-all-platforms-gray-300, #1e2631);
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 120px;
        height: 35px;
        position: relative;
    }
    .button {
        color: var(--buttons-all-platforms-gray-200, #4f5b6d);
        text-align: left;
        font-family: var(--button-text-small-font-family, "Roboto-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 10px);
        letter-spacing: var(--button-text-small-letter-spacing, 0.0125em);
        font-weight: var(--button-text-small-font-weight, 400);
        text-transform: uppercase;
        position: relative;
    }
`;
const RequiredLabelStyle = styled.div`
    &.main-conatainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        font-size: 10px;
        font-family: "Roboto";
        color: #7c868b;
        text-align: center;
        justify-content: center;
        align-items: center;
        text-transform: lowercase;
    }
    .recommended {
        display: flex;
        flex-direction: row;
        gap: 4px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .recommended-icon {
        height: 6px;
        width: 6px;
        border: 1px solid #00aeef;
        background-color: #00aeef;
        border-radius: 50%;
    }
    .mandatory-icon {
        height: 6px;
        width: 6px;
        border: 1px solid #ff9900;
        background-color: #ff9900;
        border-radius: 50%;
    }
    .locked-icon {
        height: 6px;
        width: 6px;
        border: 1px solid #4f5b6d;
        background-color: #4f5b6d;
        border-radius: 50%;
    }
`;
/* #endregion SCOPED CSS */

export default function Index() {
    document.title = `${APP_TITLE} - Retailers`;

    /* #region STATES */
    const { MainLayoutProps, layoutType } = useOutletContext();
    const props = {
        layoutType: layoutType,
        setControlBarLeftBtns: MainLayoutProps?.setControlBarLeftBtns,
        setControlBarCenterBtns: MainLayoutProps?.setControlBarCenterBtns,
        setControlBarRightBtns: MainLayoutProps?.setControlBarRightBtns,
        setFilterHeaderProperties: MainLayoutProps?.setFilterHeaderProperties,
        setRefreshUserData: MainLayoutProps?.setRefreshUserData
    };
    const [isLoading, setIsLoading] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

    const [brand, setBrand] = useState([]);
    const [retailBrand, setRetailBrand] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [taxLabel, setTaxLabel] = useState("");
    const [tax, setTax] = useState("");
    const [maxStore, setMaxStore] = React.useState("All");
    const [maxStoreQty, setMaxStoreQty] = React.useState(0);
    /* #endregion STATES */

    /* #region EFFECTS */
    useEffect(() => {
        const saveIsEnabled = !saveBtnDisabled && !isLoading;

        setupHeaders(props, saveIsEnabled, onSaveClicked);
    }, [props?.layoutType]);
    /* #endregion EFFECTS */

    /* #region LIST COMPONENT PROPS */
    const listColumns = [
        {
            name: "Retail Brand",
            selector: row => row?.retailBrand ?? "--"
        },
        {
            name: "To Brand",
            selector: row => row?.toBrand ?? "--"
        },
        {
            name: "Invited by",
            selector: row => row?.invitedBy ?? "--"
        },
        {
            name: "Invited on",
            selector: row => row?.invitedOn ?? "--"
        },
        {
            name: "Contact Name",
            selector: row => row?.contactName ?? "--"
        },
        {
            name: "Email",
            selector: row => row?.contactEmail ?? "--"
        },
        {
            name: "Country",
            selector: row => row?.country ?? "--"
        },
        {
            name: "Max Store",
            selector: row => row?.maxStore ?? "--"
        },
        {
            name: "Status",
            selector: row => row?.status ?? "--"
        }
    ];
    /* #endregion */

    /* #region METHODS */
    const onSaveClicked = () => {};
    /* #endregion METHODS */

    return (
        <StyledPage style={{ marginTop: "-24px" }}>
            <ContainerLoader isLoading={isLoading} isLoadingOverlay={true} addBlurFilters={true}>
                <div className="flex flex-dir-column gap-16 w-100">
                    <CardComponentContainer
                        title="INVITE RETAILER"
                        className="mt-24px"
                        rightComponent={<RequiredLabel />}>
                        <InviteCard
                            brand={brand}
                            setBrand={setBrand}
                            retailBrand={retailBrand}
                            setRetailBrand={setRetailBrand}
                            name={name}
                            setName={setName}
                            email={email}
                            setEmail={setEmail}
                            country={country}
                            setCountry={setCountry}
                            taxLabel={taxLabel}
                            setTaxLabel={setTaxLabel}
                            tax={tax}
                            setTax={setTax}
                            maxStore={maxStore}
                            setMaxStore={setMaxStore}
                            maxStoreQty={maxStoreQty}
                            setMaxStoreQty={setMaxStoreQty}
                        />
                    </CardComponentContainer>
                    <CardComponentContainer title="RETAILERS" title2="(LAST 10 INVITED)" className="mt-24px">
                        <ListComponent
                            title="Invite List"
                            columns={listColumns}
                            api={{
                                endpoint: "demo",
                                useCommonPostRequest: true,
                                commonContext: "Invite List",
                                formData: {
                                    companyID: localStorage.getItem("currentCompanyID")
                                }
                                //onSuccess: mapListData
                            }}
                            pageLimit={20}
                            currentPage={1}
                            queryKey={"InviteList"}
                            actions={{
                                includeActions: true,
                                extraActions: [
                                    {
                                        title: "Details"
                                    },
                                    {
                                        title: "Add staff"
                                    }
                                ],
                                editIsVisible: false
                            }}
                            includeStatus={{
                                stickyStatus: true,
                                statusFromPath: "status",
                                statusColors: [
                                    { colorClass: "text-green-500", forValues: ["Invited"] },
                                    { colorClass: "text-blue-600", forValues: ["Active"] }
                                ]
                            }}
                        />
                    </CardComponentContainer>
                </div>
            </ContainerLoader>
        </StyledPage>
    );
}
function setupHeaders(props, saveButtonEnabled, onBtnClicked) {
    props.setControlBarLeftBtns([{ to: "#", disabled: true, title: "Retailers", icon: "fa-magnifying-glass" }]);
    props.setControlBarCenterBtns([{ to: "/retailers", active: true, disabled: false, title: "Retailers" }]);
    props.setControlBarRightBtns([
        { to: "/retailers/view", active: false, disabled: false, title: "View", icon: "eye" },
        { to: "/retailers", active: true, disabled: false, title: "Invite", icon: "mail" },
        { to: "#", active: false, disabled: true, title: "Details", icon: "microscope" }
    ]);

    props.setFilterHeaderProperties({
        customContentHeader: (
            <SimpleButtonContentHeader btnTitle="SAVE" btnEnabled={saveButtonEnabled} onClick={onBtnClicked} />
        )
    });
}
function RequiredLabel() {
    return (
        <RequiredLabelStyle className="main-conatainer">
            <div className="recommended">
                <div className="recommended-icon"></div> recommended
            </div>
            <div className="recommended">
                <div className="mandatory-icon"></div> mandatory
            </div>
            <div className="recommended">
                <div className="locked-icon"></div> locked
            </div>
        </RequiredLabelStyle>
    );
}
