import CompaniesPage from "../components/pages/companies/CompaniesPage";
import CompaniesUsersPage from "../components/pages/companies/CompaniesUsersPage";
import CompanyCreatePage from "../components/pages/companies/CreateCompanyPage";
const routes = [
    {
        path: "/companies",
        element: <CompaniesPage />
    },
    {
        path: "/companies/users",
        element: <CompaniesUsersPage />
    },
    {
        path: "/companies/create",
        element: <CompanyCreatePage />
    }
];

export default routes;
