import BrandsPage from "../components/pages/brands/BrandsPage";
import BrandsUsersPage from "../components/pages/brands/BrandsUsersPage";
import CreateBrandPage from "../components/pages/brands/CreateBrandPage";

const routes = [
    {
        path: "/brands",
        element: <BrandsPage />
    },
    {
        path: "/brands/users",
        element: <BrandsUsersPage />
    },
    {
        path: "/brands/create",
        element: <CreateBrandPage />
    }
];

export default routes;
