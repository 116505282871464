import { createSlice } from "@reduxjs/toolkit";

export const userDataSlice = createSlice({
    name: "userData",
    initialState: {
        userAppData: null
    },
    reducers: {
        setData: (state, action) => {
            state.userAppData = action.payload.userAppData;
        }
    }
});

export const { setData } = userDataSlice.actions;

export default userDataSlice.reducer;
