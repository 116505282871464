import styled from "styled-components";
import CardGridSkeleton from "../gridCards/animations/CardGridSkeleton";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import CompanyGridCard from "../gridCards/CompanyGridCard";
import { useInfiniteQuery } from "react-query";
import { axiosInstance } from "../../../api/axios";
import { useNavigate } from "react-router";
import * as Library from "../../../utils/Library";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    & {
        overflow-y: scroll;
        height: 100%;
        margin-right: -24px;
        margin-left: -24px;
        padding-left: 24px;
        padding-right: 17px;
    }
`;
/* #endregion */

export default function InfinityGridCompanyCards(props) {
    const gridLayoutStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props?.minColumnWidth || "395px"}, 1fr))`,
        gap: "24px"
    };

    /* #region STATES */
    const navigate = useNavigate();
    const scrollContainerRef = useRef(null);
    const [scroll, setScroll] = useState(true);
    const [pageLimit, setPageLimit] = useState(8);
    const [uid, setuid] = useState(uuidv4());
    const [minimumCardsFill, setminimumCardsFill] = useState(props?.minimumCardsFill || pageLimit);

    const {
        data,
        isFetching: isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = Library.usePostInfiniteQuery(
        uid,
        "getCompaniesData",
        {
            organizationID: localStorage.getItem("currentOrganizationID"),
            limit: pageLimit
        },
        () => {
            setScroll(true);
        },
        true,
        "Company"
    );

    const pageList = data?.pages;
    //const totalCount = data?.pages[0]?.data?.totalCount;
    const nextDataCount = data?.pages[data?.pages.length - 1].data?.nextDataCount;
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        handleScroll();
    }, [data?.pages]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && !isLoading && scroll && !isFetchingNextPage) {
            //console.log("scroll event added");
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            //console.log("scroll event removed");
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollContainerRef, isLoading, pageList, scroll, isFetchingNextPage, data?.pages]);
    /* #endregion */

    /* #region METHODS */
    const handleScroll = () => {
        //console.log("scrolling");
        const scrollContainer = scrollContainerRef.current;

        const isAtBottom =
            Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) <= scrollContainer.clientHeight;

        if (isAtBottom && !isLoading && hasNextPage && !isFetchingNextPage) {
            setScroll(false);
            //console.log("fetching next page");
            fetchNextPage();
        }
    };

    const getTotalCards = () => {
        return pageList.reduce((acc, it) => {
            return acc + it?.data?.result?.length;
        }, 0);
    };

    /* #endregion */
    return (
        <StyledPage className={`${props?.className || ""}`} ref={scrollContainerRef}>
            {isLoading && !isFetchingNextPage ? (
                <div style={gridLayoutStyle}>
                    {Array.from({ length: 8 }, () => (
                        <CardGridSkeleton key={uuidv4()} height="420.28px" />
                    ))}
                </div>
            ) : (
                <div className="mb-24px" style={gridLayoutStyle}>
                    {pageList?.map(it => {
                        return it?.data?.result?.map(item => {
                            return (
                                <CompanyGridCard
                                    key={item?.ourID}
                                    image={item?.logoURL}
                                    titlePrefix="COMPANY"
                                    title={item?.name}
                                    id={item?.ourID}
                                    isCardEnabled={true}
                                    //isCardSuspended={status?.toLowerCase() === "suspended"} //TODO: needed to check suspended status?
                                    data={{
                                        registrationNumber: item?.registrationNumber,
                                        taxNr: item?.taxNr,
                                        phoneNr: item?.phoneNr,
                                        address: item?.address,
                                        companyAdmin: item?.companyAdmin,
                                        nrOfBrands: item?.nrOfBrands,
                                        nrOfStores: item?.nrOfStores,
                                        nrOfUsers: item?.nrOfUsers
                                    }}
                                    primaryButton={{
                                        title: "DETAILS"
                                    }}
                                    secondaryButton={{
                                        title: "ADD USERS"
                                        /* onClick: () => {
                                            navigate("/companies/users");
                                        } */
                                    }}
                                />
                            );
                        });
                    })}

                    {!isFetchingNextPage &&
                        minimumCardsFill - getTotalCards() > 0 &&
                        Array.from({ length: minimumCardsFill - getTotalCards() }, (_, index) => (
                            <CompanyGridCard key={index} isCardEnabled={false} />
                        ))}

                    {isFetchingNextPage &&
                        Array.from({ length: nextDataCount }, (_, index) => (
                            <CardGridSkeleton key={index} height="420.28px" />
                        ))}
                </div>
            )}
        </StyledPage>
    );
}

export const useInfiniteQueryData = (payload, onSuccess) => {
    //console.log("useInfiniteQueryData", payload.queryKey);

    return useInfiniteQuery({
        queryKey: [payload.queryKey],
        queryFn: async ({ pageParam = 1 }) => {
            const { data } = await axiosInstance.get(
                `${payload.api}?${payload.key}=${payload.value}&limit=${payload.pageLimit}&page=${pageParam}`
            );

            //console.log(">>>", data);
            return data;
        },

        getNextPageParam: (lastPage, pages) => {
            //console.log("pages", pages);

            return lastPage?.data?.nextPage;
        },
        onSuccess: onSuccess
    });
};
